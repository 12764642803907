export enum PlatformType {
    Instagram = 2,
    TikTok = 3,
    Twitter = 4,
    LinkedIn = 5,
    Vk = 6,
    Tumbler = 7,
    Facebook = 8,
    YouTube = 9,
    Pinterest = 10,
    Gab = 11,
    Snapchat = 12,
    Reddit = 13,
    Parler = 14,
    Telegram = 15,
    Discord = 16
}

export const PlatformOptions = [
    { text: 'Instagram', value: 2 },
    { text: 'TikTok', value: 3 },
    { text: 'Twitter', value: 4 },
    { text: 'LinkedIn', value: 5 },
    { text: 'Vk', value: 6 },
    { text: 'Tumbler', value: 7 },
    { text: 'Facebook', value: 8 },
    { text: 'YouTube', value: 9 },
    { text: 'Pinterest', value: 10 },
    { text: 'Gab', value: 11 },
    { text: 'Snapchat', value: 12 },
    { text: 'Reddit', value: 13 },
    { text: 'Parler', value: 14 },
    { text: 'Telegram', value: 15 },
    { text: 'Discord', value: 16 }
]

export const platformNameByType: Map<PlatformType, string> = new Map<PlatformType, string>([
    [PlatformType.Facebook, 'Facebook'],
    [PlatformType.Twitter, 'Twitter'],
    [PlatformType.Instagram, 'Instagram'],
    [PlatformType.Vk, 'Vk'],
    [PlatformType.Telegram, 'Telegram'],
    [PlatformType.LinkedIn, 'LinkedIn'],
    [PlatformType.Pinterest, 'Pinterest'],
    [PlatformType.Gab, 'Gab'],
    [PlatformType.Reddit, 'Reddit'],
    [PlatformType.YouTube, 'YouTube'],
    [PlatformType.Snapchat, 'Snapchat'],
    [PlatformType.Reddit, 'Reddit'],
    [PlatformType.Parler, 'Parler'],
    [PlatformType.Discord, 'Discord'],
    [PlatformType.TikTok, 'TikTok'],
    [PlatformType.Tumbler, 'Tumbler'],
]);

