import React, { LegacyRef, useRef, useState } from 'react';
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import NoPosts from "../../errors/NoPosts";
import { useEffect } from "react";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { Report } from '../../../app/models/report';
import { reportColumnDefs } from '../ReportColumnHooks';
import { GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { Button, Divider, Icon } from 'semantic-ui-react';



function ReportDashboard() {
    const gridRef = useRef<AgGridReact<Report>>(null);
    const { reportStore } = useStore();
    const { reportsList, loadReports, loading } = reportStore;
    const gridOptions: GridOptions<Report> = {
        paginationAutoPageSize: true,
        pagination: true,
        defaultColDef: {
            resizable: true,
        },
    }

    useEffect(() => {
        if (reportsList.length <= 1) {
            loadReports();
        }

    }, [loadReports, reportsList]);

    function onBtExport() {
        if (!_.isNil(gridOptions))
            gridRef?.current?.api?.exportDataAsCsv({
                allColumns: true,
                columnSeparator: ';',
            });
    }


    if (reportStore.loadingInitial || loading)
        return <LoadingComponent content={'Loading data'} />

    else if (reportsList.length === 0)
        return <NoPosts />

    else
        return (
            <>
                <div className="ag-theme-alpine" style={{ height: 750, width: 1100, marginBottom: 75 }}>
                    <Button
                        color="green"
                        disabled={reportsList.length === 0}
                        size="mini"
                        onClick={() => onBtExport()}><Icon name="download" />CSV</Button>
                    <Divider />
                    <AgGridReact
                        ref={gridRef}
                        rowData={reportsList}
                        columnDefs={reportColumnDefs}
                        gridOptions={gridOptions}>

                    </AgGridReact>
                </div>
            </>
        );
}

export default observer(ReportDashboard);
