/* #region  Imports */
import React, { useEffect, useState } from "react";
import { Button, Segment } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import { useParams, useHistory } from 'react-router-dom';
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { v4 as uuid } from 'uuid'
import ValidationErrors from "../../errors/ValidationErrors";
import { Formik, Form } from "formik";
import * as Yup from 'yup'
import TextInput from "../../../app/common/form/TextInput";
import SelectInput from "../../../app/common/form/SelectInput";
import { antiIsraeliCategoryOptions } from "../../../app/common/options/categoryOptions";
import CheckBoxInput from "../../../app/common/form/CheckBoxInput";
import { platformOptions } from "../../../app/common/options/platformOptions";
import PhotoUploadWidget from "../../../app/common/imageUpload/PhotoUploadWidget";
import { postThreatOptions } from "../../../app/common/options/postThreatOptions";
import { countryOptions, languageOptions } from "../../../app/models/countryEnum";



function ReportForm() {

    const { reportStore, userStore, modalStore } = useStore();
    const { selectedReport: report, loadReport, createReport, updateReport, loading, loadingInitial, loadEmptyReport } =
        reportStore;
    const { id } = useParams<{ id: string }>();
    const history = useHistory();


    const [errors, setErrors] = useState<string[]>();

    const validationSchema = Yup.object({
        url: Yup.string().required('Url is required').url(),
        platform: Yup.string().required('Platform is required'),
        description: Yup.string().required('Description is required'),
        language: Yup.string().required('Language is required'),
        //country: Yup.string().required('Country is required'),
        isThreat: Yup.string().required('Threat estimation is required'),
        content: Yup.string().required('Content is required'),
        image: Yup.string().required('Image is required').url(),
        //postedBy: Yup.string().required('Posted By is required'),
        category: Yup.string().required('Category is required'),
    })


    useEffect(() => {

        if (id) {
            loadReport(id)
                .then((rep) => {
                    if (!loadingInitial && rep?.createdById && !userStore.isUserManaged(rep?.createdById))
                    history.push('/reports')
            })
        }
        else {
            loadEmptyReport()
        }


    }, [id, loadReport, history, userStore, loadingInitial, loadEmptyReport])



    function handleFormSubmit(report: any) {
        if (report.id.length === 0) {
            let newReport = {
                ...report,
                id: uuid()
            }
            //createReport(newReport).then(() => history.push(`/reports/${report.id}`)).catch(err => setErrors(err))
            createReport(newReport).then(() => history.push(`/createReport`)).catch(err => setErrors(err))
        } else {

            updateReport(report).then(() => history.push(`/reports/${report.id}`)).catch(err => setErrors(err))
        }

    }


    if (loadingInitial || !report) return <LoadingComponent content="Loading report..." />
    return (

        <Segment clearing>

            {errors && <ValidationErrors errors={errors} />}
            <Formik
                validationSchema={validationSchema}
                enableReinitialize
                initialValues={report}
                onSubmit={values => handleFormSubmit(values)}>
                {({ handleSubmit, isValid, isSubmitting, dirty, values, setFieldValue }) => (
                    <Form className="ui form" onSubmit={handleSubmit} autoComplete="off">
                        <TextInput name='url' placeholder="Url" label="Post Link" />
                        <SelectInput name='platform' placeholder="Platform" options={platformOptions} label="Platform" search/>
                        <TextInput name='description' placeholder="A short summary of the content- write briefly in your own words" label="Description" />
                        <TextInput name='content' placeholder="In this search, what keywords or hashtags did you use? Please be specific. Also, Please include hashtags and keywords from the original post. Only English keywords!" label="Hashtags/Keywords" />
                        <SelectInput name='category' placeholder="Hate Speech Category" options={antiIsraeliCategoryOptions} label="Hate Speech Category" search/>
                        <SelectInput name='language' placeholder="Language of the post" label="Language" options={languageOptions} search/>
                        <SelectInput name='country' placeholder="Country where content published" label="Country" options={countryOptions} search/>
                        <SelectInput name='isThreat' placeholder="Do you believe the content you reported poses a threat?" options={postThreatOptions} label="Do you believe the content you reported poses a threat?" />
                        <TextInput name='image' placeholder="Enter url or use Upload image button below" label="Screenshot" /><Button type="submit" disabled={false} content='Upload image' onClick={() => modalStore.openModal(<PhotoUploadWidget fieldValue="image" setFieldValue={setFieldValue} />)} />
                        <TextInput name='postedBy' label="Posted By" placeholder="Enter username/full name of the orinigal poster (Not yours)." />
                        <CheckBoxInput name='isActive' label="Is Active Online?" />


                        <Button
                            disabled={isSubmitting || !dirty || !isValid}
                            loading={loading}
                            floated="right"
                            positive
                            type="submit"
                            content="Submit"
                        />
                        <Button
                            onClick={report!.id.length === 0 ? () => history.push('/reports') : () => history.push(`/reports/${report!.id}`)}
                            floated="right"
                            content="Cancel"
                        />
                    </Form>
                )}
            </Formik>


        </Segment>
    );
}

export default observer(ReportForm);
