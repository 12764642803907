import { Formik, Form } from 'formik'
import { observer } from 'mobx-react-lite'
import { Button, Header } from 'semantic-ui-react'
import * as Yup from 'yup'
import SelectInput from '../../../app/common/form/SelectInput'
import TextInput from '../../../app/common/form/TextInput'
import { cyabraDateSearchOptions, DateSearchType } from '../../../app/common/options/dateSearchTypeOptions'
import { cyabraPlatformEnum, cyabraPlatformOptions, TaskClassifier, taskClassifierOptions } from '../../../app/common/options/platformOptions'
import { useStore } from '../../../app/stores/store'

function CyabraNewScanTaskForm() {
    const { cyabraStore } = useStore();


    const validationSchema = Yup.object({
        phrase: Yup.string().required('Keyword / Hashtag is required'),
        platformType: Yup.number().required('Platform is required'),
        dateSearchType: Yup.number().required('Date range is required'),
        taskClassifier: Yup.number().required('Task classifier is required')
    })

    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={{ phrase: '', platformType: cyabraPlatformEnum.Facebook, dateSearchType: DateSearchType.Day, taskClassifier: TaskClassifier.AntiIsraeli, error: null }}
            onSubmit={(values, { setErrors }) => cyabraStore.startNewTask(values.phrase, values.platformType, values.dateSearchType, values.taskClassifier).catch(() => setErrors({ error: 'Error occured starting task.' }))}>
            {({ handleSubmit, isSubmitting, isValid, dirty }) => (
                <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                    <Header as='h2' content='Start new scan task' color='teal' textAlign='center' />
                    <TextInput name='phrase' placeholder='Keyword/Hashtag' />
                    <SelectInput name='platformType' placeholder='Platform' options={cyabraPlatformOptions} />
                    <SelectInput name='dateSearchType' placeholder='Date Range' options={cyabraDateSearchOptions} />
                    <SelectInput name='taskClassifier' placeholder='Task Classifier' options={taskClassifierOptions} />
                    <Button disabled={isSubmitting || !dirty || !isValid} loading={isSubmitting} positive content="Start" type='submit' fluid />
                </Form>
            )}

        </Formik>
    )
}

export default observer(CyabraNewScanTaskForm)
