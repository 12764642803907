import React, { useEffect, useState } from 'react'
import { Image, Statistic, Card } from 'semantic-ui-react'
import { useStore } from '../../../app/stores/store';
import * as _ from 'lodash'

interface Props {
    platform: string;
}

function ReportStatisticItem({ platform }: Props) {
    const { reportStore } = useStore();
    const { statsByPlatform} = reportStore;
    const platStats = statsByPlatform.get(platform);

    return (
        <Card.Group>
            <Card>
                <Card.Content>
                    <Image
                        floated='right'
                        size='mini'
                        src={`/assets/${platform.toLowerCase()}-icon.png`}
                    />
                    <Card.Header>{platform}</Card.Header>

                    <Card.Description >
                        <Statistic.Group horizontal>
                            <Statistic>
                                <Statistic.Value>{platStats?.total}</Statistic.Value>
                                <Statistic.Label>Total</Statistic.Label>
                            </Statistic>
                            <Statistic>
                                <Statistic.Value>{platStats?.online}</Statistic.Value>
                                <Statistic.Label>Online</Statistic.Label>
                            </Statistic>
                            <Statistic>
                                <Statistic.Value>{platStats?.removed}</Statistic.Value>
                                <Statistic.Label>Removed</Statistic.Label>
                            </Statistic>
                        </Statistic.Group>


                    </Card.Description>
                </Card.Content>
            </Card>

        </Card.Group>
    )


}

export default ReportStatisticItem