import { useEffect } from 'react'
import * as _ from 'lodash';
import { Button, Header, Segment, Table } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import CyabraTaskTableRow from './CyabraTaskTableRow';
import CyabraNewScanTaskForm from './CyabraNewScanTaskForm';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import NewScrapeForm from './NewScrapeForm';

function CyabraManagement() {
    const { cyabraStore, modalStore } = useStore();
    const { getTasks, tasks, fetchPosts, getLimits, limits, removeScanTaskPosts, downloadTaskPosts, removeScanTask, limitsLoaded, tasksLoaded } = cyabraStore;
    const { openModal } = modalStore;

    useEffect(() => {
        if (_.isNil(tasks))
            getTasks()
    }, [tasks, getTasks])

    useEffect(() => {
        if (_.isNil(limits))
            getLimits()
    }, [limits, getLimits])

    return (
        <>
            <Segment clearing>
                <Header as='h2' floated='left'>Cyabra Management</Header>
                <Button color='green' floated='right' onClick={() => openModal(<NewScrapeForm />)}>New Scrape</Button>
                <Button color='green' floated='right' onClick={() => openModal(<CyabraNewScanTaskForm />)}>New Task</Button>
            </Segment>
            {limitsLoaded ?
                <Header as='h5'>Limit: {limits?.mentionQuota ?? 0}, Used: {limits?.mentionUsed ?? 0}, Remaining: {limits?.mentionRemaining ?? 0}</Header> :
                <Header as='h5'>Loading limits...</Header>}

            {tasksLoaded ?
                <Table celled singleLine>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Keyword/#</Table.HeaderCell>
                            <Table.HeaderCell>Platform</Table.HeaderCell>
                            <Table.HeaderCell>Date range</Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.HeaderCell>Fetched</Table.HeaderCell>
                            <Table.HeaderCell>Posts count</Table.HeaderCell>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {_.map(tasks, (task) => {
                            return <CyabraTaskTableRow
                                task={task}
                                key={task.id}
                                fetchPosts={() => fetchPosts(task.id)}
                                removeScanTaskPosts={() => removeScanTaskPosts(task.id)}
                                downloadTaskPosts={() => downloadTaskPosts(task.id)}
                                deleteScanTask={() => removeScanTask(task.id)}
                            />;
                        })}
                    </Table.Body>
                </Table> :
                <LoadingComponent content="Loading Tasks..." />
            }
        </>

    );
}

export default observer(CyabraManagement)