import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Pagination, ProfileParams } from "../models/pagination";
import { Profile } from "../models/profile";
import { Report } from "../models/report";
import { store } from "./store";
import * as _ from "lodash";
import { PlatformStatistics } from "../models/platformStatistics";
import { ToArray } from "../common/helpers/enumHelpers";
import { platformEnum } from "../common/options/platformOptions";


export default class ProfileStore {

    reportRegistry = new Map<string, Report>();
    profile: Profile | null = null;
    loadingProfile: boolean = false;
    loadingProfileReports: boolean = false;
    uploadingMain: boolean = false;
    pagination: Pagination | null = null;
    pagingParams: ProfileParams | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    get reportsByDate() {
        return Array.from(this.reportRegistry.values()).sort(
            (a, b) =>
                Date.parse(b.createdOn.toString()) - Date.parse(a.createdOn.toString())
        );
    }

    get groupedReportsByPlatform() {
        return _.groupBy(Array.from(this.reportsByDate), function (report) {
            return _.get(platformEnum, report.platform);
        });
    }


    get platformsStatisticsByUser() {
        let lst: PlatformStatistics[] = [];
        const socialList = ToArray(platformEnum);

        _.forEach(socialList, social => {
            let isActive = _.countBy(this.groupedReportsByPlatform[social], 'isActive')
            let total = _.size(this.groupedReportsByPlatform[social])
            let online = _.has(isActive, 'true') ? isActive['true'] : 0
            let removed = _.has(isActive, 'false') ? isActive['false'] : 0

            let platStat: PlatformStatistics = {
                name: social,
                total,
                online,
                removed
            };
            if (total > 0)
                lst.push(platStat);
        });


        return lst;
    }


    setPagingParams = (pagingParams: ProfileParams) => {
        this.pagingParams = pagingParams;
    };

    loadReports = async (params: ProfileParams) => {

        this.loadingProfileReports = true;
        try {
            this.setPagingParams(params);
            const results = await agent.Reports.listByUser(this.axiosParams);
            this.removeReports();
            _.map(results.data, (report) => {

                this.setReport(report)
            });
            this.setPagination(results.pagination);


        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => {
                this.loadingProfileReports = false;
            });
        }
    };

    private setReport = (report: Report) => {
        this.reportRegistry.set(report.id, report);
    };

    private removeReports = () => {
        this.reportRegistry.clear();
    };

    setPagination = (pagination: Pagination) => {
        this.pagination = pagination;
    };


    loadProfile = async (username: string) => {
        this.loadingProfile = true;
        try {
            const profile = await agent.Profiles.get(username);
            runInAction(() => {
                this.profile = profile;

                this.pagingParams = new ProfileParams(username);

                this.loadingProfile = false;
            });
        } catch (ex) {
            console.log(ex);
            runInAction(() => {
                this.loadingProfile = false;
            });
        }
    };

    setProfileImage = (image: string) => {
        if (this.profile?.image) this.profile.image = image;
    };



    uploadMainPhoto = async (file: Blob) => {
        this.uploadingMain = true;
        try {
            const response = await agent.Reports.uploadMainPhoto(file);
            const photo = response.data;
            runInAction(() => {
                this.setProfileImage(photo.url);
                if (store.userStore.user?.image) store.userStore.user.image = photo.url;
                this.uploadingMain = false;
            });
            return photo.url;
        } catch (ex) {
            console.log(ex);
            runInAction(() => {
                this.uploadingMain = false;
                return "";
            });
        }
    };

    get reportsList() {
        return Array.from(this.reportRegistry.values());
    }

    // eslint-disable-next-line getter-return
    get isCurrentUser() {
        if (store.userStore && this.profile) {
            return store.userStore.user?.username === this.profile.username;
        }
    }

    get axiosParams() {
        const params = new URLSearchParams();
        if (this.pagingParams) {
            params.append("pageNumber", this.pagingParams.pageNumber.toString());
            params.append("pageSize", this.pagingParams.pageSize.toString());
            params.append("username", this.pagingParams.username);
        }

        return params;
    }

}
