import { makeAutoObservable, runInAction} from "mobx";
import agent from "../api/agent";
import { MonitorStatus } from "../models/monitorStatus";


export default class MonitorsStore {
    monitorsDataLoaded: boolean = false;
    monitorStatus: MonitorStatus | null = null;

    constructor() {
        makeAutoObservable(this);
    }


    getMonitorsData = async () => {
        try {
            const monitorStatus: MonitorStatus = await agent.Monitors.onlineTool();
            this.monitorStatus = monitorStatus;
            runInAction(() => this.monitorsDataLoaded = true)
        } catch (e) {
            throw e
        }
    }

    get monitorStatusData() {
        return this.monitorStatus;
    }

}
