import { SemanticCOLORS } from "semantic-ui-react";

export const skipReasonOptions = [
    { text: 'Not my language', value: 'Not my language' },
    { text: "I don't have an account on this platform", value: "I don't have an account on this platform" },
    { text: 'Post has been removed', value: 'Post has been removed' },
    { text: 'Unsure how to categorize', value: 'Unsure how to categorize' },
    { text: 'Other', value: 'Other' }
];

