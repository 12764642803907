import React from 'react'
import { Message } from 'semantic-ui-react';

interface Props{
    errors: any;
}
function ValidationErrors({errors}: Props) {
    return (
        <Message error>
            {errors && Array.isArray(errors) && (errors.length > 0) && (
                <Message.List>
                    {errors.map((err: any, i: any)=>(
                        <Message.Item key={i}>{err}</Message.Item>
                    ))}
                </Message.List>
            )}
            {errors && !Array.isArray(errors) &&
                <Message.Item key={0}>One of your credentials isn't valid</Message.Item>}
        </Message>
    )
}

export default ValidationErrors
