/* #region  Imports */
import { useCallback, useEffect, useState } from "react";
import { Button, Grid, Header } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import * as _ from "lodash";
import ScrapedStatisticPlaceholder from "./ScrapedStatisticPlaceholder";
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from "recharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { platformEnum } from "../../../app/common/options/platformOptions";
import { PlatformStatistics } from "../../../app/models/platformStatistics";
import { ToArray } from "../../../app/common/helpers/enumHelpers";




/* #endregion */

function ScrapedStatisticGraphs() {
    /* #region  State and store */
    const { scrapedStore } = useStore();
    const { loadReports, reportRegistry, loadingInitial } = scrapedStore;
    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
    const [startDate, endDate] = dateRange;
    const [stats, setStats] = useState<PlatformStatistics[]>();

    const getGraphValues = useCallback((sDate: Date | null = null, eDate: Date | null = null) => {

        if ((_.isNil(sDate) && eDate) || (_.isNil(eDate) && sDate))
            return;
        let grouped: any;
        let repoByDates = Array.from(reportRegistry.values()).sort(
            (a, b) =>
                Date.parse(b.postedOn.toString()) - Date.parse(a.postedOn.toString())
        );
        if (sDate && eDate) {
            repoByDates = repoByDates.filter(repo => new Date(repo.postedOn).getTime() > sDate.getTime() &&
                new Date(repo.postedOn).getTime() <= eDate.getTime())
        }


        grouped = _.groupBy(Array.from(repoByDates), function (report) {
            return _.get(platformEnum, report.platform);
        })



        let lst: PlatformStatistics[] = [];
        const socialList = ToArray(platformEnum);

        _.forEach(socialList, social => {
            let isActive = _.countBy(grouped[social], 'isActive')
            let total = _.size(grouped[social])
            let online = _.has(isActive, 'true') ? isActive['true'] : 0
            let removed = _.has(isActive, 'false') ? isActive['false'] : 0

            let platStat: PlatformStatistics = {
                name: social,
                total,
                online,
                removed
            };
            lst.push(platStat);
        });
        setStats(lst)

    }, [reportRegistry])

    useEffect(() => {
        getGraphValues(dateRange[0], dateRange[1]);
    }, [dateRange, getGraphValues])

    useEffect(() => {

        if (reportRegistry.size <= 1 || loadingInitial) loadReports();


    }, [loadReports, reportRegistry.size]);
    /* #endregion */



    if (scrapedStore.loadingInitial && reportRegistry.size <= 1)
        return <ScrapedStatisticPlaceholder />;

    return (
        <>
            <Grid>
                <Grid.Row centered columns={2}>
                    <Grid.Column textAlign="left">
                        <Header size='medium'>Current range:  {(dateRange[0] && dateRange[1]) ? `${dateRange[0]?.toLocaleDateString("he")} - ${dateRange[1]?.toLocaleDateString("he")}` : "All time"}</Header>
                    </Grid.Column>
                    <Grid.Column textAlign="right">
                        <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            onChange={(update) => {
                                setDateRange(update);
                            }}
                            customInput={<Button primary>Set date range</Button>}
                            withPortal
                            isClearable
                        />

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <BarChart
                        width={1097}
                        height={300}
                        data={stats}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="total" fill="#8884d8" />
                        <Bar dataKey="removed" fill="#82ca9d" />
                        <Bar dataKey="online" fill="#ca8282" />
                    </BarChart>
                </Grid.Row>
            </Grid>
        </>
    );
}

export default observer(ScrapedStatisticGraphs);
