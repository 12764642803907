import {  Header, Icon, Segment } from 'semantic-ui-react'

const NothingHere = () => (
  <Segment placeholder>
    <Header icon>
      <Icon name="frown outline" />
      There is nothing here yet...
    </Header>
  </Segment>
)

export default NothingHere