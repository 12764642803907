import { observer } from 'mobx-react-lite';
import { Table, Popup, Button } from 'semantic-ui-react'
import * as _ from 'lodash'
import DeleteButton from '../../../app/common/confirm/ConfirmButton';
import CheckBoxInput from '../../../app/common/form/CheckBoxInput';
import { Keyword } from './Keyword';
import { useStore } from '../../../app/stores/store';
import NewKeywordForm from './NewKeywordForm';


interface Props {
    index: number;
    keyword: Keyword;
}
function KeywordTableRow({ index, keyword }: Props) {
    
    const { keywordsStore, modalStore } = useStore();
    const { deleteKeyword } = keywordsStore;
    const { openModal } = modalStore;

    return (

        <Table.Row key={keyword.id}>
            <Table.Cell >{index}</Table.Cell>
            <Table.Cell >{keyword.text}</Table.Cell>
            <Table.Cell >{keyword.exclude ? "Exclude" : "Include"}</Table.Cell>
            <Table.Cell >{keyword.importance}</Table.Cell>
            <Table.Cell >{keyword.posts}</Table.Cell>
            <Table.Cell width="2" textAlign="right" >
            <Button size='mini' icon="edit" color="yellow" onClick={() => openModal(<NewKeywordForm initialValues={{ text: keyword.text, exclude: keyword.exclude, importance: keyword.importance, error: null }} />)}></Button>
            <Popup content='Delete keyword' trigger={<DeleteButton text="Sure you want to delete?" func={() => deleteKeyword(keyword)}></DeleteButton>} />
            {/*<Button size='mini' icon="delete" color="red" onClick={() => deleteKeyword(keyword)}></Button>*/}
            </Table.Cell>
        </Table.Row>
    )
}

export default observer(KeywordTableRow)
