import { Formik, Form } from 'formik';
import { observer } from 'mobx-react-lite';
import { Button, Header, Input, List, Segment } from 'semantic-ui-react';
import * as Yup from 'yup';
import CheckBoxInput from '../../../app/common/form/CheckBoxInput';
import TextInput from '../../../app/common/form/TextInput';
import { useStore } from '../../../app/stores/store';
import * as XLSX from 'xlsx';
import { Dispatch, SetStateAction, useState } from 'react';
import { NewKeyword } from "./Keyword";

/*interface ImportKeywordFormValues {
  file: File | null;
  exclude: boolean;
  importance: number;
  error: string | null;
}*/

function ImportKeywordForm() {
  const { keywordsStore, modalStore } = useStore();
  const { closeModal } = modalStore;
  const { addKeywordList } = keywordsStore;
  const [ newKeywordsTexts, setNewKeywordsTexts ] = useState<string[][]>([]);

  const validationSchema = Yup.object({
    file: Yup.mixed().required('Keyword file is required'),
    importance: Yup.number().required('Keyword importance is required'),
  });

  return (
    <Formik
        validationSchema={validationSchema}
        initialValues={{ file: null, exclude: false, importance: '', error: null }}
        onSubmit={async (values, { setErrors }) => {
        try {
            const newKeywords: NewKeyword[] = newKeywordsTexts.flat().map(text => ({
              text: text,
              exclude: values.exclude,
              importance: Number(values.importance) || 1,
            }));
            closeModal();
            addKeywordList(newKeywords);
        } catch (error) {
            setErrors({ error: 'Error occurred while processing the file.' });
        }
    }}
    >
    {({ handleSubmit, isSubmitting, isValid, dirty, setFieldValue }) => (
      <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
        <Header as='h2' content='Add / Edit Keyword' color='teal' textAlign='center' />
        <Input
        type='file'
        label='Choose file'
        labelPosition='left'
        placeholder='Upload CSV'
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleFileChange(event, setFieldValue, setNewKeywordsTexts as Dispatch<SetStateAction<string[][]>>);
        }}
        fluid
        accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
        />

        <TextInput name='importance' placeholder='Importance' type='number' />
        <CheckBoxInput name='exclude' label='Exclude' />
        <Header as='h3' color='teal' textAlign='center'>
        {newKeywordsTexts.length > 0 && <Segment className='scrollable-container'>
            <List divided relaxed>
              {newKeywordsTexts.map((keywords, index) => (
                <List.Item key={index}>
                  <List.Content>
                    <List.Header as='h3'>{`${keywords.join(', ')}`}</List.Header>
                  </List.Content>
                </List.Item>
              ))}
            </List>
        </Segment>}
        </Header>

        <Button disabled={isSubmitting || !dirty || !isValid} loading={isSubmitting} positive content='Add / Edit' type='submit' fluid />
      </Form> 
    )}
    </Formik>
  );
}

async function readFile(file: File | null): Promise<string[][]> {
    return new Promise((resolve, reject) => {
    if (!file) {
      reject(new Error('File is null.'));
      return;
    }
  
    const reader = new FileReader();
  
    reader.onload = (event) => {
      const data = event.target?.result;
      if (data) {
        const workbook = XLSX.read(data, { type: 'binary' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        resolve(jsonData as string[][]);
      } else {
        reject(new Error('Failed to read file content.'));
      }
    };
  
    reader.onerror = (error) => {
      reject(error);
    };
  
    reader.readAsBinaryString(file);
    });
}

async function handleFileChange(
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void,
    setNewKeywords: Dispatch<SetStateAction<string[][]>>  // Adjust this type if needed
  ) {
    const file = event.currentTarget.files?.[0];
  
    if (file) {
      const fileType = file.type;
  
      if (
        fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        fileType === 'application/vnd.ms-excel' ||
        fileType === 'text/csv'
      ) {
        setFieldValue('file', file);
      } else {
        event.currentTarget.value = '';
        alert('Please choose a valid CSV or Excel file.');
      }
    }
  
    try {
      const fileContent = await readFile(file || null);
      setNewKeywords(fileContent);
    } catch (error) {
      console.error('Error reading file:', error);
    }
}

export default observer(ImportKeywordForm);
