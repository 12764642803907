import { observer } from 'mobx-react-lite'
import { useEffect } from 'react';
import { Grid, Tab } from 'semantic-ui-react'
import { useStore } from '../../app/stores/store'
import AiPlayground from './aiPlayground/AiPlayground';
import ApiTokenManagement from './apiTokensManagement/ApiTokenManagement';
import CollectorsManagement from './collectorsManagement/CollectorsManagement';
import CyabraManagement from './cyabraManagement/CyabraManagement';
import SeleniumAgentsManagement from './seleniumAgentsManagement/SeleniumAgentsManagement';
import UserManagement from './userManagement/UserManagement';
import KeywordsManagement from './keywordsManagement/KeywordsManagement';
import GptClassifierManagement from './gptClassifierManagement/GptClassifierManagement';


function AdminPage() {
    const { userStore } = useStore();

    const { loadUsers, usersList, initialLoading } = userStore;

    useEffect(() => {
        if (!usersList || !initialLoading) {
            loadUsers();
        }
    }, [loadUsers, usersList])

    const panes = [
        { menuItem: 'Users Management', render: () => <Tab.Pane><UserManagement /></Tab.Pane> },
        { menuItem: 'Api Tokens Management', render: () => <Tab.Pane><ApiTokenManagement/></Tab.Pane> },
        { menuItem: 'Keywords Management', render: () => <Tab.Pane><KeywordsManagement/></Tab.Pane> },
        { menuItem: 'Automated Reports Management', render: () => <Tab.Pane><CyabraManagement /></Tab.Pane> },
        { menuItem: 'GPT Classifier Management', render: () => <Tab.Pane><GptClassifierManagement /></Tab.Pane> },
        { menuItem: 'AI Playground', render: () => <Tab.Pane><AiPlayground /></Tab.Pane> },
        { menuItem: 'Collectors Management', render: () => <Tab.Pane><CollectorsManagement /></Tab.Pane> },
        { menuItem: 'Selenium Management', render: () => <Tab.Pane><SeleniumAgentsManagement /></Tab.Pane> },

    ]
    return (
        <Grid>
            <Grid.Column width={16}>
                <Tab
                    menu={{ fluid: true, vertical: true }}
                    menuPosition='right'
                    panes={panes}>

                </Tab>
            </Grid.Column>
        </Grid>

    )
}

export default observer(AdminPage)
