import {  Header, Icon, Segment } from 'semantic-ui-react'

const NotAuthorized = () => (
  <Segment placeholder>
    <Header icon>
      <Icon name='dont' />
      You are not authorized to view this page.
    </Header>
  </Segment>
)

export default NotAuthorized