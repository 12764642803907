import { Formik, Form, ErrorMessage } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { Button, Header, Label } from 'semantic-ui-react'
import * as Yup from 'yup'
import * as _ from 'lodash';
import HeaderSubHeader from 'semantic-ui-react/dist/commonjs/elements/Header/HeaderSubheader'
import ReCAPTCHA from "react-google-recaptcha";
import TextInput from '../../../app/common/form/TextInput'
import SelectInput from '../../../app/common/form/SelectInput'
import { skipReasonOptions } from '../../../app/common/options/skipReasonOptions'
import { useStore } from '../../../app/stores/store'

interface Props {
    skipFunction: (r: string) => void;
}

const captchaCenter: React.CSSProperties = {
    textAlign: '-webkit-center' as any
}

function SkipForm(props: Props) {
    const { modalStore, userStore } = useStore();
    
    const validationSchema = Yup.object({
        reason: Yup.string().required('Reason is required'),

    })

    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={{ reason: '', error: null }}
            onSubmit={(values, { setSubmitting }) => {
                try {
                    props.skipFunction(values.reason);
                    console.log(values.reason);
                    modalStore.closeModal();
                } catch (error) {
                    setSubmitting(false);
                    console.error('Error:', error);
                }
            }}>
            {({ handleSubmit, isSubmitting, errors, isValid, dirty }) => (
                <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                    <Header as='h2' content='Why do you want to skip this post?' color='teal' textAlign='center' />
                    <SelectInput name='reason' placeholder="Skip Reason" options={skipReasonOptions} search/>
                    <ErrorMessage name='error'
                        render={() => <Label style={{ marginBotton: 10 }} basic color='red' content={errors.error} />} />
                    <Button disabled={isSubmitting}  loading={isSubmitting} positive content="Skip" type='submit' fluid />
                </Form>
            )}
        </Formik>
    )
}

export default observer(SkipForm)
