import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import {
  ScrapedGroupedByPlatform,
  ScrapedMonthYearPosts,
  ScrapedPlatformMonthPosts,
  Scraped,
  ScrapedMonthYearGptCategories,
} from "../models/scraped";
import * as _ from "lodash";
import Moment from "moment";
import {
  platformDictionary,
  platformEnum,
} from "../common/options/platformOptions";
import { Pagination, PagingParams } from "../models/pagination";
import { PlatformStatistics } from "../models/platformStatistics";
import { ToArray } from "../common/helpers/enumHelpers";
import { store } from "./store";
import { UserRole } from "../models/user";

export default class ScrapedStore {
  reportRegistry = new Map<string, Scraped>();
  selectedReport: Scraped | undefined = undefined;
  filteredReports: _.Dictionary<Scraped[]> | undefined = undefined;
  statsByPlatform = new Map<string, PlatformStatistics>();
  statsByPlatformAndDate: ScrapedPlatformMonthPosts[] = [];
  statsByDate: ScrapedMonthYearPosts[] = [];
  gptClassifiesByDate: ScrapedMonthYearGptCategories[] = [];
  editMode = false;
  loading = false;
  downloaded = 0;
  loadingInitial = false;
  isFiltered = false;
  uploading = false;
  pagination: Pagination | null = null;
  pagingParams = new PagingParams();
  predicate = new Map().set("all", true);
  loggedIn = false;
  defaultDate: Date = new Date("2020-01-01");

  constructor() {
    makeAutoObservable(this);
  }

  get reportsList() {
    return Array.from(this.reportRegistry.values());
  }

  loadReports = async () => {
    if (store.userStore.user?.userRole !== UserRole.User) {
      this.loadingInitial = true;
      try {
        const block = 20000;
        this.downloaded = 0;
        var results: Scraped[] = [];
        //var tmpResults: Scraped[] = [];

        //const results = await agent.Scrape.scrapedPosts(block, this.downloaded);
        while (true) {
          const tmpResults = await agent.Scrape.scrapedPosts(
            block,
            this.downloaded
          );
          if (tmpResults.length == 0) break;
          runInAction(() => {
            this.downloaded += tmpResults.length;
          });
          results = [...results, ...tmpResults];
          //console.log(results)
        } // while(tmpResults.length != 0);

        this.removeReports();
        _.map(results, (report) => {
          this.setReport(report);
        });
        this.platformsStatistics();
        this.statsByPlatformAndDate = this.groupByMonthAndPlatform(results);
        this.statsByDate = this.countByMonthYear(results);
        this.gptClassifiesByDate = this.countGptCategoriesByMonthYear(results);
      } catch (error) {
        console.log(error);
      } finally {
        runInAction(() => {
          this.loadingInitial = false;
        });
      }
    }
  };

  private removeReports = () => {
    this.reportRegistry.clear();
  };

  platformsStatistics() {
    const socialList = ToArray(platformEnum);
    let all: PlatformStatistics = {
      name: "All",
      total: 0,
      online: 0,
      removed: 0,
    };

    _.forEach(socialList, (social) => {
      let isActive = _.countBy(
        this.groupedReportsByPlatform[social],
        "isActive"
      );
      let total = _.size(this.groupedReportsByPlatform[social]);
      let online = _.has(isActive, "true") ? isActive["true"] : 0;
      let removed = _.has(isActive, "false") ? isActive["false"] : 0;

      let platStat: PlatformStatistics = {
        name: social,
        total,
        online,
        removed,
      };
      this.statsByPlatform.set(social, platStat);

      all.total += total;
      all.online += online;
      all.removed += removed;
    });
    this.statsByPlatform.set("All", all);
  }

  loadReport = async (id: string) => {
    this.loadingInitial = true;
    try {
      await agent.Reports.details(id)
        .then((report) => {
          if (report) {
            runInAction(() => {
              this.selectedReport = report;
            });
            return report;
          }
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });

      const report: Scraped = {
        id: "",
        platformName: "",
        platform: 0,
        url: "",
        postedBy: "",
        postedOn: new Date(),
        createdOn: new Date(),
        isActive: true,
        category: 1,
        categories: [],
        gptScore: null,
        gptClassified: [],
        source: "",
        keywords: [],
        keywordScore: 0,
        isArchived: false,
      };
      return report;
    }
  };
  private getReport = (id: string) => {
    return this.reportRegistry.get(id);
  };

  private setReport = (report: Scraped) => {
    let key = report.id;
    let count = 1;
    while (this.reportRegistry.has(key)) {
      key = `${report.id}_dup${count}`;
      count++;
    }
    this.reportRegistry.set(key, report);
  };

  setPredicate = (predicate: string, value: string) => {
    const resetPredicate = () => {
      this.predicate.forEach((value, key) => {
        this.predicate.delete(key);
      });
    };
    switch (predicate) {
      case "all":
        resetPredicate();
        this.predicate.set("all", true);
        this.predicate.set("includeArchived", false);
        this.predicate.set("includeNonActive", false);
        break;
      case "includeArchived":
        resetPredicate();
        this.predicate.set("includeArchived", true);
        break;
      case "includeNonActive":
        resetPredicate();
        this.predicate.set("includeNonActive", true);
        break;
    }
  };

  get reportsUnfiltered() {
    return Array.from(this.reportRegistry.values());
  }

  get reportsByDate() {
    return Array.from(this.reportRegistry.values()).sort(
      (a, b) =>
        Date.parse(b.postedOn.toString()) - Date.parse(a.postedOn.toString())
    );
  }

  get groupedReportsByDate() {
    return _.groupBy(Array.from(this.reportsByDate), function (report) {
      return Moment(report.postedOn).format("DD-MMM-YYYY");
    });
  }

  get groupedReportsByPlatform() {
    return _.groupBy(Array.from(this.reportsByDate), function (report) {
      return _.get(platformEnum, report.platform);
    });
  }

  get groupedReportsByPlatformAndDate() {
    return _.groupBy(Array.from(this.reportsByDate), function (report) {
      return _.get(platformEnum, report.platform);
    });
  }

  get groupedReportsByUser() {
    return _.groupBy(Array.from(this.reportsByDate), function (report) {
      return report.modifiedBy;
    });
  }

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      params.append(key, value);
    });
    return params;
  }

  groupByMonthAndPlatform = (
    reports: Scraped[]
  ): ScrapedPlatformMonthPosts[] => {
    const result: ScrapedPlatformMonthPosts[] = [];

    const currentDate = new Date();
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(currentDate.getFullYear() - 1);

    const groupedByMonth = reports.reduce(
      (acc: Record<string, Scraped[]>, report) => {
        const date = new Date(report.postedOn);
        if (date >= oneYearAgo) {
          const month = date.toISOString().slice(0, 7);
          acc[month] = acc[month] || [];
          acc[month].push(report);
        }
        return acc;
      },
      {}
    );

    for (const [month, reports] of Object.entries(groupedByMonth)) {
      const platforms = reports.reduce(
        (acc: Record<number, Scraped[]>, report) => {
          acc[report.platform] = acc[report.platform] || [];
          acc[report.platform].push(report);
          return acc;
        },
        {}
      );

      const groupedByPlatform: ScrapedGroupedByPlatform[] = Object.entries(
        platforms
      ).map(([platform, reports]) => ({
        platform: parseInt(platform, 10),
        reports,
      }));

      for (const { platform, reports } of groupedByPlatform) {
        const platformValue = platformDictionary.get(platform);
        result.push({
          platform: platformValue || "Other",
          month,
          posts: reports.length,
        });
      }
    }

    return result;
  };

  countByYear = (reports: Scraped[]): ScrapedMonthYearPosts[] => {
    const result: ScrapedMonthYearPosts[] = [];

    const groupedByYear = reports.reduce(
      (acc: Record<string, Scraped[]>, report) => {
        const date = new Date(report.postedOn);
        const year = date.getFullYear();
        if (!acc[year]) {
          acc[year] = [];
        }
        acc[year].push(report);
        return acc;
      },
      {}
    );

    for (const [year, reports] of Object.entries(groupedByYear)) {
      result.push({ date: this.convertToDate(year), posts: reports.length });
    }

    return result;
  };

  countByMonthYear = (reports: Scraped[]): ScrapedMonthYearPosts[] => {
    const result: ScrapedMonthYearPosts[] = [];

    const groupedByMonthYear = reports.reduce(
      (acc: Record<string, Scraped[]>, report) => {
        const date = new Date(report.postedOn);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const monthYear = `${year}-${month.toString().padStart(2, "0")}`;
        if (!acc[monthYear]) {
          acc[monthYear] = [];
        }
        acc[monthYear].push(report);
        return acc;
      },
      {}
    );

    for (const [monthYear, reports] of Object.entries(groupedByMonthYear)) {
      result.push({
        date: this.convertToDate(monthYear),
        posts: reports.length,
      });
    }

    return result;
  };

  countGptCategoriesByMonthYear = (
    reports: Scraped[]
  ): ScrapedMonthYearGptCategories[] => {
    const result: ScrapedMonthYearGptCategories[] = [];

    const groupedByMonthYear = reports.reduce(
      (acc: Record<string, Scraped[]>, report) => {
        const date = new Date(report.postedOn);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const monthYear = `${year}-${month.toString().padStart(2, "0")}`;
        if (!acc[monthYear]) {
          acc[monthYear] = [];
        }
        acc[monthYear].push(report);
        return acc;
      },
      {}
    );

    for (const [monthYear, reports] of Object.entries(groupedByMonthYear)) {
      const categoryCounts: Record<string, number> = {
        antisemitism: 0,
        holocaust: 0,
        antiZionism_extremist: 0,
        antiIsrael_extremist: 0,
        misinformation: 0,
        endorsement_of_terrorism: 0,
        pro_palestine: 0,
      };

      for (const report of reports) {
        for (const category of report.gptClassified) {
          if (categoryCounts.hasOwnProperty(category)) {
            categoryCounts[category]++;
          }
        }
      }

      result.push({
        date: new Date(monthYear),
        antisemitism: categoryCounts["antisemitism"],
        holocaust: categoryCounts["holocaust"],
        antiZionism_extremist: categoryCounts["antiZionism_extremist"],
        antiIsrael_extremist: categoryCounts["antiIsrael_extremist"],
        misinformation: categoryCounts["misinformation"],
        endorsement_of_terrorism: categoryCounts["endorsement_of_terrorism"],
        pro_palestine: categoryCounts["pro_palestine"],
      });
    }
    return result;
  };

  convertToDate = (dateString: string): Date => {
    return new Date(dateString);
  };
}
