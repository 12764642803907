import React, { useEffect } from 'react'
import { Container, Header, Segment, Image, Button } from 'semantic-ui-react'
import { history } from '../..';
import { DefaultRoute } from '../../app/common/defaults/routeDefault';
import { useStore } from '../../app/stores/store'
import LoginForm from '../users/LoginForm';
import RegisterForm from '../users/RegisterForm';


function HomePage() {
    const { modalStore, userStore } = useStore();
    useEffect(() => {

        if (userStore.isLoggedIn && userStore.isActivated) {
            history.push(DefaultRoute)
        }

    }, [userStore.isLoggedIn, userStore.isActivated])

    return (
        <Segment inverted textAlign='center' vertical className='masthead'>

            <Container fluid>
                <Image verticalAlign='top' src='/assets/logo.png' alt='logo' />
                <Header as='h2' inverted>
                    
                    FOA Dashboard
                </Header>
                <Header as='h2' inverted />
                <Button onClick={() => {
                    modalStore.openModal(<LoginForm />)
                    } } 
                    size='huge' inverted>
                    Login
                </Button>
                <Button onClick={() => {
                    modalStore.openModal(<RegisterForm />)
                    } }
                size='huge' inverted>
                    Register
                </Button>
            </Container>
        </Segment>
    )
}

export default HomePage
