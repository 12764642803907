import { createContext, useContext } from "react";
import ApiTokenStore from "./apiTokenStore";
import KeywordsStore from "./keywordsStore";
import CollectorsStore from "./collectorsStore";
import CommonStore from "./commonStore";
import CyabraStore from "./cyabraStore";
import ModalStore from "./modalStore";
import MonitorsStore from "./monitorsStore";
import ProfileStore from "./profileSore";
import ReportStore from "./reportStore";
import ScrapedStore from "./scrapedStore";
import SeleniumAgentsStore from "./seleniumAgentsStore";
import UserStore from "./userStore";
import VoteStore from "./voteStore";
import GptClassifierStore from "./gptClassifierStore";

interface Store{
    reportStore: ReportStore,
    scrapedStore: ScrapedStore,
    userStore: UserStore,
    commonStore: CommonStore,
    modalStore: ModalStore,
    profileStore: ProfileStore,
    monitorsStore: MonitorsStore,
    voteStore: VoteStore,
    cyabraStore: CyabraStore,
    apiTokenStore: ApiTokenStore,
    keywordsStore: KeywordsStore,
    gptClassifierStore: GptClassifierStore,
    collectorsStore: CollectorsStore,
    seleniumAgentsStore: SeleniumAgentsStore
}

export const store: Store = {
    reportStore: new ReportStore(),
    scrapedStore: new ScrapedStore(),
    userStore: new UserStore(),
    commonStore: new CommonStore(),
    modalStore: new ModalStore(),
    profileStore: new ProfileStore(),
    monitorsStore: new MonitorsStore(),
    voteStore: new VoteStore(),
    cyabraStore: new CyabraStore(),
    apiTokenStore: new ApiTokenStore(),
    keywordsStore: new KeywordsStore(),
    gptClassifierStore: new GptClassifierStore(),
    collectorsStore: new CollectorsStore(),
    seleniumAgentsStore: new SeleniumAgentsStore()
}

export const StoreContext = createContext(store);

export function useStore(){
    return useContext(StoreContext);
}