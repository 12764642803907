import { NavLink, RouteComponentProps, RouteProps } from "react-router-dom";
import { Menu } from "semantic-ui-react";
import { UserRole } from "../models/user";
import { useStore } from "../stores/store";


interface Props extends RouteProps {
    allowedRoles: UserRole[];
    name: string | undefined;
    link: string;
}

export function RoleBasedMenuItem({ component: Component, allowedRoles, name, link, ...rest }: Props) {
    const { userStore: { isLoggedIn, userRole } } = useStore();

    return (
        (isLoggedIn && userRole && allowedRoles.includes(userRole)) ?
            <Menu.Item name={name} as={NavLink} to={link} /> :
            <></>
    )

}
