import { useState } from 'react';
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStore } from '../../app/stores/store';
import NoPosts from '../errors/NoPosts';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { Button, Card, Dimmer, Form, Grid, Header, Icon, Image, Label, Placeholder, Segment } from 'semantic-ui-react'
import VoteButton from './components/VoteButton';
import { antiIsraeliCategoryOptions, categoryEnum } from '../../app/common/options/categoryOptions';
import * as _ from 'lodash';
import { platformBycyabraPlatformCode, platformEnumBycyabraPlatformCode, TaskClassifier } from '../../app/common/options/platformOptions';
import { PlaceholderEmbed } from 'react-social-media-embed';
import SocialFrame from './components/SocialFrame';
import { PostToVote } from './components/PostToVote';
import { Formik } from 'formik';
import SelectInput from '../../app/common/form/SelectInput';
import * as Yup from 'yup'
import SkipForm from './components/SkipForm';
import { toast } from 'react-toastify';

function VotePageAntiIsraeli() {
    const { voteStore } = useStore();
    const { userStore, modalStore } = useStore();
    const { loadedVotePost, getAntiIsraeliPostToVote, skipAntiIsraeliPost, voteAntiIsraeliPost, loading, initialLodaing } = voteStore;

    const [isShow, setIsShow] = useState(false);
    const [platform, setPlatform] = useState<string | undefined>(undefined);

    const validationSchema = Yup.object({
        category: Yup.number().min(1, 'Category is required').required('Category is required'),
    })

    const vote = async (category: categoryEnum[]) => {
        if (category.length <= 2 && category.length > 0) {
            if (category.includes(categoryEnum.NonHarmful))
                category = [categoryEnum.NonHarmful];
            setIsShow(true);
            await voteAntiIsraeliPost(category)
            setTimeout(() => setIsShow(false), 1000)
        }
        else {
            toast.error("Please choose up to 2 categories");
        }

    }

    const handleSkipButtonClick = () => {
        modalStore.openModal(<SkipForm skipFunction={(r: string) => skip(r)} />);
    }

    const skip = async (reason: string) => {
        setIsShow(true);
        await skipAntiIsraeliPost(reason)
        setTimeout(() => setIsShow(false), 1000)
    }

    const aiScoreStatus = (post: PostToVote) => {
        if (!_.isNil(post.score)) {
            let percentage = (post.score * 100);

            if (percentage < 45)

                return (<Label color='green' attached='bottom right'>
                    <Icon name='thermometer empty' /> {percentage.toFixed(2)}%
                </Label>)
            else if (percentage < 75)

                return (<Label color='yellow' attached='bottom right'>
                    <Icon name='thermometer half' /> {percentage.toFixed(2)}%
                </Label>)
            else

                return (<Label color='red' attached='bottom right'>
                    <Icon name='thermometer full' /> {percentage.toFixed(2)}%
                </Label>)

        }
    }

    useEffect(() => {
        if (_.isNil(loadedVotePost))
            getAntiIsraeliPostToVote();
    }, [loadedVotePost, getAntiIsraeliPostToVote]);

    useEffect(() => {
        if (!_.isNil(loadedVotePost)) {

            let plat = platformEnumBycyabraPlatformCode.get(loadedVotePost.platform)
            setPlatform(plat)
        }
    })

    if (_.isNil(loadedVotePost) && loading && initialLodaing)
        return <LoadingComponent content={'Loading post to vote...'} />

    else if (_.isNil(loadedVotePost) && !loading)
        return <NoPosts />

    else
        return (
            <>
                <Dimmer.Dimmable as={Segment} dimmed={isShow} style={{ minHeight: 750, height: '100%' }}>
                    <Header as='h5'>Please select the most appropriate category from the selection below</Header>
                    {_.isNil(loadedVotePost) && <Placeholder>
                        <Placeholder.Header image>
                            <Placeholder.Line />
                            <Placeholder.Line />
                        </Placeholder.Header>
                        <Placeholder.Paragraph>
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                        </Placeholder.Paragraph>
                        <Placeholder.Paragraph>
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                        </Placeholder.Paragraph>
                        <Placeholder.Paragraph>
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                        </Placeholder.Paragraph>
                        <Placeholder.Paragraph>
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                        </Placeholder.Paragraph>
                    </Placeholder>}

                    {!_.isNil(loadedVotePost) && <>
                        <Card.Group>
                            <Card fluid>
                                <Card.Content extra>
                                    {/*<div className='ui seven buttons'>*/}
                                    {/*antiIsraeliCategoryOptions.map(category =>
                                            <VoteButton key={category.value} color={category.color} text={category.text} onClick={() => vote(category.value)} />)*/}
                                    {/*</div>*/}
                                    <Formik
                                        enableReinitialize
                                        initialValues={{ category: [] }}
                                        onSubmit={values => vote(values.category)}>
                                        {({ handleSubmit, values, isValid }) => (
                                            <Form className="ui form" onSubmit={handleSubmit} autoComplete="off">
                                                <Grid centered>
                                                    <Grid.Column computer={4} tablet={8} mobile={16}>
                                                        <SelectInput multiple notClearable name='category' placeholder="Hate Speech Category" options={antiIsraeliCategoryOptions} search />
                                                    </Grid.Column>
                                                    <Grid.Column computer={4} tablet={8} mobile={16}>
                                                        <Button
                                                            validation={validationSchema}
                                                            disabled={!isValid}
                                                            floated="right"
                                                            positive
                                                            type="submit"
                                                            content="Vote"
                                                            fluid
                                                            style={{ marginTop: "4px" }}
                                                        />
                                                    </Grid.Column>
                                                    <Grid.Column computer={4} tablet={8} mobile={16}>
                                                        <Button
                                                            onClick={handleSkipButtonClick}
                                                            disabled={false}
                                                            primary
                                                            type="button"
                                                            content="Skip"
                                                            fluid
                                                            style={{ marginTop: "4px" }}
                                                        />
                                                    </Grid.Column>

                                                </Grid>
                                            </Form>
                                        )}
                                    </Formik>
                                </Card.Content>
                                <Card.Content textAlign='center' alignItems='center' style={{ maxHeight: '500px' }}>
                                    <SocialFrame url={loadedVotePost.content_url} platform={loadedVotePost.platform} width="100%" height="100%" isCyabra={false} />
                                </Card.Content>
                                <Card.Content>
                                    {!_.isNil(platform) &&
                                        <Image
                                            floated='right'
                                            size='mini'
                                            src={`/assets/${platform.toLowerCase()}-icon.png`}
                                        />

                                    }
                                    <Card.Header>{loadedVotePost.platform} - {_.capitalize(loadedVotePost.content_type)} {userStore.isPowerUser && aiScoreStatus(loadedVotePost)}</Card.Header>
                                    <Card.Meta>
                                        <div>
                                            <div>Posted By: <strong>{loadedVotePost.author_name}</strong>, Posted At: <strong>{loadedVotePost.date}</strong>, Url: <strong><a href={loadedVotePost.content_url}>Click Here</a></strong></div>
                                            <div>Likes: <strong>{loadedVotePost.like_count}</strong>, Comments: <strong>{loadedVotePost.comment_count}</strong>, Reposts: <strong>{loadedVotePost.share_count}</strong></div>
                                        </div>
                                    </Card.Meta>
                                    <Card.Description>
                                        {loadedVotePost.text}
                                    </Card.Description>

                                </Card.Content>
                                <Card.Content extra>
                                    <div style={{ paddingTop: 10 }}>
                                        <img style={{ width: 'auto', maxHeight: '500px' }} src={loadedVotePost.image_url} alt="" />
                                    </div>
                                </Card.Content>
                            </Card>
                        </Card.Group></>}


                    <Dimmer active={isShow} onClickOutside={() => setIsShow(!isShow)}>
                        <Header as='h2' icon inverted>
                            <Icon name='thumbs up outline' />
                            Thank you for voting! Next post is loading...
                        </Header>
                    </Dimmer>
                </Dimmer.Dimmable>
            </>
        );
}

export default observer(VotePageAntiIsraeli);
