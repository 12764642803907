import { useEffect } from 'react'
import * as _ from 'lodash';
import { Button, Header, Segment, Table } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import AgentTableRow from './SeleniumAgentsTableRow';
import AgentsForm from './SeleniumAgentsForm';

function AgentsManagement() {
    const { seleniumAgentsStore, modalStore } = useStore();
    const { getAgents, agentsLoaded, agents, deleteAgent, setAgent } = seleniumAgentsStore;
    const { openModal } = modalStore;

    useEffect(() => {
        if (_.isNil(agents))
            getAgents()
    }, [agents, getAgents])

    return (
        <>
            <Segment clearing>
                <Header as='h2' floated='left'>Agents Management</Header>
                <Button color='green' floated='right' onClick={() => openModal(<AgentsForm />)}>New Agent</Button>
            </Segment>

            {agentsLoaded ?
                <Table celled singleLine>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Type</Table.HeaderCell>
                            <Table.HeaderCell>Created By</Table.HeaderCell>
                            <Table.HeaderCell>Created On</Table.HeaderCell>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {_.map(agents, (agent) => {
                            return <AgentTableRow
                                agent={agent}
                                key={agent.id}
                                setAgent={() => setAgent(agent.id) }
                                deleteAgent={() => deleteAgent(agent.id)}
                                updateAgent={() => openModal(<AgentsForm agent={agent} />)}
                            />;
                        })}
                    </Table.Body>
                </Table> :
                <LoadingComponent content="Loading agents..." />
            }
        </>

    );
}

export default observer(AgentsManagement)