import { useState } from 'react'
import * as _ from 'lodash';
import { Button, Grid, Header, Segment, Table, TextArea, Form, List, PlaceholderParagraph } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import { GptRecord } from './GptClassifierManagementRecord';
import GptClassifierManagementTableRow from './GptClassifierManagementTableRow';
import { v4 as uuidv4 } from 'uuid';


function GptClassifierManagement() {
    const { gptClassifierStore } = useStore();
    const { rankRandom, randomResult } = gptClassifierStore;

    const [input, setInput] = useState<string | undefined>("");
    const [phrases, setPhrases] = useState<GptRecord[]>([]);


    const deleteRecord = (phrase: string) => {
        if (!_.isNil(phrase)) {
            setPhrases((prevArray) => prevArray.filter((item) => item.phrase !== phrase));
        }
    }

    const isJSON = (str: string) => {
        try {
          JSON.parse(str);
          return true;
        } catch (e) {
          return false;
        }
      }

    return (
        <>
            <Segment clearing>
                <Header as='h2' floated='left'>GPT Auto Classifier</Header>
                <Button color="green" disabled={true} floated="right" onClick={() => console.log("rank")}>Rank Posts</Button>
            </Segment>
            <Segment clearing>
                <Grid.Column>
                    <Button color="blue" floated="right" onClick={() => rankRandom()}>Rank Random Post</Button>
                    <TextArea
                    disabled={true}
                    rows={30}
                    style={{ float: "left", width: "100%", height: "100%", fontSize: "18px", fontWeight: "bold" }}
                    value={randomResult}
                    />
                </Grid.Column>
            </Segment>

        </>

    );
}

export default observer(GptClassifierManagement)