export const platformOptions = [
    { text: 'Other', value: 1 },
    { text: 'Instagram', value: 2 },
    { text: 'TikTok', value: 3 },
    { text: 'Twitter', value: 4 },
    { text: 'LinkedIn', value: 5 },
    { text: 'Vk', value: 6 },
    { text: 'Tumbler', value: 7 },
    { text: 'Facebook', value: 8 },
    { text: 'YouTube', value: 9 },
    { text: 'Gab', value: 10 },
    { text: 'Pinterest', value: 11 },
    { text: 'Snapchat', value: 12 },
    { text: 'Reddit', value: 13 },
    { text: 'Parler', value: 14 },
    { text: 'Telegram', value: 15 },
    { text: 'Discord', value: 16 },
]

export const platformDictionary: Map<number, string> = new Map<number, string>([
    [1, 'Other'],
    [2, 'Instagram'],
    [3, 'TikTok'],
    [4, 'Twitter'],
    [5, 'LinkedIn'],
    [6, 'Vk'],
    [7, 'Tumbler'],
    [8, 'Facebook'],
    [9, 'YouTube'],
    [10, 'Gab'],
    [11, 'Pinterest'],
    [12, 'Snapchat'],
    [13, 'Reddit'],
    [14, 'Parler'],
    [15, 'Telegram'],
    [16, 'Discord']
]);

export enum platformEnum {
    Other = 1,
    Instagram = 2,
    TikTok = 3,
    Twitter = 4,
    LinkedIn = 5,
    Vk = 6,
    Tumbler = 7,
    Facebook = 8,
    YouTube = 9,
    Gab = 10,
    Pinterest = 11,
    Snapchat = 12,
    Reddit = 13,
    Parler = 14,
    Telegram = 15,
    Discord = 16
}

export const platformBycyabraPlatformCode: Map<string, string> = new Map<string, string>([
    ['NA', 'Other'],
    ['FB', 'Facebook'],
    ['T', 'Twitter'],
    ['I', 'Instagram'],
    ['V', 'Vk'],
    ['TG', 'Telegram'],
    ['N', 'News'],
    ['BD', 'Blogs'],
    ['TK', 'Tiktok'],
    ['RD', 'Reddit'],
    ['Y', 'Youtube'],
]);

export const platformEnumBycyabraPlatformCode: Map<string, string> = new Map<string, string>([
    ['NA', 'Other'],
    ['FB', 'Facebook'],
    ['T', 'Twitter'],
    ['I', 'Instagram'],
    ['V', 'Vk'],
    ['TG', 'Telegram'],
    ['N', 'Other'],
    ['BD', 'Other'],
    ['TK', 'Tiktok'],
    ['RD', 'Reddit'],
    ['Y', 'Youtube'],
]);

export enum cyabraPlatformEnum {
    Facebook = 2,
    Twitter = 3,
    Instagram = 4,
    Vk = 5,
    Telegram = 6,
    News = 7,
    Blogs = 8,
    Tiktok = 9,
    Reddit = 10,
    Youtube = 11,
}

export const platformTextValueBycyabraPlatformEnum: Map<cyabraPlatformEnum, string> = new Map<cyabraPlatformEnum, string>([
    [cyabraPlatformEnum.Facebook, 'Facebook'],
    [cyabraPlatformEnum.Twitter, 'Twitter'],
    [cyabraPlatformEnum.Instagram, 'Instagram'],
    [cyabraPlatformEnum.Vk, 'Vk'],
    [cyabraPlatformEnum.Telegram, 'Telegram'],
    [cyabraPlatformEnum.News, 'Other'],
    [cyabraPlatformEnum.Blogs, 'Other'],
    [cyabraPlatformEnum.Tiktok, 'Tiktok'],
    [cyabraPlatformEnum.Reddit, 'Reddit'],
    [cyabraPlatformEnum.Youtube, 'Youtube'],
]);

export const cyabraPlatformOptions = [
    { text: 'Facebook', value: 2 },
    { text: 'Twitter', value: 3 },
    { text: 'Instagram', value: 4 },
    { text: 'Vk', value: 5 },
    { text: 'Telegram', value: 6 },
    { text: 'News', value: 7 },
    { text: 'Blogs', value: 8 },
    { text: 'Tiktok', value: 9 },
    { text: 'Reddit', value: 10 },
    { text: 'Youtube', value: 11 }
]

export enum TaskClassifier {
    AntiSemitic = 1,
    AntiIsraeli = 2
}

export const taskClassifierOptions = [
    { text: 'Anti Semitic', value: 1 },
    { text: 'Anti Israeli', value: 2 }
]