import React, { useEffect } from "react";
import { Container } from "semantic-ui-react";
import ReportDashboard from "../../features/reports/dashboard/ReportDashboard";
import NavBar from "./NavBar";
import { observer } from "mobx-react-lite";
import { Route, Switch, useLocation } from 'react-router-dom';
import HomePage from "../../features/home/HomePage";
import ReportForm from "../../features/reports/form/ReportForm";
import ReportDetails from "../../features/reports/details/ReportDetails";
import { toast, ToastContainer } from "react-toastify";
import NotFound from "../../features/errors/NotFound";
import { useStore } from "../stores/store";
import LoadingComponent from "./LoadingComponent";
import ModalContainer from "../common/modals/ModalContainer";
import { history } from "../..";
import ProfilePage from "../../features/profile/ProfilePage";
import ReportStatisticDashboard from "../../features/reports/statistics/ReportStatisticDashboard";
import PrivateRoute from "./PrivateRoute";
import { RoleBasedRoute } from "./RoleBasedRoute";
import { UserRole } from "../models/user";
import AdminPage from "../../features/admin/AdminPage";
import VotePage from "../../features/swipe/VotePage";
import { useSignalR } from "../common/hooks/useSignalrHook";
import VotePageAntiIsraeli from "../../features/swipe/VotePageAntiIsraeli";
import ScrapedStatisticDashboard from "../../features/scraped/statistics/ScrapedStatisticDashboard";
import VolunteersPage from "../../features/volunteers/VolunteersPage";
import SettingsPage from "../../features/settings/SettingsPage";


function App() {
    const location = useLocation();
    const { commonStore, userStore } = useStore();

    useSignalR(userStore.isPowerUser);

    useEffect(() => {
        if (commonStore.token) {
            userStore.getUser().finally(() => {
                commonStore.setAppLoaded()
            })
            userStore.getUsersCount();

        } else {
            history.push('/')
            commonStore.setAppLoaded()
        }

    }, [commonStore, userStore])

    if (!commonStore.appLoaded) return <LoadingComponent content="Loading app..." />

    return (
        <>

            <ToastContainer position="bottom-right" hideProgressBar />
            <ModalContainer />
            <Route path='/' exact component={HomePage} />
            <Route
                path={'/(.+)'}
                render={() => (
                    <>
                        <NavBar />
                        <Container style={{ marginTop: "5em" }}>
                            <Switch>
                                <RoleBasedRoute path='/reports' exact component={ReportDashboard} allowedRoles={[UserRole.PowerUser]} />
                                <RoleBasedRoute path='/reports/:id' component={ReportDetails} allowedRoles={[UserRole.PowerUser]} />
                                <PrivateRoute key={location.key} path={['/createReport', '/manage/:id']} component={ReportForm} /*RoleBasedRoute, allowedRoles deprecated*//>
                                <RoleBasedRoute path='/statistics' component={ReportStatisticDashboard} allowedRoles={[UserRole.PowerUser]} />
                                <RoleBasedRoute path='/scraped' component={ScrapedStatisticDashboard} allowedRoles={[UserRole.PowerUser]} />
                                <RoleBasedRoute path='/profile/:username' component={ProfilePage} allowedRoles={[UserRole.PowerUser]} />
                                <PrivateRoute path='/vote' component={VotePageAntiIsraeli} />
                                <PrivateRoute path='/settings' component={SettingsPage} />
                                <RoleBasedRoute path='/admin' component={AdminPage} allowedRoles={[UserRole.PowerUser]} />
                                <RoleBasedRoute path='/volunteers' component={VolunteersPage} allowedRoles={[UserRole.PowerUser, UserRole.Manager, UserRole.TeamLeader]} />
                                <PrivateRoute component={NotFound} />
                            </Switch>

                        </Container>

                    </>
                )} />

        </>
    );
}

export default observer(App);
