import { Formik, Form, ErrorMessage } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { Button, Header, Label } from 'semantic-ui-react'
import TextInput from '../../app/common/form/TextInput'
import { useStore } from '../../app/stores/store'
import * as Yup from 'yup'
import * as _ from 'lodash';
import MultiFactorAuthForm from './MultiFactorAuthForm'
import ForgotPassForm from './ForgotPassForm'
import HeaderSubHeader from 'semantic-ui-react/dist/commonjs/elements/Header/HeaderSubheader'
import ReCAPTCHA from "react-google-recaptcha";

const captchaCenter: React.CSSProperties = {
    textAlign: '-webkit-center' as any
}

function LoginForm() {
    const { modalStore, userStore } = useStore();
    const [isCaptchaValid, setIsCaptchaValid] = useState<boolean>(true);
    function onChange(value: string | null) {
        if (!_.isNil(value)) {
            userStore.verifyCaptcha(value).then(response => {
                if (response) {
                    setIsCaptchaValid(response.isSuccess);
                }
            })
        }
    }
    const validationSchema = Yup.object({
        email: Yup.string().required('Email is required').email(),
        password: Yup.string().required('Password is required'),

    })

    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={{ email: '', password: '', error: null }}
            onSubmit={(values, { setErrors }) => {
                return userStore.loginMultiFactorAuth(values)
                    .then(() => {
                        modalStore.openModal(<MultiFactorAuthForm />);
                    }).catch(() => setErrors({ error: 'Invalid email or password' }));
            }}>
            {({ handleSubmit, isSubmitting, errors, isValid, dirty }) => (
                <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                    <Header as='h2' content='Login to FOA' color='teal' textAlign='center' />
                    <TextInput name='email' placeholder='Email' />
                    <TextInput name='password' placeholder='Password' type='password' />
                    <ErrorMessage name='error'
                        render={() => <Label style={{ marginBotton: 10 }} basic color='red' content={errors.error} />} />
                    {/*<div*/}
                    {/*    style={captchaCenter}>*/}
                    {/*    <ReCAPTCHA*/}
                    {/*        sitekey="6Ld8ML8oAAAAAMXGCgyoUbsQ4R70Ymwh4gQfgg8K"*/}
                    {/*        onChange={onChange}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    <Button disabled={isSubmitting || !isValid || !isCaptchaValid || !dirty} loading={isSubmitting} positive content="Login" type='submit' fluid />

                    <HeaderSubHeader style={{ marginTop: 10 , cursor: 'pointer'}} onClick={() => modalStore.openModal(<ForgotPassForm />)}>Forgot Password</HeaderSubHeader>
                </Form>
            )}
        </Formik>
    )
}

export default observer(LoginForm)
