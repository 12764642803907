import { useField } from 'formik';
import React from 'react'
import { Form, Label, Select } from 'semantic-ui-react';

interface Props{
    placeholder: string;
    name: string;
    options: any;
    label?: string;
    search?: boolean;
    notClearable?: boolean;
    multiple?: boolean;
}
function SelectInput(props: Props) {
    const [field, meta, helpers] = useField(props.name)
  return (
    <Form.Field error={meta.touched && !!meta.error}>
        <label>{props.label}</label>
        <Select
        clearable={!props.notClearable}
        options={props.options}
        value={field.value || null}
        onChange={(e, d) => helpers.setValue(d.value)}
        onBlur={()=> helpers.setTouched(true)}
        placeholder={props.placeholder}
        search={props.search}
        multiple={props.multiple}
        />
        {meta.touched && meta.error ? (
            <Label basic color='red'>{meta.error}</Label>
        ) : null}
    </Form.Field>
  )
}

export default SelectInput
