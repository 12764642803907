import { observer } from 'mobx-react-lite';
import { Button, Icon, Table } from 'semantic-ui-react'
import * as _ from 'lodash'

import { AiRecord } from './AiPlaygroundRecord';

interface Props {
    phrase: AiRecord;
    voteYes: () => void;
    voteNo: () => void;
    remove: (phrase: string) => void;
}
function AiPlaygroundTableRow({ phrase, voteYes, voteNo, remove }: Props) {

    return (

        <Table.Row key={phrase.id}>
            <Table.Cell>{phrase.phrase}</Table.Cell>
            <Table.Cell>{(phrase.score * 100).toFixed(2)}%</Table.Cell>
            {/*<Table.Cell>
                <Button onClick={() => voteYes()} color="green" floated="left" icon='thumbs up outline' />
                <Button onClick={() => voteNo()} color="red" icon="thumbs down outline" floated="right" />
            </Table.Cell>*/ }
            <Table.Cell>
                <Button onClick={() => remove(phrase.phrase)} color="grey" icon="delete" fluid />
            </Table.Cell>
        </Table.Row>
    )
}

export default observer(AiPlaygroundTableRow)
