import { observer } from 'mobx-react-lite';
import { Table, Popup, Button } from 'semantic-ui-react'
import DeleteButton, { SetButton } from '../../../app/common/confirm/ConfirmButton';
import { GetSeleniumAgentDto } from '../../../app/models/seleniumAgents';
import { seleniumAgentNameByType } from '../../../app/models/seleniumAgentsTypes';

interface Props {
    agent: GetSeleniumAgentDto;
    setAgent: () => void;
    updateAgent: () => void;
    deleteAgent: () => void;
}
function SeleniumAgentsTableRow({ agent, deleteAgent, updateAgent, setAgent }: Props) {

    return (

        <Table.Row key={agent.id}>
            <Table.Cell >{seleniumAgentNameByType.get(agent.agentType)}</Table.Cell>
            <Table.Cell >{agent.createdBy}</Table.Cell>
            <Table.Cell >{agent.createdOn.toString().split('T')[0]}</Table.Cell>
            <Table.Cell width="2" textAlign="right" >
                {agent.isSelected ?
                    <SetButton disabled={true} text="Sure you want to set?" func={() => setAgent()}></SetButton> :
                    <Popup content='Set agent' trigger={<SetButton text="Sure you want to set?" func={() => setAgent()}></SetButton>} />}
                <Button size='mini' icon="edit" color="green" onClick={() => updateAgent()}></Button>
                <Popup content='Delete agent' trigger={<DeleteButton text="Sure you want to delete?" func={() => deleteAgent()}></DeleteButton>} />
            </Table.Cell>
        </Table.Row>
    )
}

export default observer(SeleniumAgentsTableRow)