import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { store } from "./store";
import * as _ from 'lodash';
import { toast } from "react-toastify";
import { ApiToken } from "../../features/admin/apiTokensManagement/ApiToken";
import { AddNewTokenDto } from "../../features/admin/apiTokensManagement/AddNewTokenDto";

export default class ApiTokenStore {
    tokens: ApiToken[] | null = null;
    loading: boolean = false;
    tokensLoaded: boolean = false;
    newAddedToken: string | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    getTokens = async () => {
        try {
            if (_.isNil(this.tokens)) {
                this.tokensLoaded = false;
                this.loading = true;
                const results = await agent.ApiTokens.getApiTokens();
                this.tokens = results;
                this.loading = false;
                this.tokensLoaded = true;
            }
        }
        catch (e) {
            toast.error("An error occurred while loading tokens.");
        }
    }

    addNewToken = async (addTokenDto: AddNewTokenDto) => {
        try {
            var result = await agent.ApiTokens.createToken(addTokenDto);
            store.modalStore.closeModal();
            if (!_.isNil(result)) {
                toast.success("New token added.");
                this.newAddedToken = result.token;
                this.tokens = null;
                this.getTokens();
            }
            else {
                toast.error("New token was not added.");
            }
        }
        catch (e) {
            toast.error("An error occurred while adding new token.");
        }
    }


    removeToken = async (id: string) => {
        try {
            var result = await agent.ApiTokens.deleteToken(id);
            if (!_.isNil(result)) {
                toast.success("Token deleted.");
                this.tokens = null;
                this.getTokens();
            }
            else {
                toast.error("Token was not deleted.");
            }
            store.modalStore.closeModal();
            return result;
        }
        catch (e) {
            toast.error("An error occurred while removing token.");
        }
    }

    removeNewAddedToken = () => this.newAddedToken = null;

}
