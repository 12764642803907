import { observer } from 'mobx-react-lite';
import { Table, Popup } from 'semantic-ui-react'
import * as _ from 'lodash'
import DeleteButton from '../../../app/common/confirm/ConfirmButton';
import { ApiToken } from './ApiToken';
import { webhookTypeMapper } from './WebhookType';

interface Props {
    token: ApiToken;
    deleteToken: () => void;
}
function ApiTokenTableRow({ token, deleteToken }: Props) {

    return (

        <Table.Row key={token.id}>
            <Table.Cell >{token.name}</Table.Cell>
            <Table.Cell >{webhookTypeMapper[token.type]}</Table.Cell>
            <Table.Cell >{token.createdBy}</Table.Cell>
            <Table.Cell >{token.createdOn.toString().split('T')[0]}</Table.Cell>
            <Table.Cell width="2" textAlign="right" >
                <Popup content='Delete scan token' trigger={<DeleteButton text="Sure you want to delete?" func={() => deleteToken()}></DeleteButton>} />
            </Table.Cell>
        </Table.Row>
    )
}

export default observer(ApiTokenTableRow)
