/* #region  Imports */
import { Grid } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { observer } from "mobx-react-lite";
import { useParams, useHistory} from 'react-router-dom'
import { useEffect } from "react";
import ReportDetailedHeader from "./ReportDetailedHeader";
import ReportDetailedInfo from "./ReportDetailedInfo";
import ReportDetailedSideBar from "./ReportDetailedSideBar";

/* #endregion */

function ReportDetails() {
  /* #region  State and store */
  const { reportStore } = useStore();
  const { selectedReport: report, loadReport, loadingInitial} = reportStore;
  const {id} = useParams<{id: string}>();
  const history = useHistory();


  useEffect(()=>{
    if(id) loadReport(id)
  },[id, loadReport,history])
  /* #endregion */

  if (loadingInitial || !report) return <LoadingComponent content="Loading..." />;


  return (
    <Grid>
      <Grid.Column width={10}>
        <ReportDetailedHeader report={report}/>
        <ReportDetailedInfo report={report}/>
      </Grid.Column>
      <Grid.Column width={6}>
        <ReportDetailedSideBar report={report}/>
      </Grid.Column>
    </Grid>
  );
}

export default observer(ReportDetails);
