import { observer } from 'mobx-react-lite';
import React from 'react'
import { Button, Label, Table } from 'semantic-ui-react'
import { Report } from '../../app/models/report';
import Moment from 'moment';
import { platformEnum } from '../../app/common/options/platformOptions';
import * as _ from 'lodash'
import { Link } from 'react-router-dom';

interface Props{
    report?: Report;
}
function ProfileReportsTableRow({report}: Props) {

return(
    
        <Table.Row>
            <Table.Cell>{_.get(platformEnum, report?.platform!)}</Table.Cell>
            <Table.Cell width={3}><Button as={Link} to={`/reports/${report?.id}`} primary content='Click here' fluid/></Table.Cell>
            <Table.Cell>{Moment(report?.createdOn).format("DD-MMM-YYYY")}</Table.Cell>
            <Table.Cell width={1} textAlign='center'>{report?.isArchived ? <Label basic color='yellow' content='Archived'/> : <Label basic color='grey' content='Not'/>}</Table.Cell>
            <Table.Cell width={1} textAlign='center'>{report?.isActive ? <Label basic color='red' content='Online'/> : <Label basic color='green' content='Removed'/>}</Table.Cell>
        </Table.Row>    
) }

export default observer(ProfileReportsTableRow)
