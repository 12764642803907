import { AgChartsReact } from 'ag-charts-react';
import { AgChartOptions } from 'ag-charts-community';
import { useStore } from '../../../app/stores/store';



const ScrapedGptClassifiedStatisticLog = () => {
    const { scrapedStore } = useStore();
    const { gptClassifiesByDate, convertToDate } = scrapedStore;

    const options: AgChartOptions = {
        data: gptClassifiesByDate.filter(item => item.date >= convertToDate("2023-01")).sort((a, b) => a.date.getTime() - b.date.getTime()),
        title: {
            text: 'Activity Over Time',
        },
        subtitle: {
            text: 'log scale',
        },
        series: [
            {
                type: 'line',
                xKey: 'date',
                yKey: 'antisemitism',
                title: 'Antisemitism',
            },
            {
                type: 'line',
                xKey: 'date',
                yKey: 'holocaust',
                title: 'Holocaust',
            },
            {
                type: 'line',
                xKey: 'date',
                yKey: 'antiZionism_extremist',
                title: 'Anti-Zionism',
            },
            {
                type: 'line',
                xKey: 'date',
                yKey: 'antiIsrael_extremist',
                title: 'Anti-Israel',
            },
            {
                type: 'line',
                xKey: 'date',
                yKey: 'misinformation',
                title: 'Fake-News / Conspiracy',
            },
            {
                type: 'line',
                xKey: 'date',
                yKey: 'endorsement_of_terrorism',
                title: 'Hamas and another terrorism',
            },
            {
                type: 'line',
                xKey: 'date',
                yKey: 'pro_palestine',
                title: 'Pro palestine',
            },
        ],
        axes: [
            {
                type: 'log',
                position: 'left',
                title: {
                    text: 'Posts',
                },
                label: {
                    format: ',.0f',
                    fontSize: 10,
                },
            },
            {
                type: 'time',
                position: 'bottom',
                title: {
                    text: 'Date',
                },
                label: {
                    format: '%b %Y',
                    fontSize: 10,
                },
            },
        ],
        legend: {
            enabled: true, // Enable legend to differentiate categories
        },
    };

    return <AgChartsReact options={options} />;
};

export default ScrapedGptClassifiedStatisticLog;