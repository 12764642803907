import { observer } from 'mobx-react-lite';
import { Button, Table, Popup } from 'semantic-ui-react'
import * as _ from 'lodash'
import { CyabraScanTask } from '../../swipe/components/CyabraScanTask';
import { platformTextValueBycyabraPlatformEnum } from '../../../app/common/options/platformOptions';
import { dateSearchValueByDateSearchEnum } from '../../../app/common/options/dateSearchTypeOptions';
import DeleteButton from '../../../app/common/confirm/ConfirmButton';
import { TaskStatus, taskStatusDictionary } from "../../../app/models/task";

interface Props {
    task: CyabraScanTask;
    fetchPosts: () => void;
    removeScanTaskPosts: () => void;
    downloadTaskPosts: () => any;
    deleteScanTask: () => void;
}
function CyabraTaskTableRow({ task, fetchPosts, removeScanTaskPosts, downloadTaskPosts, deleteScanTask }: Props) {

    return (

        <Table.Row key={task.id}>
            <Table.Cell >{task.phrase}</Table.Cell>
            <Table.Cell >{platformTextValueBycyabraPlatformEnum.get(task.platform)}</Table.Cell>
            <Table.Cell >{dateSearchValueByDateSearchEnum.get(task.date)}</Table.Cell>
            <Table.Cell >{taskStatusDictionary.get(task.status)}</Table.Cell>
            <Table.Cell >{task.isFetched ? "Yes" : (task.status == TaskStatus.Success ? <Button size='mini' color="green" onClick={() => fetchPosts()}>Fetch</Button> : "No")}</Table.Cell>
            <Table.Cell textAlign="right">{task.postCount ?? 0}</Table.Cell>
            <Table.Cell width="2" textAlign="right" >{task.status == TaskStatus.Success && task.isFetched ?
                <>
                    <Popup content='Exclude from vote page' trigger={<Button size='mini'  color="yellow" icon="remove bookmark" onClick={() => removeScanTaskPosts()}></Button>} />
                    <Popup content='Download original posts' trigger={<Button size='mini' icon="download" color="green" onClick={() => downloadTaskPosts()}></Button>} />
                    <Popup content='Delete scan task' trigger={<DeleteButton text="Sure you want to delete?" func={() => deleteScanTask()}></DeleteButton>} />
                    </>
                : <Popup content='Delete scan task' trigger={<DeleteButton text="Sure you want to delete?" func={() => deleteScanTask()}></DeleteButton>} />}</Table.Cell>
        </Table.Row>
    )
}

export default observer(CyabraTaskTableRow)
