const successStyle = {
    color: 'green'
} 

const failureStyle = {
    color: 'red'
}
export const cellLink = (params: { value: string }): JSX.Element => <a href={params.value} target="_blank"> Open Here </a>

export const successSpan = (value: string): JSX.Element => <b style={successStyle}>{value}</b>
export const failureSpan = (value: string): JSX.Element => <b style={failureStyle}>{value}</b>