import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import {
  GroupedByPlatform,
  MonthYearPosts,
  PlatformMonthPosts,
  Report,
} from "../models/report";
import * as _ from "lodash";
import Moment from "moment";
import {
  platformDictionary,
  platformEnum,
} from "../common/options/platformOptions";
import { Pagination, PagingParams } from "../models/pagination";
import { PlatformStatistics } from "../models/platformStatistics";
import { ToArray } from "../common/helpers/enumHelpers";
import { store } from "./store";
import { toast } from "react-toastify";
import { UserRole } from "../models/user";

export default class ReportStore {
  reportRegistry = new Map<string, Report>();
  selectedReport: Report | undefined = undefined;
  filteredReports: _.Dictionary<Report[]> | undefined = undefined;
  statsByPlatform = new Map<string, PlatformStatistics>();
  statsByPlatformAndDate: PlatformMonthPosts[] = [];
  statsByDate: MonthYearPosts[] = [];
  editMode = false;
  loading = false;
  loadingInitial = false;
  isFiltered = false;
  uploading = false;
  pagination: Pagination | null = null;
  pagingParams = new PagingParams();
  predicate = new Map().set("all", true);
  loggedIn = false;
  defaultDate: Date = new Date("2020-01-01");

  constructor() {
    makeAutoObservable(this);
  }

  get reportsList() {
    return Array.from(this.reportRegistry.values());
  }

  loadReports = async () => {
    if (store.userStore.user?.userRole !== UserRole.User) {
      this.loadingInitial = true;
      try {
        const results = await agent.Reports.list();
        this.removeReports();
        _.map(results, (report) => {
          this.setReport(report);
        });
        this.platformsStatistics();
        this.statsByPlatformAndDate = this.groupByMonthAndPlatform(results);
        this.statsByDate = this.countByYear(results);
      } catch (error) {
        console.log(error);
      } finally {
        runInAction(() => {
          this.loadingInitial = false;
        });
      }
    }
  };

  private removeReports = () => {
    this.reportRegistry.clear();
  };

  platformsStatistics() {
    const socialList = ToArray(platformEnum);
    let all: PlatformStatistics = {
      name: "All",
      total: 0,
      online: 0,
      removed: 0,
    };

    _.forEach(socialList, (social) => {
      let isActive = _.countBy(
        this.groupedReportsByPlatform[social],
        "isActive"
      );
      let total = _.size(this.groupedReportsByPlatform[social]);
      let online = _.has(isActive, "true") ? isActive["true"] : 0;
      let removed = _.has(isActive, "false") ? isActive["false"] : 0;

      let platStat: PlatformStatistics = {
        name: social,
        total,
        online,
        removed,
      };
      this.statsByPlatform.set(social, platStat);

      all.total += total;
      all.online += online;
      all.removed += removed;
    });
    this.statsByPlatform.set("All", all);
  }

  loadReport = async (id: string) => {
    this.loadingInitial = true;
    try {
      await agent.Reports.details(id)
        .then((report) => {
          if (report) {
            runInAction(() => {
              this.selectedReport = report;
            });
            return report;
          }
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });

      const report: Report = {
        id: "",
        title: "",
        createdOn: new Date(),
        createdBy: "",
        createdById: "",
        isArchived: false,
        url: "",
        content: "",
        platform: 1,
        description: "",
        image: "",
        postedBy: "",
        isActive: true,
        isApproved: false,
        categories: [],
      };
      return report;
    }
  };
  private getReport = (id: string) => {
    return this.reportRegistry.get(id);
  };

  uploadPhoto = async (file: Blob) => {
    this.uploading = true;
    try {
      const response = await agent.Reports.uploadPhoto(file);
      const photo = response;
      runInAction(() => {
        this.uploading = false;
      });
      if (photo.url.length > 0) toast.success("File uploaded successfully");
      else toast.error("Error while file uploading");
      return photo.url;
    } catch (ex) {
      console.log(ex);
      runInAction(() => {
        this.uploading = false;
        return "";
      });
    }
  };

  loadEmptyReport = () => {
    this.loadingInitial = true;
    const report = {
      id: "",
      title: "",
      createdOn: new Date(),
      createdBy: "",
      createdById: "",
      isArchived: false,
      url: "",
      content: "",
      platform: 0,
      description: "",
      image: "",
      postedBy: "",
      isActive: true,
      isApproved: false,
      categories: [],
    };
    runInAction(() => {
      this.selectedReport = report;
    });
    this.loadingInitial = false;
  };

  createReport = async (report: Report) => {
    this.loading = true;

    try {
      await agent.Reports.add(report);
      this.setReport(report);
      runInAction(() => {
        this.selectedReport = report;
        this.editMode = false;
      });
      toast.success(`Post reported successfully!`);
    } catch (error) {
      toast.error(`Report on post failed!`);
      throw error;
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  updateReport = async (report: Report) => {
    this.loading = true;
    try {
      await agent.Reports.edit(report.id, report);
      this.setReport(report);
      runInAction(() => {
        this.editMode = false;
        this.selectedReport = report;
      });
      toast.success(`Post updated successfully!`);
    } catch (error) {
      toast.error(`Update post failed!`);
      throw error;
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  deleteReport = async (id: string) => {
    this.loading = true;

    try {
      await agent.Reports.delete(id);

      runInAction(() => {
        this.reportRegistry.delete(id);
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  private setReport = (report: Report) => {
    this.reportRegistry.set(report.id, report);
  };

  setPredicate = (predicate: string, value: string) => {
    const resetPredicate = () => {
      this.predicate.forEach((value, key) => {
        this.predicate.delete(key);
      });
    };
    switch (predicate) {
      case "all":
        resetPredicate();
        this.predicate.set("all", true);
        this.predicate.set("includeArchived", false);
        this.predicate.set("includeNonActive", false);
        break;
      case "includeArchived":
        resetPredicate();
        this.predicate.set("includeArchived", true);
        break;
      case "includeNonActive":
        resetPredicate();
        this.predicate.set("includeNonActive", true);
        break;
    }
  };

  get reportsUnfiltered() {
    return Array.from(this.reportRegistry.values());
  }

  get reportsByDate() {
    return Array.from(this.reportRegistry.values()).sort(
      (a, b) =>
        Date.parse(b.createdOn.toString()) - Date.parse(a.createdOn.toString())
    );
  }

  get groupedReportsByDate() {
    return _.groupBy(Array.from(this.reportsByDate), function (report) {
      return Moment(report.createdOn).format("DD-MMM-YYYY");
    });
  }

  get groupedReportsByPlatform() {
    return _.groupBy(Array.from(this.reportsByDate), function (report) {
      return _.get(platformEnum, report.platform);
    });
  }

  get groupedReportsByPlatformAndDate() {
    return _.groupBy(Array.from(this.reportsByDate), function (report) {
      return _.get(platformEnum, report.platform);
    });
  }

  get groupedReportsByUser() {
    return _.groupBy(Array.from(this.reportsByDate), function (report) {
      return report.createdBy;
    });
  }

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      params.append(key, value);
    });
    return params;
  }

  groupByMonthAndPlatform = (reports: Report[]): PlatformMonthPosts[] => {
    const result: PlatformMonthPosts[] = [];

    const currentDate = new Date();
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(currentDate.getFullYear() - 1);

    const groupedByMonth = reports.reduce(
      (acc: Record<string, Report[]>, report) => {
        const date = new Date(report.createdOn);
        if (date >= oneYearAgo) {
          const month = date.toISOString().slice(0, 7);
          acc[month] = acc[month] || [];
          acc[month].push(report);
        }
        return acc;
      },
      {}
    );

    for (const [month, reports] of Object.entries(groupedByMonth)) {
      const platforms = reports.reduce(
        (acc: Record<number, Report[]>, report) => {
          acc[report.platform] = acc[report.platform] || [];
          acc[report.platform].push(report);
          return acc;
        },
        {}
      );

      const groupedByPlatform: GroupedByPlatform[] = Object.entries(
        platforms
      ).map(([platform, reports]) => ({
        platform: parseInt(platform, 10),
        reports,
      }));

      for (const { platform, reports } of groupedByPlatform) {
        const platformValue = platformDictionary.get(platform);
        result.push({
          platform: platformValue || "Other",
          month,
          posts: reports.length,
        });
      }
    }

    return result;
  };

  countByYear = (reports: Report[]): MonthYearPosts[] => {
    const result: MonthYearPosts[] = [];

    const groupedByYear = reports.reduce(
      (acc: Record<string, Report[]>, report) => {
        const date = new Date(report.createdOn);
        const year = date.getFullYear();
        if (!acc[year]) {
          acc[year] = [];
        }
        acc[year].push(report);
        return acc;
      },
      {}
    );

    for (const [year, reports] of Object.entries(groupedByYear)) {
      result.push({ year: parseInt(year), posts: reports.length });
    }

    return result;
  };
}
