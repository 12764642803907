import * as signalR from '@microsoft/signalr';
import { toast } from 'react-toastify';


const NotificationHubEndpoint = "/notifications";
const NotificationsMethodEndpoint = "RecieveNotification";

export const useSignalR = (isAllowed: boolean) => {

    if (isAllowed) {

        const hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_API_URL + NotificationHubEndpoint)
            .build();

        hubConnection
            .start()
            .catch((error: string) =>
                console.error(error.toString()));

        hubConnection.on(NotificationsMethodEndpoint,
            (notification: string, success: boolean) => {
                const toastFunction = success ? toast.success : toast.error;
                toastFunction(notification);
            })
    }

};