
export enum WebhookType {
    BrightDataPostCheck = 1,
    GetPosts = 2,
    GetBrightDataNewPosts = 3,
    GetDigitalDomePosts = 4
}

export const webhookTypeOptions = [
    { text: 'BrightData post check', value: 1 },
    { text: 'Get Posts', value: 2 },
    { text: 'Get BrightData New Posts', value: 3 },
    { text: 'Get DigitalDome New Posts', value: 4 }
]

export const webhookTypeMapper: { [key: number]: string } = {
    1: "BrightData",
    2: "GetPosts",
    3: "GetBrightDataNewPosts",
    4: "GetDigitalDomePosts"
}