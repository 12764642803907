import { useEffect } from 'react'
import * as _ from 'lodash';
import { Button, Divider, Header, Icon, Segment, Table } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import ImportKeywordsForm from './ImportKeywordsForm';
import NewKeywordForm from './NewKeywordForm';
import KeywordTableRow from './KeywordTableRow';
import HeaderSubHeader from 'semantic-ui-react/dist/commonjs/elements/Header/HeaderSubheader';
//import Papa from 'papaparse';

function KeywordsManagement() {
    const { keywordsStore: keywordsStore, modalStore } = useStore();
    const { openModal } = modalStore;
    const { getKeywords, keywords, refreshKeywordsCount, counting, counterStatus, keywordsLoaded, setCounting, getKeywordsCounterStatus } = keywordsStore;

    useEffect(() => {
        if (_.isNil(keywords))
            getKeywords()
    }, [keywords, getKeywords])

    /*useEffect(() => {
        getKeywordsCounterStatus();
    }, [counting, getKeywordsCounterStatus]);*/

    const onBtExport = () => {
        if (_.isNil(keywords)) return;
    
        const csvContent = 'data:text/csv;charset=utf-8,' +
            '\ufeffText,Exclude,Importance,Appears\n' +
            keywords.map((keyword, index) => (
            `${index + 1},${keyword.text},${keyword.exclude},${keyword.importance},${keyword.posts}`
            )).join('\n');
    
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', 'keywords.csv');
    
            document.body.appendChild(link);
    
            link.click();
    
            document.body.removeChild(link);
        } else {
            console.error('Your browser does not support the download attribute.');
        }
      };
    /*function onBtExport() {
        if (keywords?.length || 0 > 0) {
            const csvData = Papa.unparse((keywords?? []), {
                quotes: true,
                header: true,
            });

            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-16;' });
            const link = document.createElement('a');

            link.href = window.URL.createObjectURL(blob);
            link.setAttribute('download', 'keywords.csv');
            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);
        }
    }*/

    return (
        <>
            <Button
                color="green"
                disabled={keywords?.length === 0}
                size="mini"
                onClick={() => onBtExport()}><Icon name="download" />CSV</Button>
            <Divider />
            <Segment clearing style={{ margin: 0 }}>
                <Header as='h2' floated='left'>Keywords Management</Header>

                <Button color='green' floated='right' onClick={() => openModal(<NewKeywordForm />)}>New Keyword</Button>
                <Button color='green' floated='right' onClick={() => openModal(<ImportKeywordsForm />)}>Import Keywords CSV</Button>
            </Segment>

            <Segment clearing style={{ margin: 0 }}>
                <HeaderSubHeader as='h3' floated='left'>Total Keywords: {keywords?.length}</HeaderSubHeader>
                <Button disabled={counting} color='red' floated='right' onClick={() => {
                    setCounting(true);
                    return refreshKeywordsCount()
                    }}>{counting ?`Counting... ${counterStatus}`:`Refresh Posts Keywords Count`}</Button>
            </Segment>

            {keywordsLoaded ?
                <Table celled singleLine>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Num</Table.HeaderCell>
                            <Table.HeaderCell>Text</Table.HeaderCell>
                            <Table.HeaderCell>Exclude</Table.HeaderCell>
                            <Table.HeaderCell>Importance</Table.HeaderCell>
                            <Table.HeaderCell>Appears</Table.HeaderCell>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {_.map(keywords, (keyword, index) => {
                                return <KeywordTableRow
                                    index={index}
                                    keyword={keyword}
                                    key={keyword.id}
                                    //deleteToken={() => removeToken(token.id)}
                                
                                />;
                            })}
                    </Table.Body>
                </Table> :
                <LoadingComponent content="Loading keywords..." />
            }
        </>

    );
}

export default observer(KeywordsManagement)