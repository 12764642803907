import { ColDef } from "ag-grid-community";
import * as _ from "lodash";
import {
  platformDictionary,
  platformEnum,
} from "../../app/common/options/platformOptions";
import { cellLink, failureSpan, successSpan } from "./ScrapedCellRenderers";
import { antiIsraeliCategoriesMapper } from "../../app/common/options/categoryOptions";

const dateFilterParams = {
  comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
    var cellDate = new Date(cellValue);

    if (
      filterLocalDateAtMidnight.getDay() === cellDate.getDay() &&
      filterLocalDateAtMidnight.getMonth() === cellDate.getMonth() &&
      filterLocalDateAtMidnight.getFullYear() === cellDate.getFullYear()
    ) {
      return 0;
    }

    if (cellDate <= filterLocalDateAtMidnight) {
      return -1;
    }

    if (cellDate >= filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  },
};

/*const platformFilterParams = {
    filterOptions: ['equal', 'notEqual'],
    debounceMs: 200,
    maxNumConditions: 1,
    textMatcher: (value: any) => {

        const platform = platformDictionary.get(parseInt(value.value));
        return platform?.toLowerCase() === value.filterText ? 1 : 0;
    }

};*/

const booleanFilterParams = {
  filterOptions: ["equal", "notEqual"],
  debounceMs: 200,
  maxNumConditions: 1,
  textMatcher: (value: any) => {
    const bool = getBooleanValue(value.value === "true" ? true : false);
    return bool?.toLowerCase() === value.filterText ? 1 : 0;
  },
};

const platformFilterParams = {
  filterParams: {
    values: Object.values(platformEnum).map(String),
    debounceMs: 200,
  },
};

export const reportColumnDefs: ColDef[] = [
  {
    field: "postedOn",
    valueFormatter: (params: { value: string }) => {
      var date = new Date(params.value);
      return date.toDateString();
    },
    filter: "agDateColumnFilter",
    filterParams: dateFilterParams,
    sortable: true,
  },
  {
    headerName: "Platform",
    field: "platformName",
    /*valueFormatter: (params: { value: string; }) => {
            return params.value;
        },*/
    filter: "agSetColumnFilter",
    filterParams: platformFilterParams,
    sortable: true,
  },
  {
    field: "gptClassified",
    valueFormatter: (params: { value: string[] }) => {
      if (Array.isArray(params.value) && params.value.length > 0) {
        return params.value.join(", ");
      } else {
        return "";
      }
    },
    filter: "agTextColumnFilter",
    filterParams: booleanFilterParams,
    sortable: true,
  },
  {
    field: "gptScore",
    filter: "agNumberColumnFilter",
    filterParams: {
      inRangeInclusive: true,
      inRangeInclusiveValue: 1,
      greaterThan: true,
      lessThan: true,
      filterOptions: ["inRangeInclusive", "greaterThan", "lessThan"],
      debounceMs: 200,
    },
    sortable: true,
    cellStyle: {
      fontSize: "16px",
      fontWeight: "bold",
    },
  },
  {
    field: "keywords",
    valueFormatter: (params: { value: string[] }) => {
      if (Array.isArray(params.value) && params.value.length > 0) {
        return params.value.join(", ");
      } else {
        return "";
      }
    },
    filter: "agTextColumnFilter",
    filterParams: booleanFilterParams,
    sortable: true,
  },
  {
    headerName: "Post Link",
    field: "url",
    cellRenderer: (params: { value: string }) => {
      return cellLink(params);
    },
  },
  {
    headerName: "Is Online",
    field: "isActive",
    valueFormatter: (params: { value: boolean }) => {
      return getBooleanValue(params.value);
    },
    filter: "agTextColumnFilter",
    filterParams: booleanFilterParams,
    sortable: true,
    cellRenderer: (params: { value: boolean; valueFormatted: string }) => {
      if (params.value) return failureSpan(params.valueFormatted);
      else return successSpan(params.valueFormatted);
    },
  },

  // {
  //     field: 'category',
  //     valueFormatter: (params: { value: number }) => {
  //         return antiIsraeliCategoriesMapper.hasOwnProperty(params.value) ? antiIsraeliCategoriesMapper[params.value] : '';
  //     },
  //     filter: 'agTextColumnFilter',
  //     //filterParams: platformFilterParams,
  //     sortable: true
  // },

  {
    field: "categories",
    valueFormatter: (params: { value: number[] }) => {
      if (Array.isArray(params.value) && params.value.length > 0) {
        return params.value
          .filter((item) => antiIsraeliCategoriesMapper.hasOwnProperty(item))
          .map((item) => antiIsraeliCategoriesMapper[item])
          .join(", ");
      } else {
        return "";
      }
    },
    filter: "agTextColumnFilter",
    //filterParams: platformFilterParams,
    sortable: true,
  },

  {
    field: "modifiedOn",
    valueFormatter: (params: { value: string }) => {
      if (_.isNil(params.value)) {
        return "Not Categorized";
      } else {
        var date = new Date(params.value);
        return date.toDateString();
      }
    },
    sortable: true,
    cellRenderer: (params: { value: boolean; valueFormatted: string }) => {
      if (params.value) return successSpan(params.valueFormatted);
      else return failureSpan(params.valueFormatted);
    },
  },
  {
    field: "source",
    filter: "agTextColumnFilter",
    sortable: true,
  },
  {
    field: "createdOn",
    valueFormatter: (params: { value: string }) => {
      var date = new Date(params.value);
      return date.toDateString();
    },
    filter: "agDateColumnFilter",
    filterParams: dateFilterParams,
    sortable: true,
  },
  {
    field: "userName",
    valueFormatter: (params: { value: string }) => {
      return params.value;
    },
    filter: "agTextColumnFilter",
    //filterParams: booleanFilterParams,
    sortable: true,
  },
  {
    field: "isArchived",
    valueFormatter: (params: { value: boolean }) => {
      return getBooleanValue(params.value);
    },
    filter: "agTextColumnFilter",
    filterParams: booleanFilterParams,
    sortable: true,
  },
  { field: "id" },
];

const getBooleanValue = (bool: boolean) => (bool ? "Yes" : "No");

export {};
