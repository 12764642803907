import { makeAutoObservable } from "mobx";
import { PostToVote } from "../../features/swipe/components/PostToVote";
import agent from "../api/agent";
import * as _ from "lodash";
import { categoryEnum } from "../common/options/categoryOptions";
import {
  antiIsraeliCategoriesMapper,
  categoriesMapper,
} from "../../app/common/options/categoryOptions";
import { toast } from "react-toastify";

export default class VoteStore {
  loadedVotePost: PostToVote | null = null;
  loading: boolean = false;
  initialLodaing: boolean = true;

  constructor() {
    makeAutoObservable(this);
  }

  getPostToVote = async () => {
    try {
      if (_.isNil(this.loadedVotePost)) {
        this.loading = true;
        this.loadedVotePost = await agent.Cyabra.getPostToVote();
        this.loading = false;
        this.initialLodaing = false;
      }
    } catch (e) {
      console.error("An error occurred while trying to get post to vote.", e);
    }
  };

  getAntiIsraeliPostToVote = async () => {
    try {
      if (_.isNil(this.loadedVotePost)) {
        this.loading = true;
        this.loadedVotePost = await agent.Scrape.getPostToVote();
        /*const results = await agent.Scrape.scrapedPosts(10000, 0);
                console.log("results:");
                console.log(results);*/
        this.loading = false;
        this.initialLodaing = false;
      }
    } catch (e) {
      this.loading = false;
      this.initialLodaing = false;
      console.error("An error occurred while trying to get post to vote.", e);
    }
  };

  votePost = async (categories: categoryEnum) => {
    try {
      if (!_.isNil(this.loadedVotePost)) {
        let isSuccess = await agent.Cyabra.votePost(
          this.loadedVotePost.id,
          categories,
          categoriesMapper[categories]
        );
        if (isSuccess) this.loadedVotePost = null;
        return isSuccess;
      }
    } catch (e) {
      console.error("An error occurred while trying to vote a post.", e);
    }
  };

  voteAntiIsraeliPost = async (categories: categoryEnum[]) => {
    try {
      if (!_.isNil(this.loadedVotePost)) {
        let isSuccess = await agent.Scrape.votePost(
          this.loadedVotePost.id,
          categories,
          antiIsraeliCategoriesMapper[categories[0]]
        );
        console.log(categories);
        if (isSuccess) this.loadedVotePost = null;
        return isSuccess;
      }
    } catch (e) {
      console.error("An error occurred while trying to vote a post.", e);
    }
  };

  skipAntiIsraeliPost = async (reason: string) => {
    try {
      if (!_.isNil(this.loadedVotePost)) {
        console.log(reason);
        console.log(this.loadedVotePost.id);
        let isSuccess = await agent.Scrape.skipPost(
          this.loadedVotePost.id,
          reason
        );
        if (isSuccess) this.loadedVotePost = null;
        return isSuccess;
      }
    } catch (e) {
      console.error("An error occurred while trying to vote a post.", e);
    }
  };
}
