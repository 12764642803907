/* #region  Imports */
import React, {useEffect} from "react";
import { Grid } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import ScrapedStatisticItem from "./ScrapedStatisticItem";
import { platformEnum } from "../../../app/common/options/platformOptions";
import _ from "lodash";
import { ToArray } from "../../../app/common/helpers/enumHelpers";
import ScrapedStatisticHeader from "./ScrapedStatisticHeader";
import ScrapedStatisticPlaceholder from "./ScrapedStatisticPlaceholder";


/* #endregion */

function ScrapedStatisticInformation() {
    /* #region  State and store */
    const { scrapedStore } = useStore();
    const { loadReports, reportRegistry, loadingInitial } = scrapedStore;

  

    const socialList = ToArray(platformEnum);
    useEffect(() => {

        if (reportRegistry.size <= 1 || loadingInitial) loadReports();

    }, [loadReports, reportRegistry.size, loadingInitial]);
    /* #endregion */
  
    if (scrapedStore.loadingInitial && reportRegistry.size <= 1)
      return <ScrapedStatisticPlaceholder/>;

  return (
    <>
      <Grid>
        <ScrapedStatisticHeader/>
        {_.map(socialList, (platform, key)=>{
            return (
                <Grid.Column width="4" key={key}>
                    <ScrapedStatisticItem platform={platform} />
                </Grid.Column>
            )
        })}
      
      </Grid>
    </>
  );
}

export default observer(ScrapedStatisticInformation);
