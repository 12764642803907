//previous phoneRegExp was: /^((\\+[0-9]{9,15}[ \\-]*)|(\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
export const phoneRegExp = /^\+\d{9,15}[ \\-]*$/;

export const countryOptions = [
  { text: 'Afghanistan', value: 'Afghanistan' },
  { text: 'Albania', value: 'Albania' },
  { text: 'Algeria', value: 'Algeria' },
  { text: 'Andorra', value: 'Andorra' },
  { text: 'Angola', value: 'Angola' },
  { text: 'Antigua and Barbuda', value: 'Antigua and Barbuda' },
  { text: 'Argentina', value: 'Argentina' },
  { text: 'Armenia', value: 'Armenia' },
  { text: 'Australia', value: 'Australia' },
  { text: 'Austria', value: 'Austria' },
  { text: 'Azerbaijan', value: 'Azerbaijan' },
  { text: 'Bahamas', value: 'Bahamas' },
  { text: 'Bahrain', value: 'Bahrain' },
  { text: 'Bangladesh', value: 'Bangladesh' },
  { text: 'Barbados', value: 'Barbados' },
  { text: 'Belarus', value: 'Belarus' },
  { text: 'Belgium', value: 'Belgium' },
  { text: 'Belize', value: 'Belize' },
  { text: 'Benin', value: 'Benin' },
  { text: 'Bhutan', value: 'Bhutan' },
  { text: 'Bolivia', value: 'Bolivia' },
  { text: 'Bosnia and Herzegovina', value: 'Bosnia and Herzegovina' },
  { text: 'Botswana', value: 'Botswana' },
  { text: 'Brazil', value: 'Brazil' },
  { text: 'Brunei', value: 'Brunei' },
  { text: 'Bulgaria', value: 'Bulgaria' },
  { text: 'Burkina Faso', value: 'Burkina Faso' },
  { text: 'Burundi', value: 'Burundi' },
  { text: 'Cabo Verde', value: 'Cabo Verde' },
  { text: 'Cambodia', value: 'Cambodia' },
  { text: 'Cameroon', value: 'Cameroon' },
  { text: 'Canada', value: 'Canada' },
  { text: 'Central African Republic', value: 'Central African Republic' },
  { text: 'Chad', value: 'Chad' },
  { text: 'Chile', value: 'Chile' },
  { text: 'China', value: 'China' },
  { text: 'Colombia', value: 'Colombia' },
  { text: 'Comoros', value: 'Comoros' },
  { text: 'Congo', value: 'Congo' },
  { text: 'Costa Rica', value: 'Costa Rica' },
  { text: 'Croatia', value: 'Croatia' },
  { text: 'Cuba', value: 'Cuba' },
  { text: 'Cyprus', value: 'Cyprus' },
  { text: 'Czechia', value: 'Czechia' },
  { text: 'Denmark', value: 'Denmark' },
  { text: 'Djibouti', value: 'Djibouti' },
  { text: 'Dominica', value: 'Dominica' },
  { text: 'Dominican Republic', value: 'Dominican Republic' },
  { text: 'East Timor', value: 'East Timor' },
  { text: 'Ecuador', value: 'Ecuador' },
  { text: 'Egypt', value: 'Egypt' },
  { text: 'El Salvador', value: 'El Salvador' },
  { text: 'Equatorial Guinea', value: 'Equatorial Guinea' },
  { text: 'Eritrea', value: 'Eritrea' },
  { text: 'Estonia', value: 'Estonia' },
  { text: 'Eswatini', value: 'Eswatini' },
  { text: 'Ethiopia', value: 'Ethiopia' },
  { text: 'Fiji', value: 'Fiji' },
  { text: 'Finland', value: 'Finland' },
  { text: 'France', value: 'France' },
  { text: 'Gabon', value: 'Gabon' },
  { text: 'Gambia', value: 'Gambia' },
  { text: 'Georgia', value: 'Georgia' },
  { text: 'Germany', value: 'Germany' },
  { text: 'Ghana', value: 'Ghana' },
  { text: 'Greece', value: 'Greece' },
  { text: 'Grenada', value: 'Grenada' },
  { text: 'Guatemala', value: 'Guatemala' },
  { text: 'Guinea', value: 'Guinea' },
  { text: 'Guinea-Bissau', value: 'Guinea-Bissau' },
  { text: 'Guyana', value: 'Guyana' },
  { text: 'Haiti', value: 'Haiti' },
  { text: 'Honduras', value: 'Honduras' },
  { text: 'Hungary', value: 'Hungary' },
  { text: 'Iceland', value: 'Iceland' },
  { text: 'India', value: 'India' },
  { text: 'Indonesia', value: 'Indonesia' },
  { text: 'Iran', value: 'Iran' },
  { text: 'Iraq', value: 'Iraq' },
  { text: 'Ireland', value: 'Ireland' },
  { text: 'Israel', value: 'Israel' },
  { text: 'Italy', value: 'Italy' },
  { text: 'Ivory Coast', value: 'Ivory Coast' },
  { text: 'Jamaica', value: 'Jamaica' },
  { text: 'Japan', value: 'Japan' },
  { text: 'Jordan', value: 'Jordan' },
  { text: 'Kazakhstan', value: 'Kazakhstan' },
  { text: 'Kenya', value: 'Kenya' },
  { text: 'Kiribati', value: 'Kiribati' },
  { text: 'Korea, North', value: 'Korea, North' },
  { text: 'Korea, South', value: 'Korea, South' },
  { text: 'Kosovo', value: 'Kosovo' },
  { text: 'Kuwait', value: 'Kuwait' },
  { text: 'Kyrgyzstan', value: 'Kyrgyzstan' },
  { text: 'Laos', value: 'Laos' },
  { text: 'Latvia', value: 'Latvia' },
  { text: 'Lebanon', value: 'Lebanon' },
  { text: 'Lesotho', value: 'Lesotho' },
  { text: 'Liberia', value: 'Liberia' },
  { text: 'Libya', value: 'Libya' },
  { text: 'Liechtenstein', value: 'Liechtenstein' },
  { text: 'Lithuania', value: 'Lithuania' },
  { text: 'Luxembourg', value: 'Luxembourg' },
  { text: 'Madagascar', value: 'Madagascar' },
  { text: 'Malawi', value: 'Malawi' },
  { text: 'Malaysia', value: 'Malaysia' },
  { text: 'Maldives', value: 'Maldives' },
  { text: 'Mali', value: 'Mali' },
  { text: 'Malta', value: 'Malta' },
  { text: 'Marshall Islands', value: 'Marshall Islands' },
  { text: 'Mauritania', value: 'Mauritania' },
  { text: 'Mauritius', value: 'Mauritius' },
  { text: 'Mexico', value: 'Mexico' },
  { text: 'Micronesia', value: 'Micronesia' },
  { text: 'Moldova', value: 'Moldova' },
  { text: 'Monaco', value: 'Monaco' },
  { text: 'Mongolia', value: 'Mongolia' },
  { text: 'Montenegro', value: 'Montenegro' },
  { text: 'Morocco', value: 'Morocco' },
  { text: 'Mozambique', value: 'Mozambique' },
  { text: 'Myanmar', value: 'Myanmar' },
  { text: 'Namibia', value: 'Namibia' },
  { text: 'Nauru', value: 'Nauru' },
  { text: 'Nepal', value: 'Nepal' },
  { text: 'Netherlands', value: 'Netherlands' },
  { text: 'New Zealand', value: 'New Zealand' },
  { text: 'Nicaragua', value: 'Nicaragua' },
  { text: 'Niger', value: 'Niger' },
  { text: 'Nigeria', value: 'Nigeria' },
  { text: 'North Macedonia', value: 'North Macedonia' },
  { text: 'Norway', value: 'Norway' },
  { text: 'Oman', value: 'Oman' },
  { text: 'Pakistan', value: 'Pakistan' },
  { text: 'Palau', value: 'Palau' },
  { text: 'Panama', value: 'Panama' },
  { text: 'Papua New Guinea', value: 'Papua New Guinea' },
  { text: 'Paraguay', value: 'Paraguay' },
  { text: 'Peru', value: 'Peru' },
  { text: 'Philippines', value: 'Philippines' },
  { text: 'Poland', value: 'Poland' },
  { text: 'Portugal', value: 'Portugal' },
  { text: 'Qatar', value: 'Qatar' },
  { text: 'Romania', value: 'Romania' },
  { text: 'Russia', value: 'Russia' },
  { text: 'Rwanda', value: 'Rwanda' },
  { text: 'Saint Kitts and Nevis', value: 'Saint Kitts and Nevis' },
  { text: 'Saint Lucia', value: 'Saint Lucia' },
  { text: 'Saint Vincent and the Grenadines', value: 'Saint Vincent and the Grenadines' },
  { text: 'Samoa', value: 'Samoa' },
  { text: 'San Marino', value: 'San Marino' },
  { text: 'Sao Tome and Principe', value: 'Sao Tome and Principe' },
  { text: 'Saudi Arabia', value: 'Saudi Arabia' },
  { text: 'Senegal', value: 'Senegal' },
  { text: 'Serbia', value: 'Serbia' },
  { text: 'Seychelles', value: 'Seychelles' },
  { text: 'Sierra Leone', value: 'Sierra Leone' },
  { text: 'Singapore', value: 'Singapore' },
  { text: 'Slovakia', value: 'Slovakia' },
  { text: 'Slovenia', value: 'Slovenia' },
  { text: 'Solomon Islands', value: 'Solomon Islands' },
  { text: 'Somalia', value: 'Somalia' },
  { text: 'South Africa', value: 'South Africa' },
  { text: 'South Sudan', value: 'South Sudan' },
  { text: 'Spain', value: 'Spain' },
  { text: 'Sri Lanka', value: 'Sri Lanka' },
  { text: 'Sudan', value: 'Sudan' },
  { text: 'Suriname', value: 'Suriname' },
  { text: 'Sweden', value: 'Sweden' },
  { text: 'Switzerland', value: 'Switzerland' },
  { text: 'Syria', value: 'Syria' },
  { text: 'Taiwan', value: 'Taiwan' },
  { text: 'Tajikistan', value: 'Tajikistan' },
  { text: 'Tanzania', value: 'Tanzania' },
  { text: 'Thailand', value: 'Thailand' },
  { text: 'Togo', value: 'Togo' },
  { text: 'Tonga', value: 'Tonga' },
  { text: 'Trinidad and Tobago', value: 'Trinidad and Tobago' },
  { text: 'Tunisia', value: 'Tunisia' },
  { text: 'Turkey', value: 'Turkey' },
  { text: 'Turkmenistan', value: 'Turkmenistan' },
  { text: 'Tuvalu', value: 'Tuvalu' },
  { text: 'Uganda', value: 'Uganda' },
  { text: 'Ukraine', value: 'Ukraine' },
  { text: 'United Arab Emirates', value: 'United Arab Emirates' },
  { text: 'United Kingdom', value: 'United Kingdom' },
  { text: 'United States', value: 'United States' },
  { text: 'Uruguay', value: 'Uruguay' },
  { text: 'Uzbekistan', value: 'Uzbekistan' },
  { text: 'Vanuatu', value: 'Vanuatu' },
  { text: 'Vatican City', value: 'Vatican City' },
  { text: 'Venezuela', value: 'Venezuela' },
  { text: 'Vietnam', value: 'Vietnam' },
  { text: 'Yemen', value: 'Yemen' },
  { text: 'Zambia', value: 'Zambia' },
  { text: 'Zimbabwe', value: 'Zimbabwe' },
];

export const languageOptions = [
  { text: 'English', value: 'English' },
  { text: 'Spanish', value: 'Spanish' },
  { text: 'French', value: 'French' },
  { text: 'German', value: 'German' },
  { text: 'Chinese', value: 'Chinese' },
  { text: 'Japanese', value: 'Japanese' },
  { text: 'Russian', value: 'Russian' },
  { text: 'Arabic', value: 'Arabic' },
  { text: 'Portuguese', value: 'Portuguese' },
  { text: 'Italian', value: 'Italian' },
  { text: 'Hindi', value: 'Hindi' },
  { text: 'Dutch', value: 'Dutch' },
  { text: 'Swedish', value: 'Swedish' },
  { text: 'Korean', value: 'Korean' },
  { text: 'Turkish', value: 'Turkish' },
  { text: 'Indonesian', value: 'Indonesian' },
  { text: 'Malay', value: 'Malay' },
  { text: 'Vietnamese', value: 'Vietnamese' },
  { text: 'Thai', value: 'Thai' },
  { text: 'Greek', value: 'Greek' },
  { text: 'Polish', value: 'Polish' },
  { text: 'Czech', value: 'Czech' },
  { text: 'Hungarian', value: 'Hungarian' },
  { text: 'Finnish', value: 'Finnish' },
  { text: 'Danish', value: 'Danish' },
  { text: 'Norwegian', value: 'Norwegian' },
  { text: 'Romanian', value: 'Romanian' },
  { text: 'Bulgarian', value: 'Bulgarian' },
  { text: 'Croatian', value: 'Croatian' },
  { text: 'Serbian', value: 'Serbian' },
  { text: 'Slovak', value: 'Slovak' },
  { text: 'Slovenian', value: 'Slovenian' },
  { text: 'Hebrew', value: 'Hebrew' },
  { text: 'Armenian', value: 'Armenian' },
  { text: 'Persian', value: 'Persian' },
  { text: 'Urdu', value: 'Urdu' },
  { text: 'Bengali', value: 'Bengali' },
  { text: 'Punjabi', value: 'Punjabi' },
  { text: 'Tamil', value: 'Tamil' },
  { text: 'Telugu', value: 'Telugu' },
  { text: 'Marathi', value: 'Marathi' },
  { text: 'Gujarati', value: 'Gujarati' },
  { text: 'Kannada', value: 'Kannada' },
  { text: 'Odia', value: 'Odia' },
  { text: 'Malayalam', value: 'Malayalam' },
  { text: 'Sinhala', value: 'Sinhala' },
  { text: 'Nepali', value: 'Nepali' },
  { text: 'Assamese', value: 'Assamese' },
  { text: 'Burmese', value: 'Burmese' },
  { text: 'Khmer', value: 'Khmer' },
  { text: 'Lao', value: 'Lao' },
  { text: 'Mongolian', value: 'Mongolian' },
  { text: 'Tibetan', value: 'Tibetan' },
  { text: 'Uighur', value: 'Uighur' },
  { text: 'Kazakh', value: 'Kazakh' },
  { text: 'Turkmen', value: 'Turkmen' },
  { text: 'Kyrgyz', value: 'Kyrgyz' },
  { text: 'Tajik', value: 'Tajik' },
  { text: 'Georgian', value: 'Georgian' },
  { text: 'Azerbaijani', value: 'Azerbaijani' },
  { text: 'Moldovan', value: 'Moldovan' },
  { text: 'Belarusian', value: 'Belarusian' },
  { text: 'Ukrainian', value: 'Ukrainian' },
  { text: 'Latvian', value: 'Latvian' },
  { text: 'Lithuanian', value: 'Lithuanian' },
  { text: 'Estonian', value: 'Estonian' },
  { text: 'Albanian', value: 'Albanian' },
  { text: 'Macedonian', value: 'Macedonian' },
  { text: 'Montenegrin', value: 'Montenegrin' },
  { text: 'Bosnian', value: 'Bosnian' },
  { text: 'Kosovar', value: 'Kosovar' },
  { text: 'Serbo-Croatian', value: 'Serbo-Croatian' },
  { text: 'Slovene', value: 'Slovene' },
  { text: 'Moldovan', value: 'Moldovan' },
  { text: 'Catalan', value: 'Catalan' },
  { text: 'Galician', value: 'Galician' },
  { text: 'Basque', value: 'Basque' },
  { text: 'Aragonese', value: 'Aragonese' },
  { text: 'Asturian', value: 'Asturian' },
  { text: 'Aromanian', value: 'Aromanian' },
  { text: 'Sardinian', value: 'Sardinian' },
  { text: 'Corsican', value: 'Corsican' },
  { text: 'Occitan', value: 'Occitan' },
  { text: 'Franco-Provençal', value: 'Franco-Provençal' },
  { text: 'Ladin', value: 'Ladin' },
  { text: 'Friulian', value: 'Friulian' },
  { text: 'Romansh', value: 'Romansh' },
  { text: 'Luxembourgish', value: 'Luxembourgish' },
  { text: 'Letzeburgesch', value: 'Letzeburgesch' },
  { text: 'Walloon', value: 'Walloon' },
  { text: 'Limburgish', value: 'Limburgish' },
  { text: 'Frisian', value: 'Frisian' },
  { text: 'Sorbian', value: 'Sorbian' },
  { text: 'Kashubian', value: 'Kashubian' },
  { text: 'Silesian', value: 'Silesian' },
  { text: 'Upper Sorbian', value: 'Upper Sorbian' },
  { text: 'Lower Sorbian', value: 'Lower Sorbian' },
  { text: 'Maltese', value: 'Maltese' },
  { text: 'Icelandic', value: 'Icelandic' },
  { text: 'Faroese', value: 'Faroese' },
  { text: 'Greenlandic', value: 'Greenlandic' },
  { text: 'Saami', value: 'Saami' },
  { text: 'Finnish', value: 'Finnish' },
  { text: 'Estonian', value: 'Estonian' },
  { text: 'Karelian', value: 'Karelian' },
  { text: 'Veps', value: 'Veps' },
  { text: 'Finnish Sign Language', value: 'Finnish Sign Language' },
  { text: 'Swedish', value: 'Swedish' },
  { text: 'Danish', value: 'Danish' },
  { text: 'Norwegian', value: 'Norwegian' },
  { text: 'Icelandic', value: 'Icelandic' },
  { text: 'Faroese', value: 'Faroese' },
  { text: 'Greenlandic', value: 'Greenlandic' },
  { text: 'Saami', value: 'Saami' },
  { text: 'Kven', value: 'Kven' },
  { text: 'Scots', value: 'Scots' },
  { text: 'Scottish Gaelic', value: 'Scottish Gaelic' },
  { text: 'Irish', value: 'Irish' },
  { text: 'Manx', value: 'Manx' },
  { text: 'Welsh', value: 'Welsh' },
  { text: 'Cornish', value: 'Cornish' },
  { text: 'Breton', value: 'Breton' },
  { text: 'Alemannic German', value: 'Alemannic German' },
  { text: 'Bavarian', value: 'Bavarian' },
  { text: 'Swabian', value: 'Swabian' },
  { text: 'Low German', value: 'Low German' },
  { text: 'Plautdietsch', value: 'Plautdietsch' },
  { text: 'Dutch', value: 'Dutch' },
  { text: 'Flemish', value: 'Flemish' },
  { text: 'Afrikaans', value: 'Afrikaans' },
  { text: 'Limburgish', value: 'Limburgish' },
  { text: 'West Frisian', value: 'West Frisian' },
  { text: 'Sranan Tongo', value: 'Sranan Tongo' },
  { text: 'Ndyuka', value: 'Ndyuka' },
  { text: 'Saramaccan', value: 'Saramaccan' },
  { text: 'Kwinti', value: 'Kwinti' },
  { text: 'Aluku', value: 'Aluku' },
  { text: 'Paramakan', value: 'Paramakan' },
  { text: 'Lidzekodzi', value: 'Lidzekodzi' },
  { text: 'Skepi', value: 'Skepi' },
  { text: 'Djuka', value: 'Djuka' },
  { text: 'Ndyuka', value: 'Ndyuka' },
  { text: 'Belizean Creole', value: 'Belizean Creole' },
  { text: 'Jamaican Patois', value: 'Jamaican Patois' },
  { text: 'Trinidadian Creole', value: 'Trinidadian Creole' },
  { text: 'Guyanese Creole', value: 'Guyanese Creole' },
  { text: 'Papiamento', value: 'Papiamento' },
  { text: 'Saramaccan', value: 'Saramaccan' },
  { text: 'Surinamese Javanese', value: 'Surinamese Javanese' },
  { text: 'Haitian Creole', value: 'Haitian Creole' },
  { text: 'Louisiana Creole', value: 'Louisiana Creole' },
  { text: 'Gullah', value: 'Gullah' },
  { text: 'Afro-Seminole Creole', value: 'Afro-Seminole Creole' },
  { text: 'Bahamian Creole', value: 'Bahamian Creole' },
  { text: 'Krio', value: 'Krio' },
  { text: 'Sierra Leonean Krio', value: 'Sierra Leonean Krio' },
  { text: 'Aku', value: 'Aku' },
  { text: 'Pichi', value: 'Pichi' },
  { text: 'Nubi', value: 'Nubi' },
  { text: 'Swahili', value: 'Swahili' },
  { text: 'Zulu', value: 'Zulu' },
  { text: 'Xhosa', value: 'Xhosa' },
  { text: 'Ndebele', value: 'Ndebele' },
  { text: 'Sotho', value: 'Sotho' },
  { text: 'Tswana', value: 'Tswana' },
  { text: 'Venda', value: 'Venda' },
  { text: 'Tsonga', value: 'Tsonga' },
  { text: 'Tshivenda', value: 'Tshivenda' },
  { text: 'Swazi', value: 'Swazi' },
  { text: 'Kongo', value: 'Kongo' },
  { text: 'Lingala', value: 'Lingala' },
  { text: 'Tshiluba', value: 'Tshiluba' },
  { text: 'Kikongo', value: 'Kikongo' },
  { text: 'Kituba', value: 'Kituba' },
  { text: 'Luba-Kasai', value: 'Luba-Kasai' },
  { text: 'Luba-Katanga', value: 'Luba-Katanga' },
  { text: 'Songe', value: 'Songe' },
  { text: 'Bembe', value: 'Bembe' },
  { text: 'Mongo', value: 'Mongo' },
  { text: 'Nande', value: 'Nande' },
  { text: 'Lendu', value: 'Lendu' },
  { text: 'Hema', value: 'Hema' },
  { text: 'Mangbetu', value: 'Mangbetu' },
  { text: 'Lugbara', value: 'Lugbara' },
  { text: 'Madi', value: 'Madi' },
  { text: 'Kakwa', value: 'Kakwa' },
  { text: 'Bari', value: 'Bari' },
  { text: 'Lotuko', value: 'Lotuko' },
  { text: 'Otuho', value: 'Otuho' },
  { text: 'Dinka', value: 'Dinka' },
  { text: 'Nuer', value: 'Nuer' },
  { text: 'Shilluk', value: 'Shilluk' },
  { text: 'Anuak', value: 'Anuak' },
  { text: 'Berta', value: 'Berta' },
  { text: 'Gumuz', value: 'Gumuz' },
  { text: 'Mao', value: 'Mao' },
  { text: 'Daju', value: 'Daju' },
  { text: 'Nubian', value: 'Nubian' },
  { text: 'Fur', value: 'Fur' },
  { text: 'Zaghawa', value: 'Zaghawa' },
  { text: 'Masalit', value: 'Masalit' },
  { text: 'Nubian', value: 'Nubian' },
  { text: 'Tama', value: 'Tama' },
  { text: 'Fongoro', value: 'Fongoro' },
  { text: 'Kunama', value: 'Kunama' },
  { text: 'Nara', value: 'Nara' },
  { text: 'Bishnupriya Manipuri', value: 'Bishnupriya Manipuri' },
  { text: 'Khasi', value: 'Khasi' },
  { text: 'Garo', value: 'Garo' },
  { text: 'Jaintia', value: 'Jaintia' },
  { text: 'Angami', value: 'Angami' },
  { text: 'Ao Naga', value: 'Ao Naga' },
  { text: 'Lotha Naga', value: 'Lotha Naga' },
  { text: 'Rengma Naga', value: 'Rengma Naga' },
  { text: 'Sangtam Naga', value: 'Sangtam Naga' },
  { text: 'Sumi Naga', value: 'Sumi Naga' },
  { text: 'Chakhesang Naga', value: 'Chakhesang Naga' },
  { text: 'Zeme Naga', value: 'Zeme Naga' },
  { text: 'Poumai Naga', value: 'Poumai Naga' },
  { text: 'Mao Naga', value: 'Mao Naga' },
  { text: 'Maram Naga', value: 'Maram Naga' },
  { text: 'Rongmei Naga', value: 'Rongmei Naga' },
  { text: 'Thangal Naga', value: 'Thangal Naga' },
  { text: 'Tangkhul Naga', value: 'Tangkhul Naga' },
  { text: 'Maring Naga', value: 'Maring Naga' },
  { text: 'Tarao Naga', value: 'Tarao Naga' },
  { text: 'Moghrey Naga', value: 'Moghrey Naga' },
  { text: 'Vaiphei Naga', value: 'Vaiphei Naga' },
  { text: 'Zeliang Naga', value: 'Zeliang Naga' },
  { text: 'Pangal Naga', value: 'Pangal Naga' },
  { text: 'Kabui Naga', value: 'Kabui Naga' },
  { text: 'Thadou Naga', value: 'Thadou Naga' },
  { text: 'Hmar Naga', value: 'Hmar Naga' },
  { text: 'Mizo', value: 'Mizo' },
  { text: 'Lai', value: 'Lai' },
  { text: 'Paite', value: 'Paite' },
  { text: 'Simte', value: 'Simte' },
  { text: 'Zou', value: 'Zou' },
  { text: 'Tedim Chin', value: 'Tedim Chin' },
  { text: 'Falam Chin', value: 'Falam Chin' },
  { text: 'Hakha Chin', value: 'Hakha Chin' },
  { text: 'Mindat Chin', value: 'Mindat Chin' },
  { text: 'Matu Chin', value: 'Matu Chin' },
  { text: 'Rakhine', value: 'Rakhine' },
  { text: 'Chin', value: 'Chin' },
  { text: 'Burmese', value: 'Burmese' },
  { text: 'Mon', value: 'Mon' },
  { text: 'Karen', value: 'Karen' },
  { text: 'Shan', value: 'Shan' },
  { text: 'Akha', value: 'Akha' },
  { text: 'Lahu', value: 'Lahu' },
  { text: 'Hani', value: 'Hani' },
  { text: 'Yi', value: 'Yi' },
  { text: 'Miao', value: 'Miao' },
  { text: 'Hmong', value: 'Hmong' },
  { text: 'Zhuang', value: 'Zhuang' },
  { text: 'Dong', value: 'Dong' },
  { text: 'Yao', value: 'Yao' },
  { text: 'Bai', value: 'Bai' },
  { text: 'Naxi', value: 'Naxi' },
  { text: 'Tujia', value: 'Tujia' },
  { text: 'Qiang', value: 'Qiang' },
  { text: 'Tu', value: 'Tu' },
  { text: 'Ningxia Hui', value: 'Ningxia Hui' },
  { text: 'Xinjiang Uyghur', value: 'Xinjiang Uyghur' },
  { text: 'Inner Mongolian', value: 'Inner Mongolian' },
  { text: 'Daur', value: 'Daur' },
  { text: 'Evenki', value: 'Evenki' },
  { text: 'Oroqen', value: 'Oroqen' },
  { text: 'Hezhen', value: 'Hezhen' },
  { text: 'Manchu', value: 'Manchu' },
  { text: 'Mongolian', value: 'Mongolian' },
  { text: 'Daai Chuxiong', value: 'Daai Chuxiong' },
  { text: 'Nuosu Yi', value: 'Nuosu Yi' },
  { text: 'Qiangic languages', value: 'Qiangic languages' },
  { text: 'Lolo-Burmese languages', value: 'Lolo-Burmese languages' },
  { text: 'Kadai languages', value: 'Kadai languages' },
  { text: 'Hmong-Mien languages', value: 'Hmong-Mien languages' },
  { text: 'Kra-Dai languages', value: 'Kra-Dai languages' },
  { text: 'Austroasiatic languages', value: 'Austroasiatic languages' },
  { text: 'Sino-Tibetan languages', value: 'Sino-Tibetan languages' },
  { text: 'Turkic languages', value: 'Turkic languages' },
  { text: 'Mongolic languages', value: 'Mongolic languages' },
  { text: 'Tungusic languages', value: 'Tungusic languages' },
  { text: 'Chukotko-Kamchatkan languages', value: 'Chukotko-Kamchatkan languages' },
  { text: 'Yukaghir languages', value: 'Yukaghir languages' },
  { text: 'Eskimo-Aleut languages', value: 'Eskimo-Aleut languages' },
  { text: 'Nivkh', value: 'Nivkh' },
  { text: 'Gilyak', value: 'Gilyak' },
  { text: 'Ainu', value: 'Ainu' },
  { text: 'Japanese', value: 'Japanese' },
  { text: 'Korean', value: 'Korean' },
  { text: 'Mongolic languages', value: 'Mongolic languages' },
  { text: 'Tungusic languages', value: 'Tungusic languages' },
  { text: 'Austronesian languages', value: 'Austronesian languages' },
  { text: 'Koreanic languages', value: 'Koreanic languages' },
  { text: 'Japonic languages', value: 'Japonic languages' },
  { text: 'Japanese Sign Language', value: 'Japanese Sign Language' },
  { text: 'Okinawan', value: 'Okinawan' },
  { text: 'Yoron', value: 'Yoron' },
  { text: 'Miyako', value: 'Miyako' },
  { text: 'Amami', value: 'Amami' },
  { text: 'Kunigami', value: 'Kunigami' },
  { text: 'Yaeyama', value: 'Yaeyama' },
  { text: 'Miyako Sign Language', value: 'Miyako Sign Language' },
  { text: 'Amami Oshima Sign Language', value: 'Amami Oshima Sign Language' },
  { text: 'Toku-No-Shima Sign Language', value: 'Toku-No-Shima Sign Language' },
  { text: 'Yoronjima Sign Language', value: 'Yoronjima Sign Language' },
  { text: 'Bonin English', value: 'Bonin English' },
  { text: 'Hachijō', value: 'Hachijō' },
  { text: 'Ogasawara', value: 'Ogasawara' },
  { text: 'Kikai', value: 'Kikai' },
  { text: 'Kumejima', value: 'Kumejima' },
  { text: 'Yaeyama', value: 'Yaeyama' },
  { text: 'Yonaguni', value: 'Yonaguni' },
];

export const languageCodesOptions = [
  { text: 'English', value: 'en' },
  { text: 'Spanish', value: 'es' },
  { text: 'French', value: 'fr' },
  { text: 'German', value: 'de' },
  { text: 'Chinese', value: 'zh' },
  { text: 'Japanese', value: 'ja' },
  { text: 'Russian', value: 'ru' },
  { text: 'Arabic', value: 'ar' },
  { text: 'Portuguese', value: 'pt' },
  { text: 'Italian', value: 'it' },
  { text: 'Hindi', value: 'hi' },
  { text: 'Dutch', value: 'nl' },
  { text: 'Swedish', value: 'sv' },
  { text: 'Korean', value: 'ko' },
  { text: 'Turkish', value: 'tr' },
  { text: 'Indonesian', value: 'id' },
  { text: 'Malay', value: 'ms' },
  { text: 'Vietnamese', value: 'vi' },
  { text: 'Thai', value: 'th' },
  { text: 'Greek', value: 'el' },
  { text: 'Polish', value: 'pl' },
  { text: 'Czech', value: 'cs' },
  { text: 'Hungarian', value: 'hu' },
  { text: 'Finnish', value: 'fi' },
  { text: 'Danish', value: 'da' },
  { text: 'Norwegian', value: 'no' },
  { text: 'Romanian', value: 'ro' },
  { text: 'Hebrew', value: 'he' },
  { text: 'Persian', value: 'fa' },
  { text: 'Bengali', value: 'bn' },
  { text: 'Tamil', value: 'ta' },
  { text: 'Telugu', value: 'te' },
  { text: 'Marathi', value: 'mr' },
  { text: 'Gujarati', value: 'gu' },
  { text: 'Kannada', value: 'kn' },
  { text: 'Malayalam', value: 'ml' },
  { text: 'Nepali', value: 'ne' },
  { text: 'Afrikaans', value: 'af' },
  { text: 'Icelandic', value: 'is' },
  { text: 'Swahili', value: 'sw' },
  { text: 'Zulu', value: 'zu' },
  { text: 'Xhosa', value: 'xh' },
  { text: 'Sesotho', value: 'st' },
  { text: 'Somali', value: 'so' },
  { text: 'Filipino', value: 'fil' },
  { text: 'Khmer', value: 'km' },
  { text: 'Lao', value: 'lo' },
  { text: 'Maori', value: 'mi' },
  { text: 'Samoan', value: 'sm' },
  { text: 'Tongan', value: 'to' },
  { text: 'Fijian', value: 'fj' },
  { text: 'Hawaiian', value: 'haw' },
  { text: 'Chamorro', value: 'ch' },
  { text: 'Tahitian', value: 'ty' },
  { text: 'Guarani', value: 'gn' },
  { text: 'Quechua', value: 'qu' },
  { text: 'Aymara', value: 'ay' },
  { text: 'Mapudungun', value: 'arn' },
  { text: 'Haitian Creole', value: 'ht' },
  { text: 'Nahuatl', value: 'nah' },
];