/* #region  Imports */
import React, {useEffect} from "react";
import { Grid } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import ReportStatisticItem from "./ReportStatisticItem";
import { platformEnum } from "../../../app/common/options/platformOptions";
import _ from "lodash";
import { ToArray } from "../../../app/common/helpers/enumHelpers";
import ReportStatisticHeader from "./ReportStatisticHeader";
import ReportStatisticPlaceholder from "./ReportStatisticPlaceholder";


/* #endregion */

function ReportStatisticInformation() {
    /* #region  State and store */
    const { reportStore } = useStore();
    const { loadReports, reportRegistry, loadingInitial } = reportStore;

  

    const socialList = ToArray(platformEnum);
    useEffect(() => {

        if (reportRegistry.size <= 1 || loadingInitial) loadReports();

    }, [loadReports, reportRegistry.size, loadingInitial]);
    /* #endregion */
  
    if (reportStore.loadingInitial && reportRegistry.size <= 1)
      return <ReportStatisticPlaceholder/>;

  return (
    <>
      <Grid>
        <ReportStatisticHeader/>
        {_.map(socialList, (platform, key)=>{
            return (
                <Grid.Column width="4" key={key}>
                    <ReportStatisticItem platform={platform} />
                </Grid.Column>
            )
        })}
      
      </Grid>
    </>
  );
}

export default observer(ReportStatisticInformation);
