import { useEffect } from 'react'
import * as _ from 'lodash';
import { Button, Header, Segment, Table } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import NewApiTokenForm from './NewApiTokenForm';
import ApiTokenTableRow from './ApiTokenTableRow';
import ShowTokenDialog from './ShowTokenDialog';

function ApiTokenManagement() {
    const { apiTokenStore, modalStore } = useStore();
    const { getTokens, removeToken, tokens, tokensLoaded, newAddedToken, removeNewAddedToken } = apiTokenStore;
    const { openModal } = modalStore;

    useEffect(() => {
        if (_.isNil(tokens))
            getTokens()
    }, [tokens, getTokens])

    useEffect(() => {
        if (!_.isNil(newAddedToken))
            openModal(<ShowTokenDialog token={newAddedToken} removeToken={removeNewAddedToken} />);
        
        return () => { removeNewAddedToken() };
    }, [newAddedToken])

    return (
        <>
            <Segment clearing>
                <Header as='h2' floated='left'>Api Token Management</Header>
                <Button color='green' floated='right' onClick={() => openModal(<NewApiTokenForm />)}>New Token</Button>
            </Segment>

            {tokensLoaded ?
                <Table celled singleLine>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Type</Table.HeaderCell>
                            <Table.HeaderCell>Created By</Table.HeaderCell>
                            <Table.HeaderCell>Created On</Table.HeaderCell>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {_.map(tokens, (token) => {
                            return <ApiTokenTableRow
                                token={token}
                                key={token.id}
                                deleteToken={() => removeToken(token.id)}
                            />;
                        })}
                    </Table.Body>
                </Table> :
                <LoadingComponent content="Loading tokens..." />
            }
        </>

    );
}

export default observer(ApiTokenManagement)