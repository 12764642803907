import { useState } from 'react'
import * as _ from 'lodash';
import { Button, Grid, Header, Segment, Table, TextArea, Form } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import { AiRecord } from './AiPlaygroundRecord';
import AiPlaygroundTableRow from './AiPlaygroundTableRow';
import { v4 as uuidv4 } from 'uuid';


function AiPlayground() {
    const { cyabraStore } = useStore();
    const { checkPhrase,scorePosts } = cyabraStore;

    const [input, setInput] = useState<string | undefined>("");
    const [phrases, setPhrases] = useState<AiRecord[]>([]);

    const check = async (phrase: string | undefined) => {
        if (!_.isNil(phrase) && _.isNil(_.find(phrases, { phrase: phrase }))) {
            let score = await checkPhrase(phrase);
            if (!_.isNil(score)) {

                let aiRecord: AiRecord = {
                    id: uuidv4(),
                    phrase: phrase,
                    score: score
                }
                setPhrases((state) => [...state, aiRecord])
            }
        }
    }

    const deleteRecord = (phrase: string) => {
        if (!_.isNil(phrase)) {
            setPhrases((prevArray) => prevArray.filter((item) => item.phrase !== phrase));
        }
    }


    return (
        <>
            <Segment clearing>
                <Header as='h2' floated='left'>AI Playground</Header>
                <Button color="blue" floated="right" onClick={() => scorePosts()}>Score Posts</Button>
            </Segment>
            <Form>
                <Grid>
                    <Grid.Row>
                        <Grid.Column stretched>
                            <Form.Field>
                                <TextArea value={input} onChange={(event, data) => setInput(data?.value?.toString())} placeholder='Enter a phrase to check if it is anti-semitic' rows={5} />
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Button color="grey" floated="right" onClick={() => setInput("")} disabled={input?.length === 0}>Clear</Button>
                            <Button color="blue" floated="right" onClick={() => check(input)} disabled={input?.length === 0}>Check</Button>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column stretched>
                            <Table celled fixed singleLine>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Keyword/#</Table.HeaderCell>
                                        <Table.HeaderCell width='2'>AI Score</Table.HeaderCell>
                                        {/*<Table.HeaderCell width='3'>Vote</Table.HeaderCell>*/}
                                        <Table.HeaderCell width='2'>Remove</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                {_.map(phrases, (phrase) => {
                                    if (!_.isNil(phrase)) {
                                        return <AiPlaygroundTableRow phrase={phrase} key={phrase.id} voteYes={function (): void {
                                            throw new Error('Function not implemented.');
                                        }} voteNo={function (): void {
                                            throw new Error('Function not implemented.');
                                        }} remove={deleteRecord} />;
                                    }
                                })}
                                <Table.Body>
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>

        </>

    );
}

export default observer(AiPlayground)