import * as _ from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Icon, Menu, Table } from "semantic-ui-react";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { ProfileParams } from "../../app/models/pagination";
import { useStore } from "../../app/stores/store";
import ProfileReportsTableRow from "./ProfileReportsTableRow";

function ProfileReportsTable() {
  const { profileStore } = useStore();
  const {
    profile,
    setPagingParams,
    loadReports,
    reportsList,
    pagination,
    loadingProfileReports,
  } = profileStore;
  const { username } = useParams<{ username: string }>();
  const [loadingNext, setLoadingNext] = useState(false);

  useEffect(() => {
    loadReports(new ProfileParams(username));
  }, [loadReports, username]);

  /* #region  Handle get next page */

  function handleGetNext() {
    if (profile) {
      setLoadingNext(true);
      setPagingParams(new ProfileParams(username, pagination!.currentPage + 1));
      loadReports(
        new ProfileParams(username, pagination!.currentPage + 1)
      ).then(() => setLoadingNext(false));
    }
  }
  /* #endregion */

  /* #region  Handle get previous page */
  function handleGetPrevious() {
    if (profile) {
      setLoadingNext(true);
      setPagingParams(new ProfileParams(username, pagination!.currentPage - 1));
      loadReports(
        new ProfileParams(username, pagination!.currentPage - 1)
      ).then(() => setLoadingNext(false));
    }
  }
  /* #endregion */

  if (loadingNext || loadingProfileReports)
    return <LoadingComponent content="Loading user reports" />;
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Platform</Table.HeaderCell>
          <Table.HeaderCell>Link</Table.HeaderCell>
          <Table.HeaderCell>Submitted on</Table.HeaderCell>
          <Table.HeaderCell>Archived</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {_.map(reportsList, (report) => {
          return <ProfileReportsTableRow report={report} key={report.id} />;
        })}
      </Table.Body>

      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="5">
            <Menu floated="right" pagination>
              {pagination && (
                <Menu.Item
                  as="a"
                  icon
                  onClick={handleGetPrevious}
                  disabled={pagination.currentPage === 1}
                >
                  <Icon name="chevron left" />
                </Menu.Item>
              )}
              {pagination && (
                <Menu.Item
                  as="a"
                  icon
                  onClick={handleGetNext}
                  disabled={pagination.currentPage >= pagination.totalPages}
                >
                  <Icon name="chevron right" />
                </Menu.Item>
              )}
            </Menu>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
}

export default observer(ProfileReportsTable);
