import { Formik, Form } from 'formik'
import { observer } from 'mobx-react-lite'
import { Button, Header } from 'semantic-ui-react'
import * as Yup from 'yup'
import TextInput from '../../../app/common/form/TextInput'
import { useStore } from '../../../app/stores/store'

function NewScrapeForm() {
    const { cyabraStore } = useStore();



    return (
        <Formik
            initialValues={{ phrase: '', error: null }}
            onSubmit={(values, { setErrors }) => cyabraStore.startNewScrape().catch(() => setErrors({ error: 'Error occured starting scrape.' }))}>
            {({ handleSubmit, isSubmitting, isValid, dirty }) => (
                <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                    <Header as='h2' content='Start new scan task' color='teal' textAlign='center' />
                    <Button loading={isSubmitting} positive content="Start" type='submit' fluid />
                </Form>
            )}

        </Formik>
    )
}

export default observer(NewScrapeForm)
