import { toast } from 'react-toastify';
import { makeAutoObservable, reaction, runInAction } from "mobx";
import { history } from "../..";
import * as _ from 'lodash';
import agent from "../api/agent";
import { DefaultRoute, WelcomeRoute } from "../common/defaults/routeDefault";
import { User, UserFormValues, RegisterFormValues, UserRole, UpdateUserDto, SendMultiFactorAuthValues, VerifyMultiFactorAuthValues, MultiFactorAuthenticatorForm, VerifyCaptcha, ChangeUserNumberValues, VolunteersAction, SetPostLanguageRequest } from "../models/user";
import { store } from "./store";
import Cookies from 'js-cookie'
import { AuthorizedRequest } from '../common/helpers/authorizedRequest';


export default class UserStore {
    user: User | null = null;
    volunteersActivity: VolunteersAction[] | null = null;
    usersCount: number = 0;
    users: UpdateUserDto[] | null = null;
    refreshTokenTimeout: any;
    initialLoading = false;
    activityLoading = false;
    afterMultiFactorAuth: (() => Promise<void>) = async () => {};
    retryMultiFactorAuth: () => Promise<SendMultiFactorAuthValues> = async () => {
        return {
            mfaToken: '',
            authenticator: ''
        };
    };
    
    verifyMfa: VerifyMultiFactorAuthValues = { mfaToken: "", oobCode: "", typedCode: "" };
    authenticatorForm: MultiFactorAuthenticatorForm = { mfaToken: "", authenticatorId: "", authenticatorName: "", defaultAuthenticator: "" };

    constructor() {
        makeAutoObservable(this);
    }

    changePhoneNumber = async (creds: ChangeUserNumberValues) => {
        try {
            await agent.Account.changePhoneNumber(creds); 
            const mfa_token = await this.retryMultiFactorAuth();
            mfa_token.authenticator = creds.newPhoneNumber;
            await this.sendMultiFactorAuth(mfa_token, this.afterMultiFactorAuth);
        
        } catch (ex) {
            throw ex;

        }
    }

    loginMultiFactorAuth = async (creds: UserFormValues) => {
        try {
            const mfa_token = await agent.Account.loginMultiFactorAuth(creds);
            if(mfa_token.mfaToken === "success"){
                this.login(creds);
            }
            else{
                this.sendMultiFactorAuth(mfa_token, () => this.login(creds));
                runInAction(() => {
                    this.retryMultiFactorAuth = (() => agent.Account.loginMultiFactorAuth(creds));
                })
            }
            
        }
        catch (ex) {
            throw ex
        }
    }

    sendMultiFactorAuth = async (mfaRequest: SendMultiFactorAuthValues, func: () => Promise<void>) => {

        try {
            const authenticatorResponse = await agent.Account.getMultiFactorAuth(mfaRequest);
            const oobCode = await agent.Account.sendMultiFactorAuth(authenticatorResponse);
            runInAction(() => {
                this.verifyMfa.oobCode = oobCode;
                this.verifyMfa.mfaToken = mfaRequest.mfaToken;
                this.afterMultiFactorAuth = func;
                this.authenticatorForm = authenticatorResponse;
            })

        } catch (ex) {
            toast.error("Error while send sms")
        }

    }

    verifyMultiFactorAuth = async (code: string) => {
        try {
            runInAction(() => {
                this.verifyMfa.typedCode = code;
            })
            await agent.Account.verifyMultiFactorAuth(this.verifyMfa);
            await this.afterMultiFactorAuth();
            store.modalStore.closeModal();
            runInAction(() => {
                this.authenticatorForm = {mfaToken: "", authenticatorId: "", authenticatorName: "", defaultAuthenticator: ""}
            })
        } catch (ex) {
            toast.error("Invalid code")
        }
    }

    forgotPassword = async (email: string) => {
        try {
            await agent.Account.forgotPassword(email);
            toast.success("Email sent successfully");
            store.modalStore.closeModal();
        } catch (ex) {
            toast.error("Error while send email");
            throw ex;
        }
    }

    setPostLanguages = async (languages: string[]) => {
        try {
            const request: SetPostLanguageRequest = {
                userId: this.user?.id || "NONE",
                languages
            };
            await agent.Account.setPostLanguages(request);
            toast.success("Languages updated successfully");
        } catch (ex) {
            toast.error("Error while update languages");
            throw ex;
        }
    }

    verifyCaptcha = async (code: string) => {
        try {
            const captcha: VerifyCaptcha = { response: code };
            var isSuccess = await agent.Account.verifyCaptcha(captcha);
            return isSuccess;
        } catch (ex) {
            toast.error("Unable to complete captcha challange")
        }
    }

    setDefaultPassword = async (id: string) => {
        try {
            AuthorizedRequest(
                this.user,
                UserRole.PowerUser,
                true,
                "User's password set to default successfully.",
                async (id: string) => await agent.Account.setDefaultPassword(id),
                id);
        } catch (ex) {
            toast.error("Error while set user's password to default.")
        }
    }

    updateUser = async (user: UpdateUserDto) => {
        try {
            if (this.user?.id)
                AuthorizedRequest(
                    this.user,
                    UserRole.PowerUser,
                    true,
                    "User updated successfully.",
                    async (id: string, user: UpdateUserDto) => await agent.Account.updateUser(id, user),
                    this.user.id,
                    user);
        } catch (ex) {
            toast.error("Error while updating user.")
        }
    }

    loadUsers = async () => {
        try {
            AuthorizedRequest(
                this.user,
                UserRole.PowerUser,
                false,
                "",
                async () => {
                    let users: UpdateUserDto[] = await agent.Account.list();
                    users = _.filter(users, (usr: UpdateUserDto) => usr.id !== this.user?.id)
                    this.users = users;
                    runInAction(() => {
                        this.initialLoading = true;
                    })
                });
        } catch (ex) {
            throw ex;
        }
    }

    loadVolunteersActivity = async () => {
        try {
            AuthorizedRequest(
                this.user,
                UserRole.TeamLeader,
                false,
                "",
                async () => {
                    let activity: VolunteersAction[] = await agent.Account.volunteersActivity();
                    runInAction(() => {
                        this.volunteersActivity = activity;
                        this.activityLoading = true;
                    })
                });
        } catch (ex) {
            throw ex;
        }
    }


    get usersList() {
        return this.users;
    }

    get volunteersActivityList() {
        return this.volunteersActivity;
    }

    login = async (creds: UserFormValues) => {
        try {

            const user = await agent.Account.login(creds);
            store.commonStore.setToken(user.token)
            runInAction(() => { this.user = user; })
            this.startRefreshTokenTimer(user);
            if (!user.isActivated) {
                this.logout()
                toast.error("Please verify your email!");
                //agent.Account.sendActivationMail();
            }
            else
                history.push(DefaultRoute)

            store.modalStore.closeModal();

        }
        catch (ex) {
            throw ex
        }
    }

    logout = () => {
        store.commonStore.setToken(undefined);
        Cookies.remove('jwt');
        this.user = null;
        history.push(WelcomeRoute)
    }

    getUser = async () => {
        try {
            const user = await agent.Account.current();
            runInAction(() => this.user = user)
            //this.startRefreshTokenTimer(user);

            if (!user.isActivated) {
                this.logout()
                toast.error("User is not activated.")
            }
        }
        catch (ex) { console.log(ex) }
    }

    isUserManaged = (id: string) => {
        return this.user?.id === id
    }

    register = async (creds: RegisterFormValues) => {
        try {

            const user = await agent.Account.register(creds);
            store.commonStore.setToken(user.token)
            this.startRefreshTokenTimer(user);
            runInAction(() => { this.user = user; })
            history.push(DefaultRoute)
            store.modalStore.closeModal();

        }
        catch (ex) {
            throw ex
        }
    }

    registerMultiFactorAuth = async (creds: RegisterFormValues) => {
        try {
            creds.createUser = true;
            await agent.Account.register(creds);
            const userFormValues: UserFormValues = {
                email: creds.email,
                password: creds.password
            };
            this.loginMultiFactorAuth(userFormValues);
        }
        catch (ex) {
            throw ex
        }
    }

    getUsersCount = async () => {
        try {
            const usersCount = await agent.Account.count();
            this.usersCount = usersCount;
        }
        catch (ex) {
            throw ex
        }
    }

    refreshToken = async () => {
        this.stopRefreshTokenTimer();
        try {
            const user = await agent.Account.refreshToken();
            runInAction(() => {
                this.user = user
            })
            store.commonStore.setToken(user.token)
            this.startRefreshTokenTimer(user);
        } catch (ex) {
            console.log(ex)
        }
    }

    isRoleOrAbove = (role: UserRole): boolean => {
        try {
            return (this.user?.userRole || UserRole.User) >= role;
        } catch (ex) {
            console.error(ex);
            return false;
        }
    };

    private startRefreshTokenTimer(user: User) {
        const jwtToken = JSON.parse(atob(user.token.split('.')[1]))
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = /*60*1000*/expires.getTime() - Date.now() - (60 * 1000);
        this.refreshTokenTimeout = setTimeout(this.refreshToken, timeout);
        console.log(`jwtToken: ${jwtToken} ||expires: ${expires} ||timeout: ${timeout}`);
        console.log(jwtToken);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }

    get isPowerUser() {
        return this.user?.userRole === UserRole.PowerUser;
    }

    get userRole() {
        return this.user?.userRole;
    }
    get isLoggedIn() {
        return !!this.user;
    }

    get isActivated() {
        return this.user?.isActivated;
    }

}
