import { useEffect } from 'react'
import * as _ from 'lodash';
import { Button, Header, Segment, Table } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import CollectorTableRow from './CollectorTableRow';
import CollectorsForm from './CollectorsForm';

function CollectorsManagement() {
    const { collectorsStore, modalStore } = useStore();
    const { getCollectors, collectors, collectorsLoaded, deleteCollector } = collectorsStore;
    const { openModal } = modalStore;

    useEffect(() => {
        if (_.isNil(collectors))
            getCollectors()
    }, [collectors, getCollectors])

    return (
        <>
            <Segment clearing>
                <Header as='h2' floated='left'>Collectors Management</Header>
                <Button color='green' floated='right' onClick={() => openModal(<CollectorsForm />)}>New Collector</Button>
            </Segment>

            {collectorsLoaded ?
                <Table celled singleLine>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Collector Id</Table.HeaderCell>
                            <Table.HeaderCell>Platform</Table.HeaderCell>
                            <Table.HeaderCell>Created By</Table.HeaderCell>
                            <Table.HeaderCell>Created On</Table.HeaderCell>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {_.map(collectors, (collector) => {
                            return <CollectorTableRow
                                collector={collector}
                                key={collector.id}
                                deleteCollector={() => deleteCollector(collector.id)}
                                updateCollector={() => openModal(<CollectorsForm collector={collector} />)}
                            />;
                        })}
                    </Table.Body>
                </Table> :
                <LoadingComponent content="Loading collectors..." />
            }
        </>

    );
}

export default observer(CollectorsManagement)