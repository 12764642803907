export enum TaskStatus {
    Started = 1,
    InProgress = 2,
    Success = 3,
    Failed = 4
}

export const taskStatusDictionary: Map<number, string> = new Map<number, string>([
    [1, 'Started'],
    [2, 'In Progress'],
    [3, 'Success'],
    [4, 'Failed']
]);