import { AgChartsReact } from 'ag-charts-react';
import { AgChartOptions } from 'ag-charts-community';
import { useStore } from '../../../app/stores/store';


const BubbleChart = () => {
    const { reportStore } = useStore();
    const { statsByPlatformAndDate } = reportStore;
    const options: AgChartOptions = {
        autoSize: true,
        data: statsByPlatformAndDate,
        title: {
            text: 'Punch Card of Anti-Semitic posts',
            fontSize: 18,
        },
        subtitle: {
            text: 'time distribution of posts',
        },
        series: [
            {
                type: 'scatter',
                yKey: 'platform',
                yName: 'Platform',
                xKey: 'month',
                xName: 'Month',
                sizeKey: 'posts',
                sizeName: 'Posts',
                title: 'Summary Card',
                marker: {
                    size: 0,
                    maxSize: 30,
                    fill: '#cc5b58',
                    fillOpacity: 0.85,
                    strokeOpacity: 0.85,
                },
            },
        ],
        axes: [
            {
                position: 'bottom',
                type: 'category',
                gridStyle: [
                    {
                        stroke: 'rgba(0,0,0,0.2)',
                        lineDash: [0, 5, 0],
                    },
                ],
                paddingInner: 0.2,
                paddingOuter: 0.3,
                tick: {
                    color: 'black',
                },
                line: {
                    color: undefined,
                },
            },
            {
                position: 'left',
                type: 'category',
                gridStyle: [],
                paddingInner: 0.2,
                paddingOuter: 0.3,
                tick: {
                    color: 'black',
                },
                line: {
                    color: undefined,
                },
            },
        ],
        legend: {
            enabled: false,
        },
    };

    return <AgChartsReact options={options} />;
};

export default BubbleChart;