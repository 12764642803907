/* #region  Imports */
import React from "react";
import { Button, Container, Dropdown, Image, Menu } from "semantic-ui-react";
import { Link, NavLink } from 'react-router-dom'
import { useStore } from "../stores/store";
import { observer } from "mobx-react-lite";
import { UserRole } from "../models/user";
import { RoleBasedMenuItem } from "./RoleBasedMenuItem";
/* #endregion */

function NavBar() {
    const { userStore: { user, logout, isRoleOrAbove: isRoleOrAbove } } = useStore();
    return (
        <Menu inverted fixed="top">
            <Container>
                <Menu.Item  >
                    <img
                        src="/assets/FOA wordmark_white.png"
                        alt="logo"
                        style={{ marginRight: "10px" }}
                    />
                </Menu.Item>
                <Menu.Item name="Vote" as={NavLink} to='/vote' />
                <RoleBasedMenuItem name="Reports" link='/statistics' allowedRoles={[UserRole.PowerUser, UserRole.Manager]} />
                <RoleBasedMenuItem name="Scraped" link='/scraped' allowedRoles={[UserRole.PowerUser, UserRole.Manager]} />
                {/*<RoleBasedMenuItem name="Stats" link='/statistics' allowedRoles={[UserRole.PowerUser, UserRole.Manager]} />*/}
                {/*user?.userRole === UserRole.PowerUser*/true &&
                    <Menu.Item>
                        <Button
                            as={NavLink} to='/createReport'
                            positive
                            content="Create Report"
                            fluid
                        />
                    </Menu.Item>
                }
                <Menu.Item position="right">
                    <Image src={user?.image || '/assets/user.png'} avatar spaced='right' />
                    <Dropdown pointing='top left' text={user?.displayName}>
                        <Dropdown.Menu>
                            {user?.userRole === UserRole.PowerUser && <Dropdown.Item as={Link} to={`/admin`} text='Admin Settings' icon='settings' />}
                            {isRoleOrAbove(UserRole.TeamLeader) && <Dropdown.Item as={Link} to={`/volunteers`} text='Volunteers' icon='clock' />}
                            {user?.userRole === UserRole.PowerUser && <Dropdown.Item as={Link} to={`/profile/${user?.username}`} text='My Profile' icon='user' />}
                            <Dropdown.Item as={Link} to={`/settings`} text='Settings' icon='cog' />
                            <Dropdown.Item onClick={logout} text='Logout' icon='power' />
                        </Dropdown.Menu>
                    </Dropdown>
                </Menu.Item>
            </Container>
        </Menu>
    );
}

export default observer(NavBar);
