import { observer } from 'mobx-react-lite'
import { Tab } from 'semantic-ui-react'
import { useStore } from '../../app/stores/store'
import { history } from '../..';
import NothingHere from '../errors/NothingHere'
import SettingsLanguage from './SettingsLanguage';

function SettingsContent() {
  const {profileStore, userStore} = useStore();
  const {user} = userStore;

    const panes =[
        {menuItem: 'Languages', render:()=> <Tab.Pane><SettingsLanguage/></Tab.Pane>},
    ]
  return (
    <Tab
    menu={{fluid: true, vertical: true}}
    menuPosition='right'
    panes={panes}
    >

    </Tab>
  )
}

export default observer(SettingsContent)
