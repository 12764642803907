import { Formik, Form, ErrorMessage } from 'formik'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Button, Header, Label } from 'semantic-ui-react'
import TextInput from '../../app/common/form/TextInput'
import { useStore } from '../../app/stores/store'
import * as Yup from 'yup'
import HeaderSubHeader from 'semantic-ui-react/dist/commonjs/elements/Header/HeaderSubheader'
import ChangePhoneForm from './ChangePhoneForm'

function MultiFactorAuthForm() {
    const { modalStore, userStore } = useStore();

    const { authenticatorForm } = userStore;

    const validationSchema = Yup.object({
        code: Yup.string().required('Code is required'),
    })

    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={{ code: '', error: null }}
            onSubmit={(values, { setErrors }) => userStore.verifyMultiFactorAuth(values.code).catch(() => setErrors({ error: 'Invalid code' }))}>
            {({ handleSubmit, isSubmitting, errors, isValid, dirty }) => (
                <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                    <Header as='h2' content='Email Verification' color='teal' textAlign='center' />
                    <Header as='h3' content={authenticatorForm.defaultAuthenticator.length > 0 ? 
                        (`Verification code sent to ${authenticatorForm.authenticatorName.length > 0 ? authenticatorForm.authenticatorName: authenticatorForm.defaultAuthenticator}`) 
                        : "Sending Code..."} 
                        color='black' textAlign='center' />
                    <TextInput name='code' placeholder='Code' />
                    <ErrorMessage name='error'
                        render={() => <Label style={{ marginBotton: 10 }} basic color='red' content={errors.error} />} />
                    <Button disabled={isSubmitting || !dirty || !isValid} loading={isSubmitting} positive content="Verify" type='submit' fluid style={{ marginBottom: '5px' }}/>
                    {/*<HeaderSubHeader style={{ marginTop: 5 , cursor: 'pointer'}} onClick={() => modalStore.openModal(<ChangePhoneForm />)}>Change Phone Number</HeaderSubHeader>*/}
                </Form>
            )}

        </Formik>
    )
}

export default observer(MultiFactorAuthForm)
