import { toast } from "react-toastify";
import { User, UserRole } from "../../models/user";


export function AuthorizedRequest<T extends any[]>(user: User | null, role: UserRole, showMessage: boolean, successMessage: string, func: (...args: T) => void, ...inputs: T) {
    if ((user?.userRole || UserRole.User) >= role) {
        func(...inputs);

        if (showMessage)
            toast.success(successMessage)
    }
    else
        toast.error("User is not authorized.")
}