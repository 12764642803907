export enum DateSearchType {
    Day = 1,
    Week = 2,
    Month = 3
}

export const dateSearchValueByDateSearchEnum: Map<DateSearchType, string> = new Map<DateSearchType, string>([
    [DateSearchType.Day, 'Day'],
    [DateSearchType.Week, 'Week'],
    [DateSearchType.Month, 'Month']
]);

export const cyabraDateSearchOptions = [
    { text: 'Day ago', value: 1 },
    { text: 'Week ago', value: 2 },
    { text: 'Month ago', value: 3 }
]