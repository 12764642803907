import { useField } from 'formik';
import React from 'react'
import { Checkbox, Form} from 'semantic-ui-react';

interface Props{
    name: string;
    label?: string;
}
function TextInput(props: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field,meta, helpers] = useField(props.name)
  return (
    <Form.Field>
        <Checkbox 
        checked={meta.value}
        onChange={(e, d) => helpers.setValue(!meta.value)}
         type='checkbox'
         label={props.label}
         />
    </Form.Field>
  )
}

export default TextInput
