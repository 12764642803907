import { Formik, Form } from 'formik'
import { observer } from 'mobx-react-lite'
import { Button, Header } from 'semantic-ui-react'
import * as Yup from 'yup'
import CheckBoxInput from '../../../app/common/form/CheckBoxInput'
import TextInput from '../../../app/common/form/TextInput'
import { useStore } from '../../../app/stores/store'

interface Props {
    initialValues?: {
      text: string;
      exclude: boolean;
      importance: number;
      error: null | string;
    };
}


function NewKeywordForm({ initialValues = { text: '', exclude: false, importance: 1, error: null } }: Props) {
    const { keywordsStore } = useStore();
    const { addNewKeyword, keywords } = keywordsStore;


    const validationSchema = Yup.object({
        text: Yup.string().required('Keyword text is required'),
        importance: Yup.number().required('Keyword importance is required')

    })


    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={(values, { setErrors }) => addNewKeyword(values).catch(() => setErrors({ error: 'Error occured adding keyword.' }))}>
            {({ handleSubmit, isSubmitting, isValid, dirty }) => (
                <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                    <Header as='h2' content='Add / Edit Keyword' color='teal' textAlign='center' />
                    <TextInput name='text' placeholder='Name' />
                    <TextInput name='importance' placeholder='Importance' type="number"/>
                    <CheckBoxInput name='exclude' label="Exclude"/>
                    <Button disabled={isSubmitting || !dirty || !isValid} loading={isSubmitting} positive content="Add / Edit" type='submit' fluid />
                </Form>
            )}

        </Formik>
    )
}

export default observer(NewKeywordForm)
