import { Redirect, Route, RouteComponentProps, RouteProps } from "react-router-dom";
import { WelcomeRoute } from "../common/defaults/routeDefault";
import { UserRole } from "../models/user";
import { useStore } from "../stores/store";


interface Props extends RouteProps {
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    allowedRoles: UserRole[];

}

export function RoleBasedRoute({ component: Component, allowedRoles, ...rest }: Props) {
    const { userStore: { isLoggedIn, userRole } } = useStore();

    return (
        <Route
            {...rest}
            render={(props) => isLoggedIn && (userRole && allowedRoles.includes(userRole)) ? <Component {...props} /> : <Redirect to={WelcomeRoute} />}
        />
    )

}
