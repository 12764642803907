import { observer } from 'mobx-react-lite'
import { useEffect } from 'react';
import { Grid, Tab } from 'semantic-ui-react'
import { useStore } from '../../app/stores/store'
import VolunteersActivity from './volunteersActivity/VolunteersActivity';



function AdminPage() {
    const { userStore } = useStore();

    const { loadVolunteersActivity, volunteersActivityList, activityLoading } = userStore;

    useEffect(() => {
        if (!volunteersActivityList || !activityLoading) {
            loadVolunteersActivity();
        }
    }, [loadVolunteersActivity, volunteersActivityList])

    const panes = [
        { menuItem: 'Volunteers Activity', render: () => <Tab.Pane><VolunteersActivity /></Tab.Pane> },

    ]
    return (
        <Grid>
            <Grid.Column width={16}>
                <Tab
                    menu={{ fluid: true, vertical: true }}
                    menuPosition='right'
                    panes={panes}>

                </Tab>
            </Grid.Column>
        </Grid>

    )
}

export default observer(AdminPage)
