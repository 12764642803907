/* #region  Imports */
import React from "react";
import { Dimmer, Loader } from "semantic-ui-react";
/* #endregion */

/* #region  Interfaces */
interface Props {
  inverted?: boolean;
  content: string;
}
/* #endregion */

function LoadingComponent({ inverted = true, content = "Loading..." }: Props) {
  return (
    <Dimmer active={true} inverted={inverted}>
      <Loader content={content}></Loader>
    </Dimmer>
  );
}

export default LoadingComponent;
