import { AgChartsReact } from 'ag-charts-react';
import { AgChartOptions } from 'ag-charts-community';
import { useStore } from '../../../app/stores/store';



const ScrapedLogChart = () => {
    const { scrapedStore } = useStore();
    const { statsByDate, convertToDate } = scrapedStore;
    const options: AgChartOptions = {
        data: statsByDate.filter(item => item.date >= convertToDate("2019-01")).sort((a: { date: Date }, b: { date: Date }) => a.date.getTime() - b.date.getTime()),
        title: {
            text: 'Activity Over Time',
        },
        subtitle: {
            text: 'log scale',
        },
        series: [
            {
                type: 'line',
                xKey: 'date',
                yKey: 'posts',
            },
        ],
        axes: [
            {
                type: 'log',
                position: 'left',
                title: {
                    text: 'Posts',
                },
                label: {
                    format: ',.0f',
                    fontSize: 10,
                },
            },
            {
                type: 'time',
                position: 'bottom',
                title: {
                    text: 'Date',
                },
                label: {
                    format: '%b %Y',
                    fontSize: 10,
                },
            },
        ],
        legend: {
            enabled: false,
        },
    };

    return <AgChartsReact options={options} />;
};

export default ScrapedLogChart;