import { observer } from 'mobx-react-lite';
import { Table, Popup, Button } from 'semantic-ui-react'
import DeleteButton from '../../../app/common/confirm/ConfirmButton';
import { GetCollectorDto } from '../../../app/models/collectors';
import { platformNameByType } from '../../../app/models/platformEnum';

interface Props {
    collector: GetCollectorDto;
    updateCollector: () => void;
    deleteCollector: () => void;
}
function CollectorTableRow({ collector, deleteCollector, updateCollector }: Props) {

    return (

        <Table.Row key={collector.id}>
            <Table.Cell >{collector.collectorId}</Table.Cell>
            <Table.Cell >{platformNameByType.get(collector.platform)}</Table.Cell>
            <Table.Cell >{collector.createdBy}</Table.Cell>
            <Table.Cell >{collector.createdOn.toString().split('T')[0]}</Table.Cell>
            <Table.Cell width="2" textAlign="right" >
                <Button size='mini' icon="edit" color="green" onClick={() => updateCollector()}></Button>
                <Popup content='Delete collector' trigger={<DeleteButton text="Sure you want to delete?" func={() => deleteCollector()}></DeleteButton>} />
            </Table.Cell>
        </Table.Row>
    )
}

export default observer(CollectorTableRow)