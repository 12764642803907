import { observer } from "mobx-react-lite";
import { Button, Header, Input } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";

interface Props {
    token: string;
    removeToken: () => void;
}

function ShowTokenDialog({ token, removeToken }: Props) {
    const { modalStore } = useStore();

    return (
        <>
            <Header as='h2' content='New Token' color='teal' textAlign='center' />
            <Input name='token' defaultValue={token} placeholder='token' fluid />
            <Button positive onClick={() => {
                modalStore.closeModal();
                removeToken();
            }} content="Close" fluid />
        </>
    )
}

export default observer(ShowTokenDialog)
