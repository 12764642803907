import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { store } from "./store";
import * as _ from 'lodash';
import { toast } from "react-toastify";
import { AddCollectorDto, GetCollectorDto, UpdateCollectorDto } from "../models/collectors";

export default class CollectorsStore {
    collectors: GetCollectorDto[] | null = null;
    loading: boolean = false;
    collectorsLoaded: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    getCollectors = async () => {
        try {
            if (_.isNil(this.collectors)) {
                this.collectorsLoaded = false;
                this.loading = true;
                const results = await agent.Collectors.getCollectors();
                this.collectors = results;
                this.loading = false;
                this.collectorsLoaded = true;
            }
        }
        catch (e) {
            toast.error("An error occurred while loading collectors.");
        }
    }

    addNewCollector = async (addCollectorDto: AddCollectorDto) => {
        try {
            var result = await agent.Collectors.addCollector(addCollectorDto);
            store.modalStore.closeModal();
            if (!_.isNil(result)) {
                toast.success("New collector added.");
                this.collectors = null;
                this.getCollectors();
            }
            else {
                toast.error("New collector was not added.");
            }
        }
        catch (e) {
            toast.error("An error occurred while adding new collector.");
        }
    }

    updateCollector = async (updateCollectorDto: UpdateCollectorDto) => {
        try {
            var result = await agent.Collectors.updateCollector(updateCollectorDto);
            store.modalStore.closeModal();
            if (!_.isNil(result)) {
                toast.success("Collector updated.");
                this.collectors = null;
                this.getCollectors();
            }
            else {
                toast.error("Collector was no updated.");
            }
        }
        catch (e) {
            toast.error("An error occurred while updating collector.");
        }
    }


    deleteCollector = async (id: string) => {
        try {
            var result = await agent.Collectors.deleteCollector(id);
            if (!_.isNil(result)) {
                toast.success("Collector deleted.");
                this.collectors = null;
                this.getCollectors();
            }
            else {
                toast.error("Collector was not deleted.");
            }
            store.modalStore.closeModal();
            return result;
        }
        catch (e) {
            toast.error("An error occurred while removing collector.");
        }
    }

}
