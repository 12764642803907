import { SemanticCOLORS } from "semantic-ui-react";

export const categoryOptions = [
    { text: 'Incitement to Violence', value: 1, color: "red" as SemanticCOLORS },
    { text: 'Classic / Conspiracy Antisemitism', value: 2, color: "red" as SemanticCOLORS },
    { text: 'Fake / False Information', value: 3, color: "red" as SemanticCOLORS },
    { text: 'Holocaust / Holocaust Denial', value: 5, color: "red" as SemanticCOLORS },
    { text: 'Anti-Israel', value: 6, color: "red" as SemanticCOLORS },
    { text: 'Not Hate Speech', value: 7, color: "green" as SemanticCOLORS },
    { text: 'Anti-Zionist', value: 8, color: "red" as SemanticCOLORS },
    { text: 'Supporting Terrorism', value: 9, color: "red" as SemanticCOLORS },
    //{ text: 'Pro Palestine', value: 10, color: "red" as SemanticCOLORS }
]

export const antiIsraeliCategoryOptions = [
    { text: 'Incitement to Violence', value: 1, color: "red" as SemanticCOLORS },
    { text: 'Classic / Conspiracy Antisemitism', value: 2, color: "red" as SemanticCOLORS },
    { text: 'Fake / False Information', value: 3, color: "red" as SemanticCOLORS },
    { text: 'Holocaust / Holocaust Denial', value: 5, color: "red" as SemanticCOLORS },
    { text: 'Anti-Israel', value: 6, color: "red" as SemanticCOLORS },
    { text: 'Anti-Zionist', value: 8, color: "red" as SemanticCOLORS },
    { text: 'Supporting Terrorism', value: 9, color: "red" as SemanticCOLORS },
    //{ text: 'Pro Palestine', value: 10, color: "red" as SemanticCOLORS },
    { text: 'Not Hate Speech', value: 7, color: "green" as SemanticCOLORS }
]

export var antiIsraeliCategoriesMapper: { [id: number]: string } = {
    1: "Incitement to Violence",
    2: "Classic / Conspiracy Antisemitism",
    3: "Fake / False Information",
    5: "Holocaust / Holocaust Denial",
    6: "Anti-Israel",
    8: "Anti-Zionist",
    9: "Supporting Terrorism",
    10: "Pro Palestine",
    7: "Not Hate Speech"
};

export var categoriesMapper: { [id: number]: string } = {
    1: "Incitement to Violence",
    2: "Classic / Conspiracy Antisemitism",
    3: "Fake / False Information",
    4: "Holocaust Denial",
    5: "Holocaust / Holocaust Denial",
    6: "Anti-Israel",
    7: "Not Hate Speech",
    8: "Anti-Zionist",
    9: "Supporting Terrorism",
    10: "Pro Palestine"
};

export enum categoryEnum {
    IncitmentToViolance = 1,
    ClassicConspiracyAntisemitism = 2,
    FakeInformation = 3,
    HolocaustDenial = 4,
    Holocaust = 5,
    AntiIsraeli = 6,
    NonHarmful = 7,
    AntiZionist = 8,
    SupportingTerrorism = 9,
    ProPalestine = 10
}