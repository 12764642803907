import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { store } from "./store";
import * as _ from 'lodash';
import { toast } from "react-toastify";


export default class GptClassifierStore {
    loading: boolean = false;
    randomResult: string = "Randomized GPT rank will be showed here";


    constructor() {
        makeAutoObservable(this);
    }

    rankRandom = async () => {
        try {
            this.randomResult = "Proccessing post...";
            const postResult = await agent.GptClassifier.rankRandom();
            console.log(postResult);
            this.randomResult = postResult.value;
        }
        catch (e) {
            this.randomResult = "An error occured, please try again";
            toast.error("An error occurred while GPT ranking random post.");
        }
    }

}
