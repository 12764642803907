import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import * as _ from 'lodash';
import { CyabraScanTask, JsonResult, NewScanWebResponse } from "../../features/swipe/components/CyabraScanTask";
import { CyabraLimit } from "../models/cyabra";
import { cyabraPlatformEnum, TaskClassifier } from "../common/options/platformOptions";
import { DateSearchType } from "../common/options/dateSearchTypeOptions";
import { store } from "./store";
import { date } from "yup";
import { toast } from "react-toastify";
import { TaskStatus } from "../models/task";

export default class CyabraStore {
    tasks: CyabraScanTask[] | null = null;
    limits: CyabraLimit | null = null;
    loading: boolean = false;
    limitsLoaded: boolean = false;
    tasksLoaded: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    getTasks = async () => {
        try {
            if (_.isNil(this.tasks)) {
                this.tasksLoaded = false;
                this.loading = true;
                this.tasks = await agent.Cyabra.getTasks()
                this.loading = false;
                this.tasksLoaded = true;
            }
        }
        catch (e) {
            toast.error("An error occurred while loading tasks.");
        }
    }

    getLimits = async () => {
        try {
            this.limitsLoaded = false;

            if (_.isNil(this.limits)) {
                this.limits = await agent.Cyabra.getLimits()
            }
            this.limitsLoaded = true;

        }
        catch (e) {
            toast.error("An error occurred while getting cyabra limits.");
        }
    }

    fetchPosts = async (id: string) => {
        try {
            let task = _.find<CyabraScanTask>(this.tasks, { id })
            if (!_.isNil(task) && task?.status && !task.isFetched) {
                this.tasksLoaded = false;
                var fetchState = await agent.Cyabra.fetchPosts(id);
                if (fetchState.isSuccess) {
                    toast.success("Task is been fetched.");
                    this.tasks = null;
                    this.getTasks();
                }
                else {
                    toast.error(fetchState.errorMessage);
                }
            }
            else { toast.error("Task posts has been already fetched."); }

        }
        catch (e) {
            toast.error("An error occurred while fetching posts.");
        }
    }

    startNewTask = async (phrase: string, platformType: cyabraPlatformEnum, dateSearchType: DateSearchType, taskClassifier: TaskClassifier) => {
        try {
            var task: NewScanWebResponse = await agent.Cyabra.startNewScan(phrase, platformType, dateSearchType, taskClassifier);
            if (!_.isNil(task)) {
                this.tasks?.push({ phrase: phrase, platform: platformType, date: dateSearchType, status: TaskStatus.Started, isFetched: false, createdOn: date.toString(), postCount: 500, id: task.scanId, projectId: task.scanId })
                toast.success("New scan task added.")
                store.modalStore.closeModal();
                this.tasks = null;
                this.getTasks();
            }
            else {
                toast.error("An error occurred while statring new task.");
            }
        }
        catch (e) {
            toast.error("An error occurred while statring new task.");
        }
    }

    startNewScrape = async () => {
        try {
            var result: boolean = await agent.Scrape.startNewScrape();
            if (result) {
                toast.success("New scan scrape finished.")
            }
            else {
                toast.error("An error occurred while statring new scrape.");
            }
        }
        catch (e) {
            toast.error("An error occurred while statring new scrape.");
        }
    }

    checkPhrase = async (phrase: string) => {
        try {
            return await agent.Reports.checkPhrase(phrase);
        }
        catch (e) {
            console.error(`An error occurred while trying to check phrase.`, e)
        }
    }

    removeScanTask = async (id: string) => {
        try {
            let task = _.find<CyabraScanTask>(this.tasks, { id })
            if (!_.isNil(task)) {
                var result = await agent.Cyabra.removeScanTask(id);
                if (result.isSuccess) {
                    toast.success("Scan task deleted.");
                    this.tasks = null;
                    this.getTasks();
                }
                else {
                    toast.error(result.errorMessage);
                }
            }
        }
        catch (e) {
            toast.error("An error occurred while removing scan task posts.");
        }
    }

    removeScanTaskPosts = async (id: string) => {
        try {
            let task = _.find<CyabraScanTask>(this.tasks, { id })
            if (!_.isNil(task) && task?.status && task.isFetched) {
                var result = await agent.Cyabra.removeScanTaskPosts(id);
                if (result.isSuccess) {
                    toast.success("Scan task posts were deleted.");
                    this.tasks = null;
                    this.getTasks();
                }
                else {
                    toast.error(result.errorMessage);
                }
            }
            else {
                toast.error("Scan task posts were not fetched.");
            }
        }
        catch (e) {
            toast.error("An error occurred while removing scan task posts.");
        }
    }


    scorePosts = async () => {
        try {
            const result = await agent.Cyabra.socrePosts()
            if (result.isSuccess) {
                toast.success("Scoring posts...");
            }
            else {
                toast.error("An error occurred while scoring posts.");
            }

        }
        catch (e) {
            toast.error("An error occurred while scoring posts.");
        }
    }

    downloadTaskPosts = async (scanTaskId: string) => {
        return await agent.Cyabra.downloadTaskPosts(scanTaskId);
    }

}
