import { Formik, Form } from 'formik'
import { observer } from 'mobx-react-lite'
import { Button, Header } from 'semantic-ui-react'
import * as Yup from 'yup'
import SelectInput from '../../../app/common/form/SelectInput'
import TextInput from '../../../app/common/form/TextInput'
import { useStore } from '../../../app/stores/store'
import { AddNewTokenDto } from './AddNewTokenDto'
import { WebhookType, webhookTypeOptions } from './WebhookType'

function NewApiTokenForm() {
    const { apiTokenStore } = useStore();
    const { addNewToken } = apiTokenStore;


    const validationSchema = Yup.object({
        name: Yup.string().required('Name is required'),
        webhookType: Yup.number().required('Webhook type is required')

    })

    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={{ name: '', webhookType: WebhookType.BrightDataPostCheck, error: null }}
            onSubmit={(values, { setErrors }) => {
                const addTokenDto: AddNewTokenDto = {
                    name: values.name,
                    webhookType: values.webhookType
                };
                addNewToken(addTokenDto)
                    .catch(() => setErrors({ error: 'Error occured adding token.' }))
            }}>
            {({ handleSubmit, isSubmitting, isValid, dirty }) => (
                <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                    <Header as='h2' content='Add new token' color='teal' textAlign='center' />
                    <TextInput name='name' placeholder='Name' />
                    <SelectInput name='webhookType' placeholder='Webhook Type' options={webhookTypeOptions} />
                    <Button disabled={isSubmitting || !dirty || !isValid} loading={isSubmitting} positive content="Add" type='submit' fluid />
                </Form>
            )}

        </Formik>
    )
}

export default observer(NewApiTokenForm)
