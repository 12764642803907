import axios, { AxiosResponse } from "axios";
import { request } from "http";
import { toast } from "react-toastify";
import { history } from "../..";
import { AddNewTokenDto } from "../../features/admin/apiTokensManagement/AddNewTokenDto";
import { ApiToken } from "../../features/admin/apiTokensManagement/ApiToken";
import {
  CyabraScanTask,
  JsonResult,
  NewScanWebResponse,
} from "../../features/swipe/components/CyabraScanTask";
import { PostToVote } from "../../features/swipe/components/PostToVote";
import { categoryEnum } from "../common/options/categoryOptions";
import { DateSearchType } from "../common/options/dateSearchTypeOptions";
import {
  cyabraPlatformEnum,
  TaskClassifier,
} from "../common/options/platformOptions";
import LoadingComponent from "../layout/LoadingComponent";
import {
  AddCollectorDto,
  GetCollectorDto,
  UpdateCollectorDto,
} from "../models/collectors";
import { CyabraLimit } from "../models/cyabra";
import { MonitorStatus } from "../models/monitorStatus";
import { PaginatedResult } from "../models/pagination";
import { Photo } from "../models/photo";
import { Profile } from "../models/profile";
import { MinimalReport, Report } from "../models/report";
import {
  AddSeleniumAgentDto,
  GetSeleniumAgentDto,
  UpdateSeleniumAgentDto,
} from "../models/seleniumAgents";
import {
  SendMultiFactorAuthValues,
  RegisterFormValues,
  UpdateUserDto,
  User,
  UserFormValues,
  VerifyMultiFactorAuthValues,
  MultiFactorAuthenticatorForm,
  ChangeUserNumberValues,
  VerifyCaptcha,
  VerifyCaptchaResponse,
  VolunteersAction,
  SetPostLanguageRequest,
} from "../models/user";
import { store } from "../stores/store";
import {
  Keyword,
  NewKeyword,
} from "../../features/admin/keywordsManagement/Keyword";
import { Scraped } from "../models/scraped";

const sleep = (ms: number) => (response: AxiosResponse) =>
  new Promise<AxiosResponse>((resolve) =>
    setTimeout(() => resolve(response), ms)
  );

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use((config) => {
  const token = store.commonStore.token;
  if (token && config?.headers)
    config.headers["Authorization"] = `Bearer ${token}`;

  return config;
});

axios.interceptors.response.use(
  async (response) => {
    await sleep(1000);
    const pagination = response.headers["pagination"];
    if (pagination) {
      response.data = new PaginatedResult(
        response.data,
        JSON.parse(pagination)
      );
      return response as AxiosResponse<PaginatedResult<any>>;
    }
    return response;
  },
  (error) => {
    const { status, data, headers } = error.response;
    if (data.errors) {
      const modalStateErrors = [];
      for (const key in data.errors) {
        if (data.errors[key]) {
          modalStateErrors.push(data.errors[key]);
        }
      }
      throw modalStateErrors.flat();
    }
    if (error.message === "Network Error" && !error.response) {
      toast.error("Network error - make sure API is running!");
    }
    if (status === 401) {
      if (
        headers["www-authenticate"].startsWith('Bearer error="invalid_token"')
      ) {
        store.userStore.logout();
        toast.error("Session expired - please login.");
      }
    }

    if (status === 404) history.push("/not-found");

    if (status === 500) {
      toast.error("Server error - check the terminal for more info!");
    }
    return Promise.reject(error);
  }
);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
  get: <T>(url: string) =>
    axios.get<T>(url).then(sleep(1000)).then(responseBody),
  post: <T>(url: string, body: {}) =>
    axios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

const Reports = {
  checkPhrase: (phrase: string) =>
    requests.post<number>(`/reports/RateText?text=${phrase}`, {}),
  list: () => axios.get<Report[]>("/reports").then(responseBody),
  listMinimal: () =>
    axios.get<MinimalReport[]>("/reports/minimal").then(responseBody),
  listByUser: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<Report[]>>("/reports/user", { params })
      .then(responseBody),
  listStats: () => axios.get<Report[]>("/reports/stats").then(responseBody),
  details: (id: string) => requests.get<Report>(`/reports/${id}`),
  add: (report: Report) => requests.post<void>("/reports", report),
  edit: (id: string, report: Report) =>
    requests.put<void>(`/reports/${id}`, report),
  delete: (id: string) => requests.del<void>(`/reports?id=${id}`),
  uploadPhoto: (file: Blob) => {
    let formData = new FormData();
    formData.append("File", file);
    return requests.post<Photo>("/ScrapePosts/addPhoto", formData);
  },
  uploadMainPhoto: (file: Blob) => {
    let formData = new FormData();
    formData.append("File", file);
    return axios.post<Photo>("/photos/main", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
};

const Account = {
  current: () => requests.get<User>("/account"),
  list: () => requests.get<UpdateUserDto[]>("/account/users"),
  volunteersActivity: () =>
    requests.get<VolunteersAction[]>("/account/volunteersActivity"),
  login: (user: UserFormValues) => requests.post<User>("/account/login", user),
  loginMultiFactorAuth: (user: UserFormValues) =>
    requests.post<SendMultiFactorAuthValues>(
      "/account/loginMultiFactorAuth",
      user
    ),
  register: (user: RegisterFormValues) =>
    requests.post<User>("/account/register", user),
  count: () => requests.get<number>("/account/count"),
  refreshToken: () => requests.post<User>("/account/refreshToken", {}),
  updateUser: (id: string, user: UpdateUserDto) =>
    requests.put<UpdateUserDto>(`/account/user/${id}`, user),
  setDefaultPassword: (id: string) =>
    requests.post<string>(`/account/setDefaultPassword`, { id }),
  getAuth0UserIdByEmail: (email: string) =>
    requests.get<string>(
      `/account/getAuth0UserIdByEmail/${email}` /*, { email }*/
    ),
  getMultiFactorAuth: (mfaRequest: SendMultiFactorAuthValues) =>
    requests.post<MultiFactorAuthenticatorForm>(
      `/account/getMultiFactorAuth`,
      mfaRequest
    ),
  sendMultiFactorAuth: (mfaRequest: MultiFactorAuthenticatorForm) =>
    requests.post<string>(`/account/sendMultiFactorAuth`, mfaRequest),
  verifyMultiFactorAuth: (mfaVerify: VerifyMultiFactorAuthValues) =>
    requests.post<string>(`/account/verifyMultiFactorAuth`, mfaVerify),
  changePhoneNumber: (creds: ChangeUserNumberValues) =>
    requests.post<boolean>(`/account/changePhoneNumber`, creds),
  forgotPassword: (email: string) =>
    requests.post<boolean>(`/account/forgotPassword`, { email }),
  setPostLanguages: (setPostLanguageRequest: SetPostLanguageRequest) =>
    requests.post<boolean>(`/account/setPostLanguages`, setPostLanguageRequest),

  verifyCaptcha: (captcha: VerifyCaptcha) =>
    requests.post<VerifyCaptchaResponse>("/account/VerifyCaptcha", captcha),
};

const Profiles = {
  get: (username: string) => requests.get<Profile>(`/profiles/${username}`),
};

const Monitors = {
  onlineTool: () =>
    requests.get<MonitorStatus>(
      `${process.env.POST_CHECKER_URL}Main/MonitorStatus`
    ),
};
const ApiTokens = {
  createToken: (tokenDto: AddNewTokenDto) =>
    requests.post<any>("/apiTokens/Create", tokenDto),
  getApiTokens: () => requests.get<ApiToken[]>("/apiTokens"),
  deleteToken: (id: string) => requests.del<JsonResult>(`/apiTokens/${id}`),
};

const Keywords = {
  getKeywords: () => requests.get<Keyword[]>("/keywords/"),
  getKeywordsCounterStatus: () =>
    requests.get<number>("/keywords/counterStatus"),
  addKeyword: (keyword: NewKeyword) =>
    requests.post<any>("/keywords/Create", keyword),
  addKeywordList: (keywords: NewKeyword[]) =>
    requests.post<any>("/keywords/CreateKeywords", keywords),
  deleteKeyword: (id: string) => requests.del<JsonResult>(`/keywords/${id}`),
  refreshKeywordsCount: () =>
    requests.post<boolean>("/keywords/RefreshKeywordsCount", {}),
};

const GptClassifier = {
  rankRandom: () => requests.post<any>("/gptClassifier/RankRandom", {}),
};

const Collectors = {
  getCollectors: () => requests.get<GetCollectorDto[]>("/collectors"),
  addCollector: (collectorDto: AddCollectorDto) =>
    requests.post<any>("/collectors", collectorDto),
  updateCollector: (collectorDto: UpdateCollectorDto) =>
    requests.put<any>("/collectors", collectorDto),
  deleteCollector: (id: string) => requests.del<any>(`/collectors/${id}`),
};

const SeleniumAgents = {
  getAgents: () => requests.get<GetSeleniumAgentDto[]>("/seleniumAgents"),
  addAgent: (collectorDto: AddSeleniumAgentDto) =>
    requests.post<any>("/seleniumAgents", collectorDto),
  updateAgent: (collectorDto: UpdateSeleniumAgentDto) =>
    requests.put<any>("/seleniumAgents", collectorDto),
  deleteAgent: (id: string) => requests.del<any>(`/seleniumAgents/${id}`),
  setAgent: (id: string) => requests.post(`/seleniumAgents/setAgent/${id}`, {}),
};

const Scrape = {
  getPostToVote: () => requests.get<PostToVote>("/ScrapePosts/GetPostToVote"),
  votePost: (id: string, categories: categoryEnum[], categoryString: string) =>
    requests.post("/ScrapePosts/VotePost", { id, categories, categoryString }),
  skipPost: (id: string, reason: string) =>
    requests.post("/ScrapePosts/SkipPost", { id, reason }),
  startNewScrape: () => requests.post<boolean>("/ScrapePosts/StartScrape", {}),
  scrapedPosts: (amount: number, skip: number) =>
    requests.post<Scraped[]>("/ScrapePosts/GetAll", { amount, skip }),
};

const Cyabra = {
  getPostToVote: () => requests.get<PostToVote>("/cyabra/GetPostToVote"),
  socrePosts: () => requests.get<JsonResult>("/cyabra/GetAiScores"),
  votePost: (id: string, categories: categoryEnum, categoryString: string) =>
    requests.post("/cyabra/VotePost", {
      id,
      categories,
      categoryString,
    }),
  getTasks: () => requests.get<CyabraScanTask[]>("/cyabra/GetTasks"),
  getLimits: () => requests.get<CyabraLimit>("/cyabra/GetLimits"),
  startNewScan: (
    keyword: string,
    platformType: cyabraPlatformEnum,
    dateSearchType: DateSearchType,
    taskClassifier: TaskClassifier
  ) =>
    requests.post<NewScanWebResponse>("/cyabra/StartScan", {
      keyword,
      platformType,
      dateSearchType,
      taskClassifier,
    }),
  fetchPosts: (scanTaskId: string) =>
    requests.post<JsonResult>("/cyabra/FetchPosts", { scanTaskId }),
  removeScanTask: (scanTaskId: string) =>
    requests.post<JsonResult>("/cyabra/DeleteTask", { scanTaskId }),
  removeScanTaskPosts: (scanTaskId: string) =>
    requests.post<JsonResult>("/cyabra/RemoveScanTaskPosts", { scanTaskId }),
  downloadTaskPosts: (scanTaskId: string) => {
    axios({
      url: `/cyabra/DownloadTaskPosts?scanTaskId=${scanTaskId}`,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      const contentDisposition = response.headers["content-disposition"];
      let fileName = `CyabraPosts_${scanTaskId}.csv`;
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch?.length === 2) fileName = fileNameMatch[1];
      }
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  },
};
const agent = {
  Reports,
  Account,
  Profiles,
  Monitors,
  Cyabra,
  ApiTokens,
  Keywords,
  GptClassifier,
  Collectors,
  SeleniumAgents,
  Scrape,
};

export default agent;
