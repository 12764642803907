import { observer } from 'mobx-react-lite';
import React from 'react'
import {   Grid, Header, Item,  Segment, Statistic } from 'semantic-ui-react'
import { Profile } from '../../app/models/profile'
import *  as _ from 'lodash'

interface Props{
    profile: Profile;
}
function ProfileHeader({profile}:Props) {
    const isActive = _.countBy(profile.reports,'isActive')
  return (
   <Segment>
    <Grid>
        <Grid.Column width={12}>
            <Item.Group>

                    <Item>
                    <Item.Image avatar size='small' src={profile.image || '/assets/user.png'} />
                    <Item.Content verticalAlign='middle'>
                        <Header as='h1' content={profile.displayName}/>
                    </Item.Content>
                </Item>

                
            </Item.Group>
        </Grid.Column>
        <Grid.Column width={4}>
            <Statistic.Group widths={2}>
                <Statistic label='Posts reported' value={profile.reports?.length}/> 
                <Statistic label='Posts removed' value={_.has(isActive,'false') ? isActive['false'] : 0}/> 
            </Statistic.Group>
        </Grid.Column>
    </Grid>
   </Segment>
  )
}

export default observer(ProfileHeader)
