import { AgChartsReact } from 'ag-charts-react';
import { AgChartOptions } from 'ag-charts-community';
import { useStore } from '../../../app/stores/store';



const LogChart = () => {
    const { reportStore } = useStore();
    const { statsByDate } = reportStore;
    const options: AgChartOptions = {
        data: statsByDate,
        title: {
            text: 'Activity Over Time',
        },
        subtitle: {
            text: 'log scale',
        },
        series: [
            {
                type: 'line',
                xKey: 'year',
                yKey: 'posts',
            },
        ],
        axes: [
            {
                type: 'log',
                position: 'left',
                title: {
                    text: 'Posts',
                },
                label: {
                    format: ',.0f',
                    fontSize: 10,
                },
            },
            {
                type: 'number',
                position: 'bottom',
                title: {
                    text: 'Year',
                },
                label: {
                    fontSize: 10,
                },
            },
        ],
        legend: {
            enabled: false,
        },
    };

    return <AgChartsReact options={options} />;
};

export default LogChart;