export interface User{
    id: string;
    username: string;
    displayName: string;
    token: string;
    image?: string;
    userRole: UserRole;
    isActivated: boolean;
}

export interface VolunteersAction{
    postId: string;
    userId: string;
    displayName: string;
    email: string;
    actionDate: Date;
    actionName: string;
    postUrl: string;
    description: string;
    classification: number;
}

export interface UserFormValues{
    email: string;
    password: string;
    displayName?: string;
    username?: string;
}

export interface RegisterFormValues{
    email: string;
    password: string;
    displayName: string;
    username: string;
    phoneNumber: string;
    country: string;
    program: string;
    createUser: boolean;
}

export interface SetPostLanguageRequest{
    userId: string;
    languages: string[];
}
export interface SendMultiFactorAuthValues{
    mfaToken: string;
    authenticator: string;
}

export interface MultiFactorAuthenticatorForm{
    mfaToken: string;
    authenticatorId: string;
    authenticatorName: string;
    defaultAuthenticator: string;
}

export interface VerifyMultiFactorAuthValues{
    mfaToken: string;
    oobCode: string;
    typedCode: string;
}

export interface ChangeUserNumberValues{
    mfaToken: string;
    previousPhoneNumber: string;
    newPhoneNumber: string;
}
export interface VerifyCaptcha {
    response: string;
}

export interface VerifyCaptchaResponse {
    isSuccess: boolean;
}

export enum UserRole {
    User = 1,
    TeamLeader = 2,
    Manager = 3,
    PowerUser = 4
}

export interface UpdateUserDto {
    id: string;
    username: string;
    displayName: string;
    email: string;
    country: string;
    program: string;
    userRole: UserRole;
    isActivated: boolean;
}