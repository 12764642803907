import React, { useState } from 'react'
import { Button, Confirm } from 'semantic-ui-react'

interface Props {
    func: () => void;
    text: string;
    disabled?: boolean;
}

function DeleteButton(props: Props) {

    const [isOpen, setIsOpen] = useState<boolean>(false)
    return (
        <>
            <Button size='mini' icon="delete" color="red" onClick={() => setIsOpen(true)}></Button>
            <Confirm
                open={isOpen}
                onCancel={() => setIsOpen(false)}
                onConfirm={() => props.func()}
                size="mini"
                confirmButton="Delete"
                content={props.text}
            />
        </>
    )
}

export default DeleteButton

export function SetButton(props: Props) {

    const [isOpen, setIsOpen] = useState<boolean>(false)
    return (
        <>
            {props.disabled ?
                <Button size='mini' disabled icon="check circle outline" color="yellow" onClick={() => setIsOpen(true)}></Button> :
                <Button size='mini' icon="check circle" color="yellow" onClick={() => setIsOpen(true)}></Button>
            }
            <Confirm
                open={isOpen}
                onCancel={() => setIsOpen(false)}
                onConfirm={() => props.func()}
                size="mini"
                confirmButton="Set"
                content={props.text}
            />
        </>
    )
}
