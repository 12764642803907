import { Segment, List, Item } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite'
import { Report } from '../../../app/models/report'
import Moment from 'moment';

interface Props {
    report: Report
}
export default observer(function ReportDetailedSideBar ({report}: Props) {
    return (
        <>
            <Segment
                textAlign='center'
                style={{ border: 'none' }}
                attached='top'
                secondary
                inverted
                color='teal'
            >
                Timeline
            </Segment>
            <Segment attached>
                <List relaxed divided>
                    <Item style={{ position: 'relative' }}>
                        <Item.Content verticalAlign='middle'>
                            <Item.Header as='h3'>
                                Added on
                            </Item.Header>
                            <Item.Extra style={{ color: 'orange' }}>{Moment(report?.createdOn).format("DD-MMM-YYYY")}</Item.Extra>
                        </Item.Content>
                    </Item>

                    <Item style={{ position: 'relative' }}>
                        <Item.Content verticalAlign='middle'>
                            <Item.Header as='h3'>
                                Approved on
                            </Item.Header>
                            <Item.Extra style={{ color: 'orange' }}>{report?.approvedOn ? Moment(report?.approvedOn).format("DD-MMM-YYYY") : "Not approved"}</Item.Extra>
                        </Item.Content>
                    </Item>

                    <Item style={{ position: 'relative' }}>
                        <Item.Content verticalAlign='middle'>
                            <Item.Header as='h3'>
                                Removed on
                            </Item.Header>
                            <Item.Extra style={{ color: 'orange' }}>{report?.removedOn ? Moment(report?.removedOn).format("DD-MMM-YYYY") : "Online"}</Item.Extra>
                        </Item.Content>
                    </Item>
                </List>
            </Segment>
        </>

    )
})

