import React from 'react'
import { Button } from 'semantic-ui-react'

type Params = {
  click: (user: any) => void;
  data: any;
};


export function UserManagementResetPassBtn({ click, data }: Params) {
  const handleButtonClick = () => {
    click(data);
  };

  return (
    <Button size='mini' onClick={handleButtonClick}>
      Reset
    </Button>
  )
}
