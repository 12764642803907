import { Formik, Form, ErrorMessage } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { Button, Header } from 'semantic-ui-react'
import TextInput from '../../app/common/form/TextInput'
import SelectInput from '../../app/common/form/SelectInput'
import { useStore } from '../../app/stores/store'
import * as Yup from 'yup'
import * as _ from 'lodash';
import ValidationErrors from '../errors/ValidationErrors'
import { countryOptions, phoneRegExp } from '../../app/models/countryEnum'
import MultiFactorAuthForm from './MultiFactorAuthForm'
import { toast } from 'react-toastify'
import ReCAPTCHA from "react-google-recaptcha";

const captchaCenter: React.CSSProperties = {
    textAlign: '-webkit-center' as any
}

function RegisterForm() {
    const { modalStore, userStore } = useStore();
    
    const phoneRegExp = /^((\\+[0-9]{9,15}[ \\-]*)|(\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const [isCaptchaValid, setIsCaptchaValid] = useState<boolean>(true);
    function onChange(value: string | null) {
        if (!_.isNil(value)) {
            userStore.verifyCaptcha(value).then(response => {
                if (response) {
                    setIsCaptchaValid(response.isSuccess);
                }
            })
        }
    }
    const validationSchema = Yup.object({
        email: Yup.string().required('Email is required').email(),
        password: Yup.string().required('Password is required'),
        userName: Yup.string().required('Username is required').matches(/^\S*$/, 'Username should not contain spaces'),
        displayName: Yup.string().required('Display name is required'),
        phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone number is required'),
        country: Yup.string().required('Country is required'),
        program: Yup.string().required('Program is required')

    })


    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={{ email: '', password: '', username: '', displayName: '', phoneNumber: '', country: '', program: '', createUser: false, error: null }}
            onSubmit={(values, { setErrors }) => {
                return userStore.registerMultiFactorAuth(values)
                    .then(() => {
                        toast.success("Verification email sent!")
                        modalStore.closeModal();
                    }).catch((error) => setErrors({ error }));
            }}>
            {({ handleSubmit, isSubmitting, errors, isValid, dirty }) => (
                <Form className='ui form error' onSubmit={handleSubmit} autoComplete='off'>
                    <Header as='h2' content='Register to FOA' color='teal' textAlign='center' />
                    <TextInput name='displayName' placeholder='Display Name' />
                    <TextInput name='userName' placeholder='Username' />
                    <TextInput name='email' placeholder='Email' />
                    <TextInput name='password' placeholder='Password' type='password' />
                    <TextInput name='phoneNumber' placeholder='Phone Number +(000)123456789' />
                    <SelectInput name='country' placeholder='Country' options = {countryOptions} search/>
                    <TextInput name='program' placeholder='Program' />
                    <ErrorMessage name='error'
                        render={() => <ValidationErrors errors={errors.error} />} />
                    {/*<div*/}
                    {/*    style={captchaCenter}>*/}
                    {/*    <ReCAPTCHA*/}
                    {/*        sitekey="6Ld8ML8oAAAAAMXGCgyoUbsQ4R70Ymwh4gQfgg8K"*/}
                    {/*        onChange={onChange}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    <Button disabled={isSubmitting || !isValid || !isCaptchaValid || !dirty} loading={isSubmitting} positive content="Register" type='submit' fluid />
                </Form>
            )}

        </Formik>
    )
}

export default observer(RegisterForm)
