export enum SeleniumAgentType {
    Local = 0,
    Docker = 1,
    BrightData = 2
}

export const seleniumAgentOptions = [
    { text: 'Local', value: 0 },
    { text: 'Docker', value: 1 },
    { text: 'Bright Data', value: 2 }
]

export const seleniumAgentNameByType: Map<SeleniumAgentType, string> = new Map<SeleniumAgentType, string>([
    [SeleniumAgentType.Local, 'Local'],
    [SeleniumAgentType.Docker, 'Docker'],
    [SeleniumAgentType.BrightData, 'Bright Data']
]);