import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { store } from "./store";
import * as _ from 'lodash';
import { toast } from "react-toastify";
import { ApiToken } from "../../features/admin/apiTokensManagement/ApiToken";
import { AddNewTokenDto } from "../../features/admin/apiTokensManagement/AddNewTokenDto";
import { Keyword, NewKeyword } from "../../features/admin/keywordsManagement/Keyword";

export default class KeywordsManangementStore {
    keywords: Keyword[] | null = null;
    loading: boolean = false;
    keywordsLoaded: boolean = false;
    counting: boolean = false;
    counterStatus: number = -1;

    constructor() {
        makeAutoObservable(this);
    }

    getKeywords = async () => {
        try {
            if (_.isNil(this.keywords)) {
                this.keywordsLoaded = false;
                this.loading = true;
                const results = await agent.Keywords.getKeywords();
                this.keywords = results;
                this.loading = false;
                this.keywordsLoaded = true;
            }
        }
        catch (e) {
            toast.error("An error occurred while loading keywords.");
        }
    }

    addNewKeyword = async (keyword: NewKeyword) => {
        try {
            const keywordAdded = await agent.Keywords.addKeyword(keyword);
            const keywordWithId = this.keywords?.find(keyword => keyword.id === keywordAdded.value.id);
            if(keywordWithId){
                this.keywords = this.keywords?.map((keyword) =>
                    keyword.id === keywordAdded.value.id ? keywordAdded.value : keyword
                ) || [];
                toast.success(`Updated keyword ${keyword.text} to importance ${keyword.importance}.`);
            }
            else{
                this.keywords?.push(keywordAdded.value);
                toast.success(`Added keyword ${keyword.text} with importance ${keyword.importance}.`);
            }

            
        }
        catch (e) {
            toast.error("An error occurred while add keyword.");
        }
    }

    addKeywordList = async (keywords: NewKeyword[]) => {
        try {

            toast.success(`Started to add / update ${keywords.length} keywords`);
            const keywordAddedCount = await agent.Keywords.addKeywordList(keywords);
            const results = await agent.Keywords.getKeywords();
            this.keywords = results;
            toast.success(`Added / Updated ${keywordAddedCount.value} keywords with importance ${keywords[0]?.importance}.`);
    
        }
        catch (e) {
            toast.error("An error occurred while add keywords.");
        }
    }

    deleteKeyword = async (keyword: Keyword) => {
        try {
            const result = await agent.Keywords.deleteKeyword(keyword.id);
            this.keywords = this.keywords?.filter(e => e.id !== keyword.id) ?? [];
            toast.success(`Deleted keyword ${keyword.text}.`);
        }
        catch (e) {
            toast.error("An error occurred while delete keyword.");
        }
    }

    refreshKeywordsCount = async () => {
        try {
            this.counting = true;
            toast.success(`Started refreshing posts keywords count. It will take few minutes...`);
            const result = await agent.Keywords.refreshKeywordsCount();
            if(result){
                //toast.success(`Refreshed ${count} posts`);
                this.counting = false;
                this.counterStatus = -1;
            }
            else
                toast.error(`Another refresh posts keywords count task is running`);
            
        }
        catch (e) {
            this.counting = false;
            toast.error("An error occurred while refreshing posts keywords count.");
        }
    }

    getKeywordsCounterStatus = async () => {
        try {
            this.counterStatus = await agent.Keywords.getKeywordsCounterStatus();
            if(this.counterStatus > -1)
                this.counting = true;
            return this.counterStatus;
        }
        catch (e) {
        }
    }
    
    setCounting = async (set: boolean) => {
        this.counting = set;
    }
}
