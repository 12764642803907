import { Grid, Statistic } from 'semantic-ui-react'
import { useStore } from '../../../app/stores/store';
import * as _ from 'lodash'

function ReportStatisticHeader() {
    const { reportStore, userStore } = useStore();
    const { statsByPlatform } = reportStore;
    const { usersCount } = userStore;

    const allStats = statsByPlatform.get('All');


    return (
        <Grid.Column width="16">
            <Statistic.Group widths='four'>
                <Statistic>
                    <Statistic.Value>{allStats?.total}</Statistic.Value>
                    <Statistic.Label>Total posts</Statistic.Label>
                </Statistic>

                <Statistic>
                    <Statistic.Value>{allStats?.online}</Statistic.Value>
                    <Statistic.Label>Total online</Statistic.Label>
                </Statistic>

                <Statistic>
                    <Statistic.Value>{allStats?.removed}</Statistic.Value>
                    <Statistic.Label>Total removed</Statistic.Label>
                </Statistic>

                <Statistic>
                    <Statistic.Value>{usersCount}</Statistic.Value>
                    <Statistic.Label>Team Members</Statistic.Label>
                </Statistic>
            </Statistic.Group>
        </Grid.Column>

    )
}

export default ReportStatisticHeader
