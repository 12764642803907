import { useCallback, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useStore } from '../../../app/stores/store';
import { observer } from 'mobx-react-lite';
import { ColDef, GridOptions } from 'ag-grid-community';
import { UpdateUserDto } from '../../../app/models/user';
import { userRoleDictionary } from '../../../app/common/options/userRoleOptions';
import { UserManagementResetPassBtn } from './UserManagementResetPassBtn';

const UserManagement = () => {
    const { userStore } = useStore();

    const { usersList, updateUser, setDefaultPassword } = userStore;

    const gridOptions: GridOptions<UpdateUserDto> = {
        paginationAutoPageSize: true,
        pagination: true,
        defaultColDef: {
            resizable: true,
        },
        alwaysShowHorizontalScroll: true,
    }
    const [columnDefs, setColumnDefs] = useState<ColDef[]>([
        { headerName: 'Id', field: 'id', editable: false },
        { headerName: 'Name', field: 'displayName', editable: false },
        { headerName: 'Username', field: 'userName', editable: false },
        { headerName: 'Email', field: 'email', editable: false },
        { headerName: 'Country', field: 'country', editable: false },
        { headerName: 'Program', field: 'program', editable: false },
        {
            headerName: 'User Role',
            field: 'userRole',
            valueParser: (parmas: any) => parmas.value,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: [1, 2, 3, 4]
            },
            valueFormatter: (params: any) => {
                return userRoleDictionary.get(params.value) || '';
            }
        },
        {
            headerName: 'Is Activated', field: 'isActivated',
            valueParser: (parmas: any) => parmas.value ? "Yes" : "No",
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: [true, false]
            }
        },
        {
            headerName: 'Set Default Password',
            field: 'setDefaultPassword',
            editable: false,
            cellRenderer: UserManagementResetPassBtn,
            cellRendererParams: {
                click: (user: any) => setDefaultPassword(user.id),
            },
        },
    ]);
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            editable: true,
            resizable: true,
            minWidth: 100,

        };
    }, []);

    const onRowValueChanged = useCallback((event) => {
        updateUser(event.data);
    }, []);


    return (

        <div className="ag-theme-alpine" style={{ height: 500, width: 810 }}>
            <AgGridReact
                rowData={usersList}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                editType={'fullRow'}
                onRowValueChanged={onRowValueChanged}
                gridOptions={gridOptions}
            ></AgGridReact>
        </div>
    );
};

export default observer(UserManagement)