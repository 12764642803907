import { ColDef } from "ag-grid-community";
import * as _ from "lodash";
import { platformDictionary } from "../../app/common/options/platformOptions";
import { cellLink, failureSpan, successSpan } from "./ReportCellRenderers";
import { antiIsraeliCategoriesMapper } from "../../app/common/options/categoryOptions";

const dateFilterParams = {
  comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
    var cellDate = new Date(cellValue);

    if (
      filterLocalDateAtMidnight.getDay() === cellDate.getDay() &&
      filterLocalDateAtMidnight.getMonth() === cellDate.getMonth() &&
      filterLocalDateAtMidnight.getFullYear() === cellDate.getFullYear()
    ) {
      return 0;
    }

    if (cellDate <= filterLocalDateAtMidnight) {
      return -1;
    }

    if (cellDate >= filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  },
};

const platformFilterParams = {
  filterOptions: ["equal", "notEqual"],
  debounceMs: 200,
  maxNumConditions: 1,
  textMatcher: (value: any) => {
    const platform = platformDictionary.get(parseInt(value.value));
    return platform?.toLowerCase() === value.filterText ? 1 : 0;
  },
};

const booleanFilterParams = {
  filterOptions: ["equal", "notEqual"],
  debounceMs: 200,
  maxNumConditions: 1,
  textMatcher: (value: any) => {
    const bool = getBooleanValue(value.value === "true" ? true : false);
    return bool?.toLowerCase() === value.filterText ? 1 : 0;
  },
};

export const reportColumnDefs: ColDef[] = [
  {
    field: "createdOn",
    valueFormatter: (params: { value: string }) => {
      var date = new Date(params.value);
      return date.toDateString();
    },
    filter: "agDateColumnFilter",
    filterParams: dateFilterParams,
    sortable: true,
  },
  {
    field: "createdBy",
    filter: "agTextColumnFilter",
  },
  { field: "description" },
  {
    headerName: "Hashtag/s",
    field: "content",
  },
  {
    headerName: "Post Link",
    field: "url",
    cellRenderer: (params: { value: string }) => {
      return cellLink(params);
    },
  },
  {
    headerName: "Screenshot",
    field: "image",
    cellRenderer: (params: { value: string }) => {
      return cellLink(params);
    },
  },
  { field: "id" },
  {
    headerName: "Is Online",
    field: "isActive",
    valueFormatter: (params: { value: boolean }) => {
      return getBooleanValue(params.value);
    },
    filter: "agTextColumnFilter",
    filterParams: booleanFilterParams,
    cellRenderer: (params: { value: boolean; valueFormatted: string }) => {
      if (params.value) return failureSpan(params.valueFormatted);
      else return successSpan(params.valueFormatted);
    },
  },
  {
    field: "removedOn",
    valueFormatter: (params: { value: string }) => {
      if (_.isNil(params.value)) {
        return "Not Removed";
      } else {
        var date = new Date(params.value);
        return date.toDateString();
      }
    },
    sortable: true,
    cellRenderer: (params: { value: boolean; valueFormatted: string }) => {
      if (params.value) return successSpan(params.valueFormatted);
      else return failureSpan(params.valueFormatted);
    },
  },

  {
    field: "platform",
    valueFormatter: (params: { value: number }) => {
      return platformDictionary.get(params.value) || "";
    },
    filter: "agTextColumnFilter",
    filterParams: platformFilterParams,
  },

  {
    field: "isArchived",
    valueFormatter: (params: { value: boolean }) => {
      return getBooleanValue(params.value);
    },
    filter: "agTextColumnFilter",
    filterParams: booleanFilterParams,
  },
  {
    field: "categories",
    valueFormatter: (params: { value: number[] }) => {
      if (Array.isArray(params.value) && params.value.length > 0) {
        return params.value
          .filter((item) => antiIsraeliCategoriesMapper.hasOwnProperty(item))
          .map((item) => antiIsraeliCategoriesMapper[item])
          .join(", ");
      } else {
        return "";
      }
    },
    filter: "agTextColumnFilter",
    //filterParams: platformFilterParams,
    sortable: true,
  },
];

const getBooleanValue = (bool: boolean) => (bool ? "Yes" : "No");

export {};
