import { Formik, Form, ErrorMessage } from 'formik'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Button, Header, Label } from 'semantic-ui-react'
import TextInput from '../../app/common/form/TextInput'
import { useStore } from '../../app/stores/store'
import * as Yup from 'yup'

function ForgotPassForm() {
    const { userStore } = useStore();


    const validationSchema = Yup.object({
        email: Yup.string().required('Email is required').email(),
    })

    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={{ email: '', error: null }}
            onSubmit={(values, { setErrors }) => userStore.forgotPassword(values.email).catch(() => setErrors({ error: 'Invalid email' }))}>
            {({ handleSubmit, isSubmitting, errors, isValid, dirty }) => (
                <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                    <Header as='h2' content='Forgot Password' color='teal' textAlign='center' />
                    <TextInput name='email' placeholder='Email' />
                    <ErrorMessage name='error'
                        render={() => <Label style={{ marginBotton: 10 }} basic color='red' content={errors.error} />} />
                    <Button disabled={isSubmitting || !dirty || !isValid} loading={isSubmitting} positive content="Forgot Password" type='submit' fluid />
                </Form>
            )}

        </Formik>
    )
}

export default observer(ForgotPassForm)