import { Formik, Form } from 'formik'
import * as _ from 'lodash'
import { observer } from 'mobx-react-lite'
import { Button, Header } from 'semantic-ui-react'
import * as Yup from 'yup'
import SelectInput from '../../../app/common/form/SelectInput'
import TextInput from '../../../app/common/form/TextInput'
import { GetSeleniumAgentDto, AddSeleniumAgentDto, UpdateSeleniumAgentDto } from '../../../app/models/seleniumAgents'
import { SeleniumAgentType, seleniumAgentOptions } from '../../../app/models/seleniumAgentsTypes'
import { useStore } from '../../../app/stores/store'

interface Props {
    agent?: GetSeleniumAgentDto;
}

function SeleniumAgentForm({ agent }: Props) {
    const { seleniumAgentsStore } = useStore();
    const { addNewAgent, updateAgent } = seleniumAgentsStore;
    const initValues = {
        url: !_.isNil(agent) && !_.isNil(agent?.url) ? agent.url : '',
        type: !_.isNil(agent) && !_.isNil(agent?.agentType) ? agent.agentType : SeleniumAgentType.Local,
    }

    const validationSchema = Yup.object({
        url: Yup.string().required('Agent url is required'),
        type: Yup.number().required('Agent type is required')
    })

    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={{ url: initValues.url, type: initValues.type, error: null }}
            onSubmit={(values, { setErrors }) => {

                if (!_.isNil(agent) && !_.isNil(agent.id)) {
                    const updateSeleniumAgentDto: UpdateSeleniumAgentDto = {
                        id: agent.id,
                        url: values.url,
                        agentType: values.type
                    };
                    updateAgent(updateSeleniumAgentDto)
                        .catch(() => setErrors({ error: 'Error occured selenium agent.' }))
                }
                else {
                    const addSeleniumAgentDto: AddSeleniumAgentDto = {
                        url: values.url,
                        agentType: values.type
                    };
                    addNewAgent(addSeleniumAgentDto)
                        .catch(() => setErrors({ error: 'Error occured adding selenium agent.' }))
                }

            }}>
            {({ handleSubmit, isSubmitting, isValid, dirty }) => (
                <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                    {_.isNil(agent) ?
                        <Header as='h2' content='Add new agent' color='teal' textAlign='center' /> :
                        <Header as='h2' content='Update agent' color='teal' textAlign='center' />}
                    <TextInput name='url' placeholder='Url' />
                    <SelectInput name='type' placeholder='Agent Type' options={seleniumAgentOptions} />
                    <Button disabled={isSubmitting || !dirty || !isValid} loading={isSubmitting} positive content={_.isNil(agent) ? "Add" : "Update"} type='submit' fluid />
                </Form>
            )}

        </Formik>
    )
}

export default observer(SeleniumAgentForm)
