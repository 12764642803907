import { observer } from 'mobx-react-lite'
import { Tab } from 'semantic-ui-react'
import PhotoMainUploadWidget from '../../app/common/imageUpload/PhotoMainUploadWidget'
import { useStore } from '../../app/stores/store'
import ProfileReportsStatistics from './ProfileReportsStatistics'
import ProfileReportsTable from './ProfileReportsTable'
import { history } from '../..';
import NotAuthorized from '../errors/NotAuthorized'
import NothingHere from '../errors/NothingHere'

function ProfileContent() {
  const {profileStore, userStore} = useStore();
  const {user} = userStore;

    const panes =[
        {menuItem: 'About', render:()=> <Tab.Pane>{profileStore.profile?.bio ? "" : <NothingHere/>}</Tab.Pane>},
        {menuItem: 'Reports', render:()=> <Tab.Pane>{profileStore.profile?.reports &&<ProfileReportsTable/>}</Tab.Pane>},
        {menuItem: 'Statistics', render:()=> <Tab.Pane>{profileStore.profile?.reports &&<ProfileReportsStatistics/>}</Tab.Pane>},
        {menuItem: 'Profile Image', render:()=> <Tab.Pane>{user?.username === history.location.pathname.split('/')[2] ? <PhotoMainUploadWidget/> : <NotAuthorized/>}</Tab.Pane>},
    ]
  return (
    <Tab
    menu={{fluid: true, vertical: true}}
    menuPosition='right'
    panes={panes}
    >

    </Tab>
  )
}

export default observer(ProfileContent)
