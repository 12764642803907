import { observer } from 'mobx-react-lite'
import { Button, Form, Grid, Header, Tab } from 'semantic-ui-react'
import { useStore } from '../../app/stores/store'
import { history } from '../..';
import NothingHere from '../errors/NothingHere'
import { Formik } from 'formik';
import SelectInput from '../../app/common/form/SelectInput';
import { languageCodesOptions } from '../../app/models/countryEnum';
import { useState } from 'react';

function SettingsLanguage() {
  const {userStore} = useStore();
  const {user} = userStore;
  const [disabled, setDisabled] = useState(false);
  
  const setPostLanguages = async (languages: string[]) => {
    setDisabled(true);
    await userStore.setPostLanguages(languages)
    history.push('/vote');
}
  return (
    <Formik
        enableReinitialize
        initialValues={{languages: []}}
        onSubmit={values => setPostLanguages(values.languages)}>
        {({ handleSubmit,  values, isSubmitting }) => (
            <Form className="ui form" onSubmit={handleSubmit} autoComplete="off">
                <Grid centered>
                    <Grid.Column computer={14} tablet={16} mobile={16}>
                        <Header as='h3' floated='left'>Post Languages</Header>
                        <SelectInput multiple name='languages' placeholder="Post Languages" options={languageCodesOptions} search/>
                    </Grid.Column>
                    <Grid.Column computer={14} tablet={16} mobile={16}>
                        <Button
                            disabled={isSubmitting || values.languages.length == 0}
                            floated="right"
                            positive
                            type="submit"
                            content="Accept Changes"
                            fluid
                            style={{width: "150px"}}
                        />
                    </Grid.Column>
                    
                </Grid>
            </Form>
        )}
    </Formik>
  )
}

export default observer(SettingsLanguage)
