/* eslint-disable @typescript-eslint/no-unused-vars */
import * as _ from "lodash";
import { observer } from "mobx-react-lite";
import  { useEffect} from "react";
import { useParams } from "react-router-dom";
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar } from "recharts";
import { Grid } from "semantic-ui-react";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { ProfileParams } from "../../app/models/pagination";
import { useStore } from "../../app/stores/store";

function ProfileReportsStatistics() {
  const { profileStore } = useStore();
  const {
    loadReports,
    loadingProfileReports,
    platformsStatisticsByUser
  } = profileStore;
  const { username } = useParams<{ username: string }>();

  useEffect(() => {
    loadReports(new ProfileParams(username));
  }, [loadReports, username]);




  if (loadingProfileReports)
    return <LoadingComponent content="Loading user reports" />;
  return (
    <>
    <Grid>
      
      <BarChart
    width={800}
    height={300}
    data={platformsStatisticsByUser}
    margin={{
      top: 5,
      right: 0,
      left: 0,
      bottom: 5
    }}
  >
    <CartesianGrid strokeDasharray="3" />
    <XAxis dataKey="name" />
    <YAxis />
    <Tooltip />
    <Legend />
    <Bar dataKey="total" fill="#8884d8" />
    <Bar dataKey="removed" fill="#82ca9d" />
    <Bar dataKey="online" fill="#ca8282" />
  </BarChart>
    </Grid>
  </>
  );
}

export default observer(ProfileReportsStatistics);
