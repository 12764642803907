import { observer } from 'mobx-react-lite';
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { Button, Grid,  Header, Image } from 'semantic-ui-react'
import { useStore } from '../../stores/store';
import PhotoWidget from './PhotoWidget'

function PhotoMainUploadWidget() {
    const {profileStore, modalStore} = useStore();
    const [files, setFiles] = useState<any>([]);

    function handlePhotoUpload(file: Blob){
        profileStore.uploadMainPhoto(file).then((res)=> {
            modalStore.closeModal()
            toast.success("File uploaded successfully")
            setFiles([]);
        })
        
    };
  return (
    <Grid>
        <Grid.Column width={7}>
            <Header sub color='teal' content='Step 1 - Add Photo'/>
            <PhotoWidget setFiles={setFiles}/>
        </Grid.Column>
        <Grid.Column width={1}/>
        <Grid.Column width={7}>
            <Header sub color='teal' content='Step 2 - Preview & Upload'/>
            {files && files.length>0 && (
                <>
                <Image src={files[0].preview}/>
                <Button.Group widths={2}>
                    <Button loading={profileStore.uploadingMain} onClick={()=> handlePhotoUpload(files[0])}  positive icon='check'/>
                    <Button disabled={profileStore.uploadingMain}  onClick={()=> setFiles([])}   icon='close'/>
                </Button.Group>
                </>
            )}
        </Grid.Column>
    </Grid>
  )
}

export default observer(PhotoMainUploadWidget)
