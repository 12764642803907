import { observer } from 'mobx-react-lite';
import { Modal } from 'semantic-ui-react';
import { useStore } from '../../stores/store'

function ModalContainer() {
    const {modalStore} = useStore();
  return (
    <Modal closeIcon open={modalStore.modal.open} onClose={modalStore.closeModal} size='mini'>
        <Modal.Content>
            {modalStore.modal.body}
        </Modal.Content>
    </Modal>
  )
}

export default observer(ModalContainer)
