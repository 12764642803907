import {  Header, Icon, Segment } from 'semantic-ui-react'

const NoPosts = () => (
  <Segment placeholder>
    <Header icon>
      <Icon name='search' />
      There are no posts to show.
    </Header>
  </Segment>
)

export default NoPosts