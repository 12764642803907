import { Tab } from 'semantic-ui-react'
import { useStore } from '../../../app/stores/store';
import ScrapedBubbleChart from './ScrapedStatisticBubble'
import ScrapedStatisticGraphs from './ScrapedStatisticGraphs'
import ScrapedStatisticInformation from './ScrapedStatisticInformation'
import ScrapedLogChart from './ScrapedStatisticLog';
import ScrapedDashboard from '../dashboard/ScrapedDashboard';
import ScrapedGptPlatformsGraphs from './ScrapedGptPlatformsGraphs';
import ScrapedGptClassifiedStatisticLog from './ScrapedGptClassifiedStatisticLog';



const panes = [
    {
        menuItem: 'Posts',
        render: () => <Tab.Pane attached={true}><ScrapedDashboard /></Tab.Pane>,
    },
    {
        menuItem: 'General Information',
        render: () => <Tab.Pane attached={false}><ScrapedStatisticInformation /></Tab.Pane>,
    },
    {
        menuItem: 'Removal chart',
        render: () => <Tab.Pane attached={false}><ScrapedStatisticGraphs /></Tab.Pane>,
    },
    {
        menuItem: 'Scatter chart',
        render: () => <Tab.Pane attached={false}><ScrapedBubbleChart /></Tab.Pane>,
    },
    {
        menuItem: 'Log chart',
        render: () => <Tab.Pane attached={false}><ScrapedLogChart /></Tab.Pane>,
    },
    {
        menuItem: 'GPT by platforms',
        render: () => <Tab.Pane attached={false}><ScrapedGptPlatformsGraphs /></Tab.Pane>,
    },
    {
        menuItem: 'GPT classifier timeline',
        render: () => <Tab.Pane attached={false}><ScrapedGptClassifiedStatisticLog /></Tab.Pane>,
    },
]

const ScrapedStatisticDashboard = () => (
    <Tab
        menu={{ borderless: true, attached: false, tabular: false }}
        panes={panes}
    />
)

export default ScrapedStatisticDashboard