import { Formik, Form } from 'formik'
import * as _ from 'lodash'
import { observer } from 'mobx-react-lite'
import { Button, Header } from 'semantic-ui-react'
import * as Yup from 'yup'
import SelectInput from '../../../app/common/form/SelectInput'
import TextInput from '../../../app/common/form/TextInput'
import { AddCollectorDto, GetCollectorDto, UpdateCollectorDto } from '../../../app/models/collectors'
import { PlatformOptions, PlatformType } from '../../../app/models/platformEnum'
import { useStore } from '../../../app/stores/store'

interface Props {
    collector?: GetCollectorDto;
}

function CollectorsForm({ collector }: Props) {
    const { collectorsStore } = useStore();
    const { addNewCollector, updateCollector } = collectorsStore;
    const initValues = {
        collectorId: !_.isNil(collector) && !_.isNil(collector?.collectorId) ? collector.collectorId : '',
        platform: !_.isNil(collector) && !_.isNil(collector?.platform) ? collector.platform : PlatformType.Instagram,
    }

    const validationSchema = Yup.object({
        collectorId: Yup.string().required('Collector id is required'),
        platform: Yup.number().required('Platform is required')
    })

    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={{ collectorId: initValues.collectorId, platform: initValues.platform, error: null }}
            onSubmit={(values, { setErrors }) => {

                if (!_.isNil(collector) && !_.isNil(collector.id)) {
                    const updateCollectorDto: UpdateCollectorDto = {
                        id: collector.id,
                        collectorId: values.collectorId,
                        platform: values.platform
                    };
                    updateCollector(updateCollectorDto)
                        .catch(() => setErrors({ error: 'Error occured updating collector.' }))
                }
                else {
                    const addCollectorDto: AddCollectorDto = {
                        collectorId: values.collectorId,
                        platform: values.platform
                    };
                    addNewCollector(addCollectorDto)
                        .catch(() => setErrors({ error: 'Error occured adding collector.' }))
                }

            }}>
            {({ handleSubmit, isSubmitting, isValid, dirty }) => (
                <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                    {_.isNil(collector) ?
                        <Header as='h2' content='Add new collector' color='teal' textAlign='center' /> :
                        <Header as='h2' content='Update collector' color='teal' textAlign='center' />}
                    <TextInput name='collectorId' placeholder='Collector Id' />
                    <SelectInput name='platform' placeholder='Platform' options={PlatformOptions} />
                    <Button disabled={isSubmitting || !dirty || !isValid} loading={isSubmitting} positive content={_.isNil(collector) ? "Add" : "Update"} type='submit' fluid />
                </Form>
            )}

        </Formik>
    )
}

export default observer(CollectorsForm)
