import { observer } from 'mobx-react-lite';
import React from 'react'
import {Button, Header, Item, Segment, Image} from 'semantic-ui-react'
import {Report} from "../../../app/models/report";
import Moment from 'moment';
import {Link,useHistory} from 'react-router-dom'
import { useStore } from '../../../app/stores/store';

const reportImageStyle = {
    filter: 'brightness(30%)'
};

const reportImageTextStyle = {
    position: 'absolute',
    bottom: '5%',
    left: '5%',
    width: '100%',
    height: 'auto',
    color: 'white'
};

interface Props {
    report: Report
}

export default observer (function ReportDetailedHeader({report}: Props) {
    const history = useHistory();
    const {userStore} = useStore();

    let manageButton = <Button as={Link} to={`/manage/${report.id}`} color='orange' floated='right' disabled={!userStore.isUserManaged(report.createdById)}>Manage Report</Button>
    if(!userStore.isUserManaged(report.createdById)){manageButton = <></>}
        
    return (
        <Segment.Group>
            <Segment basic attached='top' style={{padding: '0'}}>
                <Image src={`/assets/placeholder.png`} fluid style={reportImageStyle}/>
                <Segment style={reportImageTextStyle} basic>
                    <Item.Group>
                        <Item>
                            <Item.Content>
                                <Header
                                    size='huge'
                                    content={report.url}
                                    style={{color: 'white'}}
                                />
                                <p>{Moment(report.createdOn).format("DD-MM-YYYY")}</p>
                                <p>
                                    Created by <strong>{report.createdBy}</strong>
                                </p>
                            </Item.Content>
                        </Item>
                    </Item.Group>
                </Segment>
            </Segment>
            <Segment clearing attached='bottom'>
                <Button onClick={()=>history.goBack()}>Go Back</Button>

                {manageButton}
                
            </Segment>
        </Segment.Group>
    )
})
