import { Tab } from 'semantic-ui-react'
import { useStore } from '../../../app/stores/store';
import BubbleChart from './ReportStatisticBubble'
import ReportStatisticGraphs from './ReportStatisticGraphs'
import ReportStatisticInformation from './ReportStatisticInformation'
import LogChart from './ReportStatisticLog';
import ReportDashboard from '../dashboard/ReportDashboard';



const panes = [
    {
        menuItem: 'Reports',
        render: () => <Tab.Pane attached={true}><ReportDashboard /></Tab.Pane>,
    },
    {
        menuItem: 'General Information',
        render: () => <Tab.Pane attached={false}><ReportStatisticInformation /></Tab.Pane>,
    },
    {
        menuItem: 'Removal chart',
        render: () => <Tab.Pane attached={false}><ReportStatisticGraphs /></Tab.Pane>,
    },
    {
        menuItem: 'Scatter chart',
        render: () => <Tab.Pane attached={false}><BubbleChart /></Tab.Pane>,
    },
    {
        menuItem: 'Log chart',
        render: () => <Tab.Pane attached={false}><LogChart /></Tab.Pane>,
    },
]

const ReportStatisticDashboard = () => (
    <Tab
        menu={{ borderless: true, attached: false, tabular: false }}
        panes={panes}
    />
)

export default ReportStatisticDashboard