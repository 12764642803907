import { makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { store } from "./store";
import * as _ from 'lodash';
import { toast } from "react-toastify";
import { AddSeleniumAgentDto, UpdateSeleniumAgentDto, GetSeleniumAgentDto } from "../models/seleniumAgents";

export default class SeleniumAgentsStore {
    agents: GetSeleniumAgentDto[] | null = null;
    loading: boolean = false;
    agentsLoaded: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    getAgents = async () => {
        try {
            if (_.isNil(this.agents)) {
                this.agentsLoaded = false;
                this.loading = true;
                const results = await agent.SeleniumAgents.getAgents();
                this.agents = results;
                this.loading = false;
                this.agentsLoaded = true;
            }
        }
        catch (e) {
            toast.error("An error occurred while loading agents.");
        }
    }

    addNewAgent = async (addAgentDto: AddSeleniumAgentDto) => {
        try {
            var result = await agent.SeleniumAgents.addAgent(addAgentDto);
            store.modalStore.closeModal();
            if (!_.isNil(result)) {
                toast.success("New agent added.");
                this.agents = null;
                this.getAgents();
            }
            else {
                toast.error("New agent was not added.");
            }
        }
        catch (e) {
            toast.error("An error occurred while adding new agent.");
        }
    }

    updateAgent = async (updateAgentDto: UpdateSeleniumAgentDto) => {
        try {
            var result = await agent.SeleniumAgents.updateAgent(updateAgentDto);
            store.modalStore.closeModal();
            if (!_.isNil(result)) {
                toast.success("Agent updated.");
                this.agents = null;
                this.getAgents();
            }
            else {
                toast.error("Agent was no updated.");
            }
        }
        catch (e) {
            toast.error("An error occurred while updating Agent.");
        }
    }


    deleteAgent = async (id: string) => {
        try {
            var result = await agent.SeleniumAgents.deleteAgent(id);
            if (!_.isNil(result)) {
                toast.success("Agent deleted.");
                this.agents = null;
                this.getAgents();
            }
            else {
                toast.error("Agent was not deleted.");
            }
            store.modalStore.closeModal();
            return result;
        }
        catch (e) {
            toast.error("An error occurred while removing Agent.");
        }
    }

    setAgent = async (id: string) => {
        try {
            var result = await agent.SeleniumAgents.setAgent(id);
            if (!_.isNil(result)) {
                toast.success("Agent was set.");
                this.agents = null;
                this.getAgents();
            }
            else {
                toast.error("Agent was not set.");
            }

            return result;
        }
        catch (e) {
            toast.error("An error occurred while setting Agent.");
        }
    }

}
