import { useCallback, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useStore } from '../../../app/stores/store';
import { observer } from 'mobx-react-lite';
import { ColDef, GridOptions } from 'ag-grid-community';
import { UpdateUserDto, VolunteersAction } from '../../../app/models/user';
import { userRoleDictionary } from '../../../app/common/options/userRoleOptions';
import { cellLink } from '../../reports/ReportCellRenderers';
import { Button, Divider, Icon } from 'semantic-ui-react';
import * as _ from 'lodash';
import { antiIsraeliCategoriesMapper } from '../../../app/common/options/categoryOptions';

const VolunteersActivity = () => {
    const { userStore } = useStore();

    const { volunteersActivityList } = userStore;

    const gridOptions: GridOptions<VolunteersAction> = {
        paginationAutoPageSize: true,
        pagination: true,
        defaultColDef: {
            resizable: true,
        },
        alwaysShowHorizontalScroll: true,
    }

    const textFilterParams = {
        filterOptions: ['contains', 'equal', 'notEqual'],
        debounceMs: 200,
        maxNumConditions: 1,

    };

    const dateFilterParams = {
        comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
            var cellDate = new Date(cellValue);

            if (filterLocalDateAtMidnight.getDay() === cellDate.getDay() &&
                filterLocalDateAtMidnight.getMonth() === cellDate.getMonth() &&
                filterLocalDateAtMidnight.getFullYear() === cellDate.getFullYear()) {
                return 0;
            }

            if (cellDate <= filterLocalDateAtMidnight) {
                return -1;
            }

            if (cellDate >= filterLocalDateAtMidnight) {
                return 1;
            }
            return 0;
        }
    };


    const [columnDefs, setColumnDefs] = useState<ColDef[]>([
        /*{ headerName: 'User Id', field: 'userId', editable: false },
        { headerName: 'Post Id', field: 'postId', editable: false },*/
        //{ headerName: 'Full Name', field: 'displayName', editable: false, sortable: true },
        //{ headerName: 'Email', field: 'email', editable: false, width: 200, sortable: true },

        {
            headerName: 'Full Name',
            field: 'displayName',
            filter: 'agTextColumnFilter',
            filterParams: textFilterParams,
            editable: false,
            sortable: true
        },
        {
            headerName: 'Email',
            field: 'email',
            filter: 'agTextColumnFilter',
            filterParams: textFilterParams,
            editable: false,
            minWidth: 250,
            sortable: true
        },
        { headerName: 'Action', field: 'actionName', editable: false, sortable: true },
        {
            headerName: 'Action Date',
            field: 'actionDate',
            valueFormatter: (params: { value: string; }) => {
                var date = new Date(params.value);
                return date.toString();
            },
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParams,
            sortable: true,
            minWidth: 250,
        },
        {
            headerName: 'Post Link',
            field: 'postUrl',
            cellRenderer: (params: { value: string }) => {
                return cellLink(params);
            }
        },
        //{ headerName: 'Classification', field: 'classification', editable: false, sortable: true },
        {
            field: 'classification',
            valueFormatter: (params: { value: number }) => {
                return antiIsraeliCategoriesMapper.hasOwnProperty(params.value) ? antiIsraeliCategoriesMapper[params.value] : '';
            },
            filter: 'agTextColumnFilter',
            //filterParams: platformFilterParams,
            sortable: true
        },
        { headerName: 'Action Description', field: 'description', editable: false },
    ]);
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            editable: true,
            resizable: true,
            minWidth: 125,

        };
    }, []);


    const onRowValueChanged = useCallback((event) => {
        //updateUser(event.data);
    }, []);

    const agGridRef = useRef<AgGridReact<VolunteersAction>>(null);

    function onBtExport() {
        if ((volunteersActivityList?.length || 0) > 0)
            agGridRef.current?.api.exportDataAsCsv();
    }

    return (
        <div className="ag-theme-alpine" style={{ height: 750, width: 810 }}>
            <Button
                color="green"
                disabled={volunteersActivityList?.length === 0}
                size="mini"
                onClick={() => onBtExport()}><Icon name="download" />CSV</Button>
            <Divider />
            <AgGridReact
                rowData={volunteersActivityList}
                columnDefs={columnDefs}

                defaultColDef={defaultColDef}
                editType={'fullRow'}
                onRowValueChanged={onRowValueChanged}
                gridOptions={gridOptions}
                ref={agGridRef}

            ></AgGridReact>
        </div>
    );
};

export default observer(VolunteersActivity)